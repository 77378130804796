import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentAccount } from "@/hooks";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { cn } from "@/lib/utils";
import { ListingStatus } from "@/types";
import { getColorForFloorDifference } from "@/utility";

import { AnimatedClickable } from "../animatedClickable";
import { ImageWithFallback } from "../imageWithFallback";
import { Spinner } from "../spinner";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { ListingCardCollectibleProps } from "./type";
import { PendingIndicator } from "@/components";

export const ListingCardCollectible: React.FC<ListingCardCollectibleProps> = ({
  selected,
  className,
  style,
  listed,
  title,
  floorDifferencePercentage,
  price,
  status,
  image,
  displayPendingState = false,
  displayBuyButton = true,
  displayStatusBadge = false,
  variant = "grid",
  lastPrice,
  seller,

  isAccountPage = false,
  isSendingPending = false,
  isReceivingPending = false,
  onBuy,
  onClick,
  onSelect,
}) => {
  const navigate = useNavigate();
  const { address } = useCurrentAccount();

  const currentUserIsSeller = useMemo(
    () => address === seller,
    [address, seller],
  );

  const floorDifference = !floorDifferencePercentage
    ? ""
    : formatNumber({
        value: floorDifferencePercentage,
        type: NumberFormatType.Percentage,
      });
  const colorPercentage = floorDifferencePercentage
    ? getColorForFloorDifference(floorDifferencePercentage)
    : "text-text-tertiary";
  const colorForPrice = price ? "" : "text-text-tertiary";
  const colorForLastPrice = lastPrice ? "" : "text-text-tertiary";

  const isPendingStateVisible = useMemo(
    () => displayPendingState,
    [displayPendingState],
  );

  const listingCardListDetails = useMemo(() => {
    return [
      {
        title: "Floor",
        value: floorDifference ? floorDifference : "–",
        colorValue: colorPercentage,
      },
      {
        title: "Price",
        value: price || "–",
        colorValue: colorForPrice,
      },
      {
        title: "Last Price",
        value: lastPrice || "–",
        colorValue: colorForLastPrice,
      },
    ];
  }, [floorDifference, colorPercentage, price, lastPrice]);

  const handleBuy = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent the event from bubbling up to the parent
    if (!address) {
      navigate("/account");
    } else if (onBuy) {
      onBuy();
    }
  };

  const handleSelect = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation(); // Prevent the event from bubbling up to the parent
      if (onSelect) {
        onSelect();
      }
    },
    [onSelect],
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation(); // Prevent the event from bubbling up to the parent
      if (onClick) {
        onClick(event);
      }
    },
    [onClick],
  );

  const buttonDisabled = status !== ListingStatus.Listed;

  if (variant === "grid") {
    return (
      <AnimatedClickable
        onClick={handleClick}
        className={cn(
          "flex w-32 flex-col overflow-hidden rounded-lg bg-background-primary",
          selected && "bg-background-tertiary",
          className,
          currentUserIsSeller && "",
        )}
        style={style}
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: 0.99 }}
      >
        <ListingCardGridImageArea
          image={image}
          selected={selected}
          displayPendingState={isPendingStateVisible}
          onSelect={handleSelect}
          listed={listed}
          status={status}
          displayStatusBadge={displayStatusBadge}
          userIsSeller={isAccountPage ? false : currentUserIsSeller}
          isSendingPending={isSendingPending}
          isReceivingPending={isReceivingPending}
        />
        <ListingCardGridDetail
          title={title}
          listed={listed}
          status={status}
          floorDifference={floorDifference}
          colorFloorDifference={colorPercentage}
          displayBuyButton={displayBuyButton}
          displayStatusBadge={displayStatusBadge}
          price={price}
          userIsSeller={isAccountPage ? false : currentUserIsSeller}
          button={
            onBuy && {
              label: address ? "Buy" : "Connect Wallet",
              disabled: buttonDisabled,
              onBuy: handleBuy,
            }
          }
        />
      </AnimatedClickable>
    );
  }

  if (variant === "gridSmall") {
    return (
      <AnimatedClickable
        onClick={handleClick}
        className={cn(
          "flex w-32 flex-col overflow-hidden rounded-lg bg-background-primary",
          selected && "bg-background-tertiary",
          className,
        )}
        style={style}
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: 0.99 }}
      >
        <ListingCardGridImageArea
          image={image}
          selected={selected}
          displayPendingState={isPendingStateVisible}
          displayStatusBadge={displayStatusBadge}
          status={status}
          listed={listed}
          onSelect={handleSelect}
          price={price}
          isSendingPending={isSendingPending}
          isReceivingPending={isReceivingPending}
        />
      </AnimatedClickable>
    );
  }

  if (variant === "list") {
    return (
      <AnimatedClickable
        onClick={handleClick}
        className={cn(
          "relative flex flex-row overflow-hidden rounded-lg bg-background-primary",
          selected && "bg-background-tertiary",
          className,
        )}
        style={style}
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: 0.99 }}
      >
        <ImageWithFallback image={image} className="h-24 w-24 rounded-none" />
        {displayStatusBadge && status && (
          <span className="absolute left-1 top-1 z-10 rounded-full bg-background-tertiary/50 px-2 py-0.5 text-xs font-bold capitalize text-text-primary">
            {status}
          </span>
        )}
        <div className="flex flex-1 flex-col space-y-4 px-4 py-3">
          <ListingCardListHeader
            title={title}
            selected={selected}
            displayPendingState={isPendingStateVisible}
            onSelect={handleSelect}
            listed={listed}
            status={status}
            displayStatusBadge={displayStatusBadge}
            isSendingPending={isSendingPending}
            isReceivingPending={isReceivingPending}
          />

          <ListingCardListDetail items={listingCardListDetails} />
        </div>
      </AnimatedClickable>
    );
  }
};

/**
 * Components that make up the ListingCardCollectible for different variants
 */

interface ListingCardCheckboxProps {
  selected: boolean;
  displayPendingState: boolean;
  displayStatusBadge?: boolean;
  onSelect: (event: React.MouseEvent) => void;
  userIsSeller?: boolean;
}

const ListingCardCheckbox: React.FC<ListingCardCheckboxProps> = ({
  selected,
  displayPendingState,
  onSelect,
  userIsSeller,
}) => {
  return (
    <div
      onClick={(event) => {
        userIsSeller ? undefined : !displayPendingState && onSelect?.(event);
      }}
      className="flex h-6 w-6 flex-col items-center justify-center rounded-full"
    >
      {displayPendingState ? (
        <div className="flex h-6 w-6 flex-col items-center justify-center rounded-full border-border-tertiary/10 bg-background-tertiary/50 drop-shadow-xl backdrop-blur-xl">
          <Spinner size={16} />
        </div>
      ) : selected ? (
        <Checkbox
          id="listing-card-checkbox"
          checked={selected}
          className="h-6 w-6 rounded-full border-none text-white data-[state=checked]:bg-primary-500"
        />
      ) : userIsSeller ? (
        <div className="flex h-6 w-6 flex-col items-center justify-center rounded-full border border-none border-text-highlight/10 bg-text-highlight drop-shadow-xl backdrop-blur-xl">
          <span className="material-symbols-rounded text-sm text-text-primary">
            person
          </span>
        </div>
      ) : (
        <div className="flex h-6 w-6 flex-col items-center justify-center rounded-full border border-none border-border-tertiary/10 bg-background-tertiary/50 drop-shadow-xl backdrop-blur-xl">
          <span className="material-symbols-rounded text-sm text-text-primary">
            add
          </span>
        </div>
      )}
    </div>
  );
};

interface ListingCardGridImageAreaProps {
  image: string;
  selected: boolean;
  onSelect: (event: React.MouseEvent) => void;
  displayPendingState: boolean;
  displayBuyButton?: boolean;
  displayStatusBadge?: boolean;
  price?: string;
  status?: ListingStatus;
  listed?: boolean;
  userIsSeller?: boolean;
  isSendingPending: boolean;
  isReceivingPending: boolean;
}

const ListingCardGridImageArea: React.FC<ListingCardGridImageAreaProps> = ({
  image,
  price,
  selected,
  displayPendingState,
  displayStatusBadge,
  onSelect,
  status,
  userIsSeller,
  isSendingPending,
  isReceivingPending
}) => {
  return (
    <div className="relative aspect-square w-full">
      <ImageWithFallback image={image} className="h-full w-full rounded-none"/>
      <div className="absolute left-1 top-1">
        {displayStatusBadge && status && (
          <span
            className="rounded-full bg-background-tertiary/50 px-2 py-0.5 text-xs font-bold capitalize text-text-primary">
            {status}
          </span>
        )}
      </div>
      <div className="absolute right-1 top-1">
        <ListingCardCheckbox
          selected={selected}
          displayPendingState={displayPendingState}
          onSelect={onSelect}
          userIsSeller={userIsSeller}
        />
      </div>
      {(displayPendingState || isSendingPending || !isReceivingPending) && (
        <div
          className="absolute bottom-1 left-1/2 flex h-8 -translate-x-1/2 transform flex-col items-center justify-center">
          {displayPendingState && !isSendingPending && !isReceivingPending && (
            <div className="flex h-5 flex-row items-center justify-center space-x-1.5 rounded-full border border-border-tertiary/10 bg-background-tertiary/50 px-2 drop-shadow-xl backdrop-blur-xl">
              <span className="text-2xs font-bold text-text-primary">
                Listing…
              </span>
              <Spinner size={10}/>
            </div>
          )}

          {(isSendingPending || isReceivingPending) && (<div className="flex items-center space-x-2">
            {isSendingPending && <PendingIndicator pendingAmount={-1}/>}
            {isReceivingPending && <PendingIndicator pendingAmount={1}/>}
          </div>)}
        </div>
      )}
      {!!price && (
        <span
          className="absolute bottom-1 right-1 rounded-full border border-border-tertiary/10 bg-background-tertiary/50 px-2 py-1 text-xs font-bold text-text-primary drop-shadow-xl backdrop-blur-xl"
          dangerouslySetInnerHTML={{__html: price}}
        />
      )}
    </div>
  );
};

interface ListingCardGridDetailProps {
  title: string;
  floorDifference: string;
  colorFloorDifference?: string;
  price: string;
  listed?: boolean;
  status?: ListingStatus;
  displayBuyButton?: boolean;
  displayStatusBadge?: boolean;
  userIsSeller?: boolean;

  button?: {
    label: string;
    disabled: boolean;
    onBuy: (event: React.MouseEvent) => void;
  };
}

const ListingCardGridDetail: React.FC<ListingCardGridDetailProps> = ({
  title,
  floorDifference,
  colorFloorDifference,
  price,
  button,
  userIsSeller
}) => {
  return (
    <div className="flex flex-col space-y-1 p-2">
      <span className="w-full truncate text-md font-semibold text-text-primary">
        {title}
      </span>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col space-y-0">
          <div className="text-xs uppercase text-text-tertiary">Floor</div>
          <div className={cn("text-xs", colorFloorDifference)}>
            {floorDifference || "-"}
          </div>
        </div>
        <div className="flex flex-col space-y-0">
          <div className="text-right text-xs uppercase text-text-tertiary">
            Price
          </div>
          <div
            className={cn(
              "text-right text-xs text-text-primary",
              !price && "text-text-tertiary",
            )}
            dangerouslySetInnerHTML={{ __html: price || "-" }}
          />
        </div>
      </div>
      {button && !userIsSeller && (
        <div className="pt-2">
          <Button
            className="w-full rounded-md"
            size="small"
            variant="inverse"
            onClick={button.onBuy}
            disabled={button.disabled}
          >
            {button.label}
          </Button>
        </div>
      )}
    </div>
  );
};

interface ListingCardListHeaderProps extends ListingCardCheckboxProps {
  title: string;
  listed?: boolean;
  status?: ListingStatus;
  isPending?: boolean;
  isReceivingPending?: boolean;
  isSendingPending?: boolean;
}

const ListingCardListHeader: React.FC<ListingCardListHeaderProps> = ({
  title,
  selected,
  displayPendingState,
  onSelect,
  isReceivingPending,
  isSendingPending,
}) => {
  return (
    <div className="flex flex-row items-center justify-between space-x-4">
      <div className="flex items-center space-x-2">
        <span className="max-w-36 truncate text-md font-semibold text-text-primary">
          {title}
        </span>

        {isSendingPending && <PendingIndicator pendingAmount={-1} />}
        {isReceivingPending && <PendingIndicator pendingAmount={1} />}
      </div>
      {!isSendingPending && !isReceivingPending && (
        <ListingCardCheckbox
          selected={selected}
          displayPendingState={displayPendingState}
          onSelect={onSelect}
        />
      )}
    </div>
  );
};

interface ListingCardListDetailItemProps {
  title: string;
  value: string;
  colorValue?: string;
}

const ListingCardListDetailItem: React.FC<ListingCardListDetailItemProps> = ({
  title,
  value,
  colorValue,
}) => {
  return (
    <div className="flex flex-col space-y-0">
      <span className="text-xs uppercase text-text-tertiary">{title}</span>
      <span
        className={cn("text-xs font-semibold text-text-primary", colorValue)}
        dangerouslySetInnerHTML={{ __html: value || "-" }}
      />
    </div>
  );
};

interface ListingCardListDetailProps {
  items: ListingCardListDetailItemProps[];
}

const ListingCardListDetail: React.FC<ListingCardListDetailProps> = ({
  items,
}) => {
  return (
    <div className="flex flex-row items-center justify-between space-x-1">
      {items.map((item, index) => (
        <ListingCardListDetailItem key={index} {...item} />
      ))}
    </div>
  );
};
