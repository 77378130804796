import React from "react";

import { cn } from "@/lib/utils";
import { Tab } from "@/types";

import { AnimatedClickable } from "../animatedClickable";

export interface TabItem {
  label: string;
  value: Tab;
  isActive: boolean;
  onSelect: (tab: Tab) => void;
}

interface ScrollableTabsProps {
  tabs: TabItem[];
  className?: string;
}

export const ScrollableTabs: React.FC<ScrollableTabsProps> = ({
  tabs,
  className,
}) => {
  return (
    <div className={cn("relative flex w-full flex-col", className)}>
      <div className="flex flex-row space-x-2 overflow-x-auto pr-6">
        {tabs.map((tab) => (
          <AnimatedClickable
            key={tab.value}
            onClick={() => tab.onSelect(tab.value)}
            className={cn(
              "flex-shrink-0 cursor-pointer border-b-2 border-transparent p-2 text-md font-semibold transition-colors duration-100 hover:text-text-secondary focus:outline-none",
              tab.isActive
                ? "border-primary-500 text-text-primary"
                : "text-text-tertiary",
            )}
          >
            {tab.label}
          </AnimatedClickable>
        ))}
      </div>
      <div className="pointer-events-none absolute bottom-0 right-0 top-0 w-10 bg-gradient-to-l from-background-primary to-transparent" />
    </div>
  );
};
