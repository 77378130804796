import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Appear,
  Button,
  ButtonBar,
  ListingCardCollectible,
  Skeleton,
  Spinner,
  TableContentListingsCollection,
  TableListings,
} from "@/components";
import {
  DOGINALS_LISTINGS_PAGINATION_LIMIT,
  PAGINATION_LIMIT,
} from "@/constants.ts";
import { useCurrency, useDogePrice, useTxWallet } from "@/contextHooks";
import { useCollectiblesDetails } from "@/contextHooks/useCollectiblesDetails.tsx";
import { useCurrentAccount, useInfiniteScroll } from "@/hooks";
import { cn } from "@/lib/utils";
import {
  ModalBuyCollectible,
  ModalCollectionFilter,
  ModalCollectionSearch,
  ModalCollectionSort,
} from "@/modals";
import {
  CollectionListingProps,
  FilterBetween,
  FilterListingsCollection,
  FilterMetadata,
  ListingStatus,
  SortListingsCollection,
} from "@/types";
import {
  createExtendedCollectibleListings,
  formatRange,
  getFormattedValue,
  isValidDogecoinAddress,
  isValidRange,
} from "@/utility";

import { DisplayType, ModalType } from "../types";
import {
  FILTER_DEFAULT,
  SORT_DEFAULT,
} from "@/hooks/datafetching/useFetchDoginalsOfCollection";

interface TabCollectionListingsProps {
  collectionSymbol: string | undefined;
}

export const TabCollectionListings: React.FC<TabCollectionListingsProps> = ({
  collectionSymbol,
}) => {
  const navigate = useNavigate();
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();

  const { login } = useTxWallet();

  const { address: currentAccountAddress } = useCurrentAccount();

  /**
   * Local State
   */
  const [modalType, setModalType] = useState<ModalType | undefined>();
  const [display, setDisplay] = useState<DisplayType>(DisplayType.Grid);
  const [selectedListings, setSelectedListings] = useState<string[]>([]);

  const { collectibleListings, setCollectionSymbol, setAddress } =
    useCollectiblesDetails();

  const {
    isLoading,
    hasMoreData,
    isLoadingMoreData,
    setFilters,
    setSort,
    sort,
    filters,
    reset,
    offset,
    setOffset,
    data,
    floorPrice,
  } = collectibleListings;

  const search = useMemo(() => {
    return filters.seller || filters.inscriptionId || "";
  }, [filters.seller, filters.inscriptionId]);

  const { hasActiveFilters, hasActiveSorting, hasActiveSearch } =
    useMemo(() => {
      const { seller, ...otherFilters } = filters;
      return {
        hasActiveFilters: !Object.entries(otherFilters).every(
          // @ts-expect-error internal type is wrong
          ([key, value]) => FILTER_DEFAULT[key] === value,
        ),
        hasActiveSorting: !Object.entries(sort).every(
          // @ts-expect-error internal type is wrong
          ([key, value]) => SORT_DEFAULT[key] === value,
        ),
        hasActiveSearch: !!seller,
      };
    }, [filters, sort]);

  /**
   * Collectible Listings
   */
  const updateListings = useCallback((offerId: string) => {
    setSelectedListings((prev) => {
      const isSelected = prev.includes(offerId);
      return isSelected
        ? prev.filter((selected) => selected !== offerId)
        : [...prev, offerId];
    });
  }, []);

  const removeFromListings = useCallback((offerId: string) => {
    setSelectedListings((prev) => {
      // Only remove the offerId if it exists in the previous state
      return prev.includes(offerId)
        ? prev.filter((selected) => selected !== offerId)
        : prev;
    });
  }, []);

  const onSelectListing = useCallback(
    async (offerId: string) => {
      const isLoggedIn = await login();
      if (isLoggedIn) {
        updateListings(offerId);
      }
    },
    [updateListings, login],
  );

  const onResetSelectedListings = useCallback(() => {
    setSelectedListings([]);
  }, []);

  /**
   * Button Bar: Modals
   */

  const onOpenModalFilter = useCallback(() => {
    setModalType(ModalType.Filter);
  }, []);

  const onOpenModalSort = useCallback(() => {
    setModalType(ModalType.Sort);
  }, []);

  const onOpenModalSearch = useCallback(() => {
    setModalType(ModalType.Search);
  }, []);

  /**
   * Button Bar: Others
   */

  const onToggleDisplay = useCallback(() => {
    setDisplay((prev) =>
      prev === DisplayType.Grid ? DisplayType.List : DisplayType.Grid,
    );
  }, []);

  const onRefresh = useCallback(
    async (resetListing: boolean = false) => {
      if (!collectionSymbol) return;
      if (resetListing) onResetSelectedListings();
      reset({
        collectionSymbol,
        offset: 0,
        limit: PAGINATION_LIMIT,
        seller: undefined,
      });
    },
    [collectionSymbol, onResetSelectedListings, reset],
  );

  const onCloseModal = useCallback(() => {
    setModalType(undefined);
    onRefresh(true);
  }, [onRefresh]);

  /**
   * Apply Filters/Sort/Search
   */

  const filterPrice = useMemo(() => {
    const { priceMin, priceMax } = filters;
    return {
      min: priceMin?.length && Number(priceMin),
      max: priceMax?.length && Number(priceMax),
    };
  }, [filters]);

  const onApplyFilter = useCallback(
    (filters: {
      price?: FilterBetween;
      listingStatus?: ListingStatus;
      metadata?: FilterMetadata[];
    }) => {
      const updatedPriceRange = isValidRange(
        filters?.price?.min,
        filters?.price?.max,
      )
        ? formatRange(filters?.price?.min, filters?.price?.max)
        : { min: undefined, max: undefined };

      setFilters((prev: FilterListingsCollection) => ({
        ...prev,
        ...filters,
        priceMin: updatedPriceRange.min,
        priceMax: updatedPriceRange.max,
        listed:
          filters.listingStatus === ListingStatus.Listed
            ? true
            : filters.listingStatus === ListingStatus.Unlisted
              ? false
              : undefined,
      }));

      onCloseModal();
    },
    [onCloseModal, setFilters],
  );

  const onApplySort = useCallback(
    (sort: SortListingsCollection | undefined) => {
      setSort(sort);
      onCloseModal();
    },
    [onCloseModal, setSort],
  );

  const onApplySearch = useCallback(
    (search: string) => {
      search = search.trim();
      const isValidSeller = isValidDogecoinAddress(search);
      if (isValidSeller) {
        setFilters((prev: FilterListingsCollection) => ({
          ...prev,
          seller: search,
        }));
      } else {
        setFilters((prev: FilterListingsCollection) => ({
          ...prev,
          seller: isValidSeller ? search : undefined,
          name: search,
        }));
      }
      onCloseModal();
    },
    [onCloseModal, setFilters],
  );

  const onBuyListings = useCallback(async () => {
    const isLoggedIn = await login();
    if (isLoggedIn) {
      setModalType(ModalType.Buy);
    }
  }, [login]);

  // Address reset to show all listings, not only the ones of the user
  useEffect(() => {
    setAddress(undefined);
  }, [setAddress]);

  /**
   * Data Transformations
   */

  // Navigation
  const onNavigateToCollectionDetail = useCallback(
    (inscriptionId: string) => {
      if (!collectionSymbol) return;
      navigate(
        `/collectible/${collectionSymbol.toLowerCase()}/${inscriptionId}`,
      );
    },
    [navigate, collectionSymbol],
  );

  // Formatted table data
  const dataListings = useMemo<CollectionListingProps[]>(() => {
    if (!data) return [];

    return createExtendedCollectibleListings({
      listings: data,
      selectedListings,
      floorPrice,
      onSelectListing,
      onNavigateToListing: onNavigateToCollectionDetail,
      currency,
      dogePrice,
      identifier: "offerId",
      checkIfUserIsSeller: (address) => address === currentAccountAddress,
    });
  }, [
    data,
    floorPrice,
    selectedListings,
    currency,
    dogePrice,
    onSelectListing,
    onNavigateToCollectionDetail,
    currentAccountAddress,
  ]);

  // Formatted card data
  const dataListingCards = useMemo(
    () =>
      dataListings.map((listing, index) => {
        const {
          offerId,
          imageURI,
          name,
          floorDifferencePercentage,
          listed,
          isSelected,
          price,
          inscriptionId,
          doginalOffer,
        } = listing;

        const { value: totalPriceForCurrency } = getFormattedValue(
          price,
          dogePrice,
          currency,
          true,
        );

        return {
          key: offerId + "-" + index,
          image: imageURI,
          title: name,
          seller: doginalOffer?.address || "",
          floorDifferencePercentage,
          status: listed ? ListingStatus.Listed : ListingStatus.Unlisted,
          selected: isSelected,
          price: `${totalPriceForCurrency}`,
          displayPendingState: false,
          className: cn("w-full", !isSelected && "bg-background-primary/50"),

          onClick: () => onNavigateToCollectionDetail(inscriptionId),
          onSelect: () => onSelectListing(offerId),
        };
      }),
    [
      dataListings,
      currency,
      dogePrice,
      onNavigateToCollectionDetail,
      onSelectListing,
    ],
  );

  const buttonBarConfig = useMemo(() => {
    return [
      {
        icon: display === DisplayType.Grid ? "reorder" : "apps",
        onClick: onToggleDisplay,
        isActive: display !== DisplayType.Grid,
      },
      {
        icon: "filter_list",
        onClick: onOpenModalFilter,
        isActive: hasActiveFilters,
      },
      {
        icon: "swap_vert",
        onClick: onOpenModalSort,
        isActive: hasActiveSorting,
      },
      { icon: "search", onClick: onOpenModalSearch, isActive: hasActiveSearch },
      { icon: "refresh", onClick: onRefresh },
    ];
  }, [
    display,
    onOpenModalFilter,
    onOpenModalSearch,
    onOpenModalSort,
    onRefresh,
    onToggleDisplay,
    hasActiveFilters,
    hasActiveSorting,
    hasActiveSearch,
  ]);

  const selectedOffers = useMemo(() => {
    return dataListings.filter((listing) =>
      selectedListings.includes(listing.offerId),
    );
  }, [dataListings, selectedListings]);

  /**
   * Effects
   */

  useEffect(() => {
    setCollectionSymbol(collectionSymbol);
    reset(
      {
        ...filters,
        collectionSymbol,
        offset: 0,
        limit: PAGINATION_LIMIT,
        listed: true,
      },
      sort,
    );
  }, [collectionSymbol, reset, setCollectionSymbol]);

  return (
    <>
      <div className="flex flex-1 flex-col">
        {/** Actions */}
        <div className="mx-auto w-full px-4 md:px-0">
          <ButtonBar buttons={buttonBarConfig} />
        </div>

        {/** Listings */}
        {isLoading ? (
          display === "grid" ? (
            <div
              className={cn(
                "grid grid-cols-2 gap-3 p-4",
                "sm:grid-cols-3 md:mt-8 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7",
              )}
            >
              <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
              <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
              <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
              <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
              <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
              <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
              <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
            </div>
          ) : (
            <div className="flex min-h-64 flex-1 flex-col items-center justify-center">
              <Spinner />
            </div>
          )
        ) : display === "grid" ? (
          <div
            className={cn(
              "grid grid-cols-2 gap-3 p-4",
              "sm:grid-cols-3 md:mt-8 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7",
            )}
          >
            {dataListingCards.map((listing) => {
              return (
                <ListingCardCollectible
                  {...listing}
                  key={listing.key}
                  className="w-full bg-background-primary 2xl:bg-background-secondary"
                />
              );
            })}
            <BottomScrollObserver
              isLoading={isLoading}
              hasMoreData={hasMoreData}
              isLoadingMoreData={isLoadingMoreData}
              setOffset={setOffset}
              offset={offset}
              paginationLimit={DOGINALS_LISTINGS_PAGINATION_LIMIT}
              display="grid"
            />
          </div>
        ) : (
          <div className="mt-4 md:mt-8">
            <TableListings
              data={dataListings}
              columns={TableContentListingsCollection}
              loading={isLoading}
            />
            <BottomScrollObserver
              isLoading={isLoading}
              hasMoreData={hasMoreData}
              isLoadingMoreData={isLoadingMoreData}
              setOffset={setOffset}
              offset={offset}
              paginationLimit={DOGINALS_LISTINGS_PAGINATION_LIMIT}
              display="list"
            />
          </div>
        )}
      </div>

      {/** Buttons */}
      <Appear
        isVisible={selectedListings.length > 0 && modalType !== ModalType.Buy}
        from="bottom"
      >
        <div className="flex w-full flex-1 flex-row items-center justify-center space-x-2">
          <div className="absolute -bottom-6 left-0 right-0 -z-10 h-20 rounded-lg bg-gradient-to-t from-background-primary/80 to-transparent transition-all duration-200" />
          <Button
            size="large"
            shape="circle"
            variant="inverse"
            onClick={onBuyListings}
            className="w-1/2 max-w-xs border-none drop-shadow-xl disabled:cursor-not-allowed disabled:text-text-disabled disabled:opacity-100 disabled:drop-shadow-none"
          >
            {`Buy ${selectedListings.length} ${selectedListings.length > 1 ? "Listings" : "Listing"}`}
          </Button>
          <Button
            size="large"
            shape="circle"
            variant="default"
            className="h-12 w-12 border-text-highlight bg-text-highlight"
            onClick={onResetSelectedListings}
          >
            <span className="material-symbols-rounded text-lg">close</span>
          </Button>
        </div>
      </Appear>

      {/** Modals */}
      <ModalCollectionFilter
        title="Filter"
        price={filterPrice}
        onClose={onCloseModal}
        onApply={onApplyFilter}
        metadata={filters.metadata}
        collectionSymbol={collectionSymbol}
        isVisible={modalType === ModalType.Filter}
        listingStatus={
          filters.listed != undefined
            ? filters.listed
              ? ListingStatus.Listed
              : ListingStatus.Unlisted
            : undefined
        }
      />

      <ModalCollectionSearch
        title="Search"
        search={search}
        onClose={onCloseModal}
        onApply={onApplySearch}
        isVisible={modalType === ModalType.Search}
      />

      <ModalCollectionSort
        title="Sort"
        sort={sort}
        onClose={onCloseModal}
        onApply={onApplySort}
        isVisible={modalType === ModalType.Sort}
      />

      <ModalBuyCollectible
        onClose={onCloseModal}
        offers={selectedOffers}
        removeFromListings={removeFromListings}
        refetchListings={onRefresh}
        isVisible={modalType === ModalType.Buy}
      />
    </>
  );
};

const BottomScrollObserver = ({
  isLoading,
  hasMoreData,
  isLoadingMoreData,
  setOffset,
  offset,
  paginationLimit,
  display,
}: {
  isLoading: boolean;
  hasMoreData: boolean;
  isLoadingMoreData: boolean;
  setOffset: (offset: number) => void;
  offset: number;
  paginationLimit: number;
  display?: "grid" | "list";
}) => {
  /**
   * Infinite Scroll
   */

  // For Infinite Scroll
  const bottomTableRef = useInfiniteScroll({
    isLoadingData: isLoading,
    hasMoreData: hasMoreData,
    isLoadingMoreData: isLoadingMoreData,
    setOffset: setOffset,
    offset: offset,
    paginationLimit: DOGINALS_LISTINGS_PAGINATION_LIMIT,
  });

  if (display === "list") {
    return (
      <>
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
        <div ref={bottomTableRef} />
      </>
    );
  }

  return (
    <>
      <div
        ref={bottomTableRef}
        className={cn(
          "min-h-72 w-full rounded-lg bg-none md:min-h-64",
          isLoadingMoreData && "animate-pulse bg-background-tertiary/30",
        )}
      />
      {isLoadingMoreData && (
        <>
          <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
          <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
        </>
      )}
    </>
  );
};
