import { useState } from "react";

import { Spinner } from "@/components";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { cn } from "@/lib/utils";
import { CollectionListingProps, TokenListingProps } from "@/types";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { LoadingState } from "../components";

// This function is used to get the sticky column class name
function getStickyColumnClassName(index: number): string | undefined {
  const stickyClasses: Record<number, string> = {
    0: "sticky left-0 z-10",
    1: "sticky left-10 z-10", // 10 is the width of the first column including padding
  };

  return stickyClasses[index];
}

type ListingProps = TokenListingProps | CollectionListingProps;

interface TableListingsProps<TData extends ListingProps, TValue> {
  data: TData[];
  columns: ColumnDef<TData, TValue>[];
  loading?: boolean;
  loadingMore?: boolean;
}

export const TableListings = <TData extends ListingProps, TValue>({
  data,
  columns,
  loading = false,
  loadingMore = false,
}: TableListingsProps<TData, TValue>) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});

  // The useReactTable hook is used to create a table instance
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    state: { sorting, rowSelection },
  });

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center py-40">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Table>
        <TableHeader id="table-header">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                const stickyColumnStyle = getStickyColumnClassName(index);
                return (
                  <TableHead
                    key={header.id}
                    className={cn(
                      stickyColumnStyle,
                      "bg-background-secondary 2xl:bg-background-primary",
                    )}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => {
              const { id, getVisibleCells } = row;
              // const isSelected = getIsSelected() || row.original.isSelected;
              const isSelected = row.original.isSelected;
              return (
                <TableRow
                  key={id}
                  data-state={isSelected && "selected"}
                  className="cursor-pointer"
                >
                  {getVisibleCells().map((cell, index) => {
                    const stickyColumnStyle = getStickyColumnClassName(index);
                    return (
                      <TableCell
                        key={cell.id}
                        className={cn(
                          stickyColumnStyle,
                          "bg-background-secondary 2xl:bg-background-primary",
                          isSelected &&
                            "bg-background-tertiary 2xl:bg-background-tertiary",
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className="h-24 text-center text-text-primary"
              >
                No data available …
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <LoadingState isVisible={loadingMore} />
    </>
  );
};
