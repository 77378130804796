import { useLocalStorage } from "@/hooks";
import { ReactNode, createContext, useEffect, useState } from "react";

interface SecurityPreferenceContextType {
  securityPreference: "pin" | "password" | undefined;
  setSecurityPreference: (value: "pin" | "password" | undefined) => void;
}

type SecurityPreferenceProviderProps = {
  children: ReactNode;
};

export const SecurityPreferenceContext =
  createContext<SecurityPreferenceContextType>({
    securityPreference: undefined,
    setSecurityPreference: () => {},
  });

export const SecurityPreferenceProvider = ({
  children,
}: SecurityPreferenceProviderProps) => {
  const { value: localStorageValue, updateValue: setLocalStorageValue } =
    useLocalStorage<"pin" | "password" | undefined>(
      "user-security-preference",
      undefined,
    );

  const [securityPreference, setSecurityPreference] = useState<
    "pin" | "password" | undefined
  >(localStorageValue);

  useEffect(() => {
    setSecurityPreference(localStorageValue);
  }, [localStorageValue]);

  return (
    <SecurityPreferenceContext.Provider
      value={{
        securityPreference,
        setSecurityPreference: setLocalStorageValue,
      }}
    >
      {children}
    </SecurityPreferenceContext.Provider>
  );
};
