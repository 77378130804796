import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { registerSW } from "virtual:pwa-register";

// registerSW({
//   immediate: true,
// });

// Check every 5 minutes for a new revision and install it.
const intervalMS = 300 * 1000;

registerSW({
  onRegisteredSW(swUrl: string, r: any) {
    r &&
      setInterval(async () => {
        if (!(!r.installing && navigator)) return;

        if ("connection" in navigator && !navigator.onLine) return;

        try {
          const resp = await fetch(swUrl, {
            cache: "no-store",
            headers: {
              cache: "no-store",
              "cache-control": "no-cache",
            },
          });

          if (resp?.status === 200) await r.update();
        } catch (e: Error | unknown) {
          console.error("Error in updating service worker", e);
        }
      }, intervalMS);
  },
});

import App from "./App.tsx";

// Sentry for debugging purposes
if (
  typeof process !== "undefined" &&
  import.meta.env.VITE_SENTRY_MODE === "active"
) {
  Sentry.init({
    dsn: "https://396c33514c7335a47bafaa5cc0723bcd@o4506343641776128.ingest.us.sentry.io/4507129883656192",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/doge-labs\.com\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  console.log("Sentry activated");
} else {
  console.log("Sentry inactive");
}

export default Sentry;

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);
