import * as bitcoin from "bitcoinjs-lib";
import { Utxo } from "@/types";
import { dogecoinNetwork } from "@/utility";
import { INSCRIPTION_SATS, ONE_DOGE_IN_SHIBES } from "@/constants.ts";
import { isString } from "lodash";
import { Transaction } from "bitcore-lib-doge";
import { DogeUtxo } from "@/types";

export type UtxoStore = Record<
  string,
  { utxos: Utxo[]; usedUtxos: string[]; initialized: boolean }
>;

export type SyncTxWalletWithUtxosParams = {
  safeUtxos: DogeUtxo[];
  address: string;
};

type RemoveUtxosParams = {
  address: string;
  inputs: any[];
};

export type AddUtxosParams = {
  address: string;
  outputs: Pick<bitcoin.TxOutput, "script" | "value">[];
  txId: string;
  outputsToSkip?: number[];
};

export type UpdateUtxosParams = {
  address: string;
  tx: any;
  outputsToSkip?: number[];
};

export class UTXOService {
  private store: UtxoStore;

  constructor(initialStore?: UtxoStore) {
    this.store = initialStore || {};
  }

  clearUtxos(address: string) {
    if (!this.store[address]) {
      return;
    }
    this.store[address] = { usedUtxos: [], utxos: [], initialized: true };
  }

  syncUtxos({ safeUtxos, address }: SyncTxWalletWithUtxosParams) {
    if (!this.store[address] || !this.store[address].initialized) {
      this.store[address] = {
        usedUtxos: [],
        initialized: true,
        utxos: this.#mapSafeUtxosToUtxos(safeUtxos),
      };
      return;
    }

    const safeUtxosMapped = this.#mapSafeUtxosToUtxos(safeUtxos);
    const newNonInscriptionUtxoSet = new Set(
      safeUtxosMapped.map((utxo: Utxo) => this.#serializeUtxo(utxo)),
    );
    const nonInscriptionUtxoSet = new Set(
      this.store[address].utxos.map((utxo: Utxo) => this.#serializeUtxo(utxo)),
    );
    const usedUtxoSet = new Set(this.store[address].usedUtxos);

    const filteredNonInscriptionUtxos = safeUtxosMapped.filter((utxo: Utxo) => {
      const serializedUtxo = this.#serializeUtxo(utxo);
      return (
        !nonInscriptionUtxoSet.has(serializedUtxo) &&
        !usedUtxoSet.has(serializedUtxo) &&
        utxo.satoshis > ONE_DOGE_IN_SHIBES
      );
    });

    const mergedUtxos = [
      ...this.store[address].utxos,
      ...filteredNonInscriptionUtxos,
    ];

    const newUtxos = mergedUtxos.filter((utxo: Utxo) => {
      const serializedUtxo = this.#serializeUtxo(utxo);

      if (!utxo.isLocal) {
        return newNonInscriptionUtxoSet.has(serializedUtxo);
      }

      return true;
    });

    this.store[address] = {
      ...this.store[address],
      utxos: newUtxos,
    };
  }

  addUtxos({
    address,
    outputs,
    txId,
    outputsToSkip,
  }: AddUtxosParams): Utxo[] | null {
    if (!this.store[address]) {
      console.info(`store not initialized for address: ${address}`);
      return null;
    }

    const nonInscriptionUtxoSet = new Set(
      this.store[address].utxos.map((utxo) => `${utxo.txid}-${utxo.vout}`),
    );
    const utxosToAdd: Utxo[] = [];

    outputs.forEach((out, i) => {
      if (!outputsToSkip?.includes(i)) {
        try {
          const p2pkh = bitcoin.payments.p2pkh({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            output: out.script.toBuffer(),
            network: dogecoinNetwork,
          });
          if (
            p2pkh.address === address &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            out.satoshis !== INSCRIPTION_SATS &&
            !nonInscriptionUtxoSet.has(`${txId}-${i}`)
          ) {
            const tmpUtxo: Utxo = {
              isLocal: true,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              satoshis: out.satoshis,
              txid: txId,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              script: out.script.toBuffer().toString("hex"),
              inscriptions: [],
              vout: i,
            };

            // ensure things are set correctly
            if (tmpUtxo.satoshis === undefined) {
              throw new Error("UTXOService - addUtxos - satoshis is undefined");
            }

            if (tmpUtxo.txid === undefined) {
              throw new Error("UTXOService - addUtxos - txid is undefined");
            }

            if (tmpUtxo.script === undefined) {
              throw new Error("UTXOService - addUtxos - script is undefined");
            }

            utxosToAdd.push(tmpUtxo);
          }
        } catch (e) {
          console.error("UTXOService - addUtxos - error adding utxos", e);
        }
      }
    });

    this.store[address].utxos.push(...utxosToAdd);
    return this.store[address].utxos;
  }

  removeUtxos({ address, inputs }: RemoveUtxosParams): {
    updatedUtxos: Utxo[];
    updatedUsedUtxos: string[];
  } | null {
    if (!this.store[address]) {
      return null;
    }

    const usedInputs = new Set(
      inputs.map(
        (input) => `${input.prevTxId.toString("hex")}-${input.outputIndex}`,
      ),
    );

    const updatedUtxos = this.store[address].utxos.filter(
      (utxo) => !usedInputs.has(this.#serializeUtxo(utxo)),
    );
    const updatedUsedUtxos = Array.from(
      new Set([...this.store[address].usedUtxos, ...usedInputs]),
    );

    this.store[address].utxos = updatedUtxos;
    this.store[address].usedUtxos = updatedUsedUtxos;

    return {
      updatedUtxos,
      updatedUsedUtxos,
    };
  }

  updateUtxos({ address, tx, outputsToSkip }: UpdateUtxosParams) {
    tx = isString(tx) ? new Transaction(tx) : tx;
    const txId = tx.hash;

    const removeUtxoRes = this.removeUtxos({ address, inputs: tx.inputs });
    if (removeUtxoRes) {
      this.store[address].utxos = removeUtxoRes.updatedUtxos;
      this.store[address].usedUtxos = removeUtxoRes.updatedUsedUtxos;
    }

    this.addUtxos({
      address,
      outputs: tx.outputs,
      txId,
      outputsToSkip: outputsToSkip || [],
    });
  }

  getStore(address: string): UtxoStore[string] | null {
    return this.store[address] || null;
  }

  #mapUtxo(utxo: DogeUtxo): Utxo {
    return {
      txid: utxo.txid,
      vout: utxo.vout,
      script: utxo.script,
      satoshis: utxo.shibes,
    };
  }

  #mapSafeUtxosToUtxos(safeUtxos: DogeUtxo[]): Utxo[] {
    return safeUtxos
      .map((safeUtxo: DogeUtxo) => this.#mapUtxo(safeUtxo))
      .filter((utxo: Utxo) => utxo) as Utxo[];
  }

  #serializeUtxo(utxo: Utxo) {
    return `${utxo.txid}-${utxo.vout}`;
  }
}
