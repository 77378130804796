import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCopyToClipboard } from "usehooks-ts";
import {
  AnimatedClickable,
  AppScreen,
  BalanceCardCollectible,
  BalanceCardCollectibleLoading,
  BalanceCardDRC20,
  BalanceCardDrc20Loading,
  BalanceCardDunes,
  BalanceRowCollectible,
  BalanceRowCollectibleProps,
  BalanceRowDRC20,
  BalanceRowDRC20Props,
  BalanceRowDune,
  BalanceRowDuneProps,
  Button,
  Skeleton,
  Spinner,
  ToggleSwitch,
  toast,
} from "@/components";
import {
  MOONPAY_BUY_DOGE_LINK,
  ONE_DOGE_IN_SHIBES,
  FEATURE_ACTIVATION_DUNES,
} from "@/constants";
import {
  useAppContentSettings,
  useBalance,
  useCurrency,
  useDogePrice,
} from "@/contextHooks";
import { useCurrentAccount, useInfoModal } from "@/hooks";
import { shortenAddress } from "@/lib/address";
import {
  NumberFormatType,
  formatNumber,
  formatLargeNumber,
} from "@/lib/numbers";
import { cn } from "@/lib/utils";
import {
  ModalAccount,
  ModalInfo,
  ModalMissingWallet,
  ModalSendDoge,
  ModalSettings,
  ModalTotalValue,
} from "@/modals";
import { AccountProps, Content, Currency, DoginalInfo } from "@/types";
import { getIconForTick, getValueForCurrency } from "@/utility";
import { BalanceListItem } from "../Account/BalanceListItem";
import { useShowMissingWallet } from "@/hooks/useShowMissingWallet";
import { ChangeIndicator } from "../Trading/components/changeIndicator";
import {
  BalanceRowDrc20Loading,
  BalanceRowSkeleton,
} from "@/components/balanceRow/balanceRowDRC20";
import {
  BalanceRowCollectibleLoading,
  BalanceRowCollectibleSkeleton,
} from "@/components/balanceRow/balanceRowCollectible";

enum ModalType {
  SendDoge = "sendDoge",
  Account = "account",
  Settings = "settings",
  TotalValue = "totalValue",
}

const formatCurrencyValue = (value: number = 0, currencyType: Currency) =>
  `${currencyType}${formatNumber({
    value,
    type: NumberFormatType.Price,
    decimalPlaces: 2,
  })}`;

export const AccountView = ({ address, isOverview = false }: AccountProps) => {
  const navigation = useNavigate();
  const currentAccount = useCurrentAccount();
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();
  const [, copy] = useCopyToClipboard();
  const { onShowMissingWallet } = useShowMissingWallet({
    address: currentAccount?.address || "",
  });
  const { content, icon, changeContent } = useAppContentSettings();

  const isOwner = currentAccount?.address === address;
  const accountName = isOwner ? currentAccount?.name : shortenAddress(address);

  const { info, infoVisible, handleOpenInfo, handleCloseInfo } = useInfoModal();

  /**
   * Local State
   */
  // const [collectiblesData] = useState<CollectibleTradingData[]>([]);

  const [modalType, setModalType] = useState<ModalType | undefined>();
  const [isMissingWallet, setIsMissingWallet] = useState<boolean>(false);

  const openModalSendDoge = useCallback(() => {
    setModalType(ModalType.SendDoge);
  }, []);

  const openModalAccount = useCallback(() => {
    setModalType(ModalType.Account);
  }, []);

  const openModalSettings = useCallback(() => {
    setModalType(ModalType.Settings);
  }, []);

  const openModalTotalValue = useCallback(() => {
    setModalType(ModalType.TotalValue);
  }, []);

  const onCloseModal = useCallback(() => {
    setModalType(undefined);
  }, []);

  /**
   * Data Fetching
   */
  const {
    drc20s,
    dunes,
    isDrc20Loading,
    isDunesLoading,
    collectibles,
    isCollectiblesLoading,
    balanceOverview,
    isLoadingBalanceOverviewOfAddress,
    refetchDrc20PendingBalances,
    refetchBalanceOverview,
  } = useBalance();

  const isLoading = useMemo(
    () =>
      isLoadingBalanceOverviewOfAddress ||
      isDrc20Loading ||
      isDunesLoading ||
      isCollectiblesLoading,
    [
      isLoadingBalanceOverviewOfAddress,
      isDrc20Loading,
      isDunesLoading,
      isCollectiblesLoading,
    ],
  );

  /**
   * Actions
   */
  const onCopyAddress = useCallback(() => {
    address &&
      copy(address).then(() => {
        toast({
          title: "Address Copied",
          description: `This wallet address ${shortenAddress(address)} has been copied to the clipboard.`,
          variant: "default",
          duration: 800,
        });
      });
  }, [copy, address]);

  const onRefetch = useCallback(() => {
    refetchBalanceOverview();
  }, [refetchBalanceOverview]);

  // Data
  const itemOnClick = useCallback(
    (base: "drc20" | "collectible" | "dune", item: string) => {
      navigation(`/account/${base}/${item}`);
    },
    [navigation],
  );

  const balanceRowDataDRC20: BalanceRowDRC20Props[] = useMemo(() => {
    return drc20s
      .sort((a, b) => b.valueDollar - a.valueDollar)
      .map((item) => ({
        imageURI: getIconForTick(item.tick),
        name: item.tick,
        tick: item.tick,
        amount: item.amount,
        value: `${formatCurrencyValue(currency === Currency.USD ? item.valueDollar : item.valueDoge, currency)}`,
        valueChangeAbsolute:
          item.changeDoge != 0
            ? `${formatCurrencyValue(currency === Currency.USD ? item.changeDollar : item.changeDoge, currency)}`
            : "",
        colorForBalanceChange:
          item.changeDoge < 0 ? "text-red-500" : "text-green-500",
        price:
          item.priceData?.floorPrice?.usd == 0
            ? ""
            : `${formatCurrencyValue(currency === Currency.USD ? item.priceData?.floorPrice?.usd : item.priceData?.floorPrice?.doge, currency)}`,
        priceChange24H:
          item.priceData?.twentyFourHourChangePercent == 0
            ? ""
            : `${formatNumber({ value: item.priceData?.twentyFourHourChangePercent || 0, type: NumberFormatType.Percentage, decimalPlaces: 2 })}`,
        priceChange7D:
          item.priceData?.weeklyChangePercent == 0
            ? ""
            : `${formatNumber({ value: item.priceData?.weeklyChangePercent || 0, type: NumberFormatType.Percentage, decimalPlaces: 2 })}`,
        volume7D:
          item.priceData?.volume?.usd == 0
            ? ""
            : `${formatCurrencyValue(currency === Currency.USD ? item.priceData?.volume?.usd : item.priceData?.volume?.doge, currency)}`,
        onClick: () => itemOnClick("drc20", item.tick),
      }));
  }, [drc20s, currency, itemOnClick]);

  const balanceRowDataDunes: BalanceRowDuneProps[] = useMemo(() => {
    return dunes
      .sort((a, b) => b.valueDollar - a.valueDollar)
      .map((item) => ({
        imageURI: getIconForTick(item.tick),
        name: item.tick,
        tick: item.tick,
        amount: item.amount,
        value: `${formatCurrencyValue(currency === Currency.USD ? item.valueDollar : item.valueDoge, currency)}`,
        valueChangeAbsolute:
          item.changeDoge != 0
            ? `${formatCurrencyValue(currency === Currency.USD ? item.changeDollar : item.changeDoge, currency)}`
            : "",
        colorForBalanceChange:
          item.changeDoge < 0 ? "text-red-500" : "text-green-500",
        price:
          item.priceData?.floorPrice?.usd == 0
            ? ""
            : `${formatCurrencyValue(currency === Currency.USD ? item.priceData?.floorPrice?.usd : item.priceData?.floorPrice?.doge, currency)}`,
        priceChange24H:
          item.priceData?.twentyFourHourChangePercent == 0
            ? ""
            : `${formatNumber({ value: item.priceData?.twentyFourHourChangePercent || 0, type: NumberFormatType.Percentage, decimalPlaces: 2 })}`,
        priceChange7D:
          item.priceData?.weeklyChangePercent == 0
            ? ""
            : `${formatNumber({ value: item.priceData?.weeklyChangePercent || 0, type: NumberFormatType.Percentage, decimalPlaces: 2 })}`,
        volume7D:
          item.priceData?.volume?.usd == 0
            ? ""
            : `${formatCurrencyValue(currency === Currency.USD ? item.priceData?.volume?.usd : item.priceData?.volume?.doge, currency)}`,
        onClick: () => itemOnClick("dune", item.tick),
      }));
  }, [dunes, currency, itemOnClick]);

  const balanceRowDataCollectible: BalanceRowCollectibleProps[] =
    useMemo(() => {
      return collectibles
        .sort((a, b) => b.valueDollar - a.valueDollar)
        .map((item: DoginalInfo) => {
          return {
            imageURI: item.collectionImageURI,
            name: item.collectionName,
            symbol: item.collectionSymbol,
            amount: item.amount,
            numPendingSending: item.sendingPendingAmount,
            numPendingReceiving: item.receivingPendingAmount,
            value:
              item.valueDoge != 0
                ? `${formatCurrencyValue((currency === Currency.USD ? item.valueDollar : item.valueDoge) || 0, currency)}`
                : "",
            valueChangeAbsolute:
              item.changeDoge != 0
                ? `${formatCurrencyValue(currency === Currency.USD ? item.changeDollar : item.changeDoge, currency)}`
                : "",
            colorForBalanceChange:
              item.changeDoge < 0 ? "text-red-500" : "text-green-500",
            floor: !item.floorPrice
              ? ""
              : `${formatCurrencyValue(getValueForCurrency(item.floorPrice || 0, currency, dogePrice), currency)}`,
            floorChange24H:
              item.priceData?.twentyFourHourChangePercent != 0
                ? formatNumber({
                    value: item.priceData?.twentyFourHourChangePercent || 0,
                    type: NumberFormatType.Percentage,
                    decimalPlaces: 2,
                  })
                : "",
            floorChange7D:
              item.priceData?.weeklyChangePercent != 0
                ? formatNumber({
                    value: item.priceData?.weeklyChangePercent || 0,
                    type: NumberFormatType.Percentage,
                    decimalPlaces: 2,
                  })
                : "",
            sales7D:
              item.priceData?.sales != 0
                ? formatNumber({
                    value: item.priceData?.sales ?? 0,
                    type: NumberFormatType.Large_Number,
                    decimalPlaces: 2,
                  })
                : "",
            volume7D: !item.priceData?.volume?.usd
              ? ""
              : `${formatCurrencyValue(currency === Currency.USD ? item.priceData?.volume?.usd : item.priceData?.volume?.doge, currency)}`,
            onClick: () => itemOnClick("collectible", item.collectionSymbol),
          };
        });
    }, [collectibles, currency, dogePrice, itemOnClick]);

  const onBuy = useCallback(() => {
    window.open(MOONPAY_BUY_DOGE_LINK, "_blank");
  }, []);

  const balancePerformance = useMemo(() => {
    return (
      ((balanceOverview?.estimated_total_value?.doginals?.performance ?? 0) +
        (balanceOverview?.estimated_total_value?.drc20s?.performance ?? 0)) /
      ONE_DOGE_IN_SHIBES
    );
  }, [balanceOverview]);

  const {
    totalBalancePerformance,
    formattedTotalBalance,
    formattedTotalBalancePerformance,
  } = useMemo(() => {
    const totalBalance =
      currency === Currency.USD
        ? balanceOverview?.totalInUSD
        : balanceOverview?.totalInDOGE;
    const totalBalancePerformance =
      currency === Currency.USD
        ? balancePerformance * dogePrice
        : balancePerformance;
    const formattedTotalBalance = formatCurrencyValue(totalBalance, currency);
    const formattedTotalBalancePerformance = formatCurrencyValue(
      totalBalancePerformance,
      currency,
    );
    const colorForBalanceChange =
      totalBalancePerformance > 0
        ? "text-green-500"
        : totalBalancePerformance === 0
          ? "text-text-tertiary"
          : "text-red-500";
    return {
      totalBalance,
      totalBalancePerformance,
      formattedTotalBalance,
      formattedTotalBalancePerformance,
      colorForBalanceChange,
    };
  }, [balanceOverview, currency, dogePrice, balancePerformance]);

  function getCollectibleAmountLabel(amount: number) {
    return `${formatLargeNumber(amount)} ${amount == 1 ? "collectible" : "collectibles"}`;
  }

  const totalValueItems = useMemo(() => {
    const items = [
      {
        icon: "account_balance",
        label: "Total Value",
        labelDetail: "Total value of all assets.",
        handleOpenInfo,
        value: formatCurrencyValue(
          currency === Currency.DOGE
            ? balanceOverview?.totalInDOGE
            : balanceOverview?.totalInUSD,
          currency,
        ),
        // labelDetail: formatCurrencyValue(
        //   balanceOverview?.totalInDOGE,
        //   Currency.DOGE,
        // ),
        showLabelDetail: false,
      },
      {
        icon: "wallet",
        label: "Dogecoin",
        value: formatCurrencyValue(
          currency === Currency.DOGE
            ? balanceOverview?.safeBalanceInDoge
            : balanceOverview?.safeBalanceInUSD,
          currency,
        ),
        labelDetail: `Total value of available DOGE (minus inscriptions).\nBalance excluded due to inscriptions: Ð${formatNumber({ value: balanceOverview.estimated_total_value.safeBalance - balanceOverview.safeBalance, type: NumberFormatType.Price, decimalPlaces: 2 })}`,
        handleOpenInfo,
      },
      {
        icon: "toll",
        label: "DRC-20s",
        labelDetail:
          "Total value of all drc-20s based on quantity & floor price.",
        handleOpenInfo,
        value: formatCurrencyValue(
          currency === Currency.DOGE
            ? balanceOverview?.drc20sValueInDoge
            : balanceOverview?.drc20sValueInUSD,
          currency,
        ),
        valueDetail:
          balanceOverview?.estimated_total_value.drc20s.performance == 0
            ? ""
            : `${formatCurrencyValue(getValueForCurrency(balanceOverview?.estimated_total_value.drc20s.performance ?? 0, currency, dogePrice), currency)}`,
        // labelDetail: getCollectibleAmountLabel(
        //   balanceOverview?.estimated_total_value.drc20s.amount ?? 0,
        // ),
        colorForValueDetail:
          balanceOverview?.estimated_total_value.drc20s.performance < 0
            ? "text-red-500"
            : "text-green-500",
      },
      {
        icon: "wallpaper",
        label: "Collections",
        labelDetail:
          "Total value of all Collections based on quantity & collection floor price.",
        handleOpenInfo,
        value: formatCurrencyValue(
          currency === Currency.DOGE
            ? balanceOverview?.doginalsValueInDoge
            : balanceOverview?.doginalsValueInUSD,
          currency,
        ),
        valueDetail:
          balanceOverview?.estimated_total_value.doginals.performance == 0
            ? ""
            : `${formatCurrencyValue(getValueForCurrency(balanceOverview?.estimated_total_value.doginals.performance ?? 0, currency, dogePrice), currency)}`,
        // labelDetail: getCollectibleAmountLabel(
        //   balanceOverview?.estimated_total_value.doginals.amount ?? 0,
        // ),
        colorForValueDetail:
          balanceOverview?.estimated_total_value.doginals.performance < 0
            ? "text-red-500"
            : "text-green-500",
      },
    ];

    if (FEATURE_ACTIVATION_DUNES === "true") {
      items.push({
        icon: "radio_button_checked",
        label: "Dunes",
        labelDetail: `${getCollectibleAmountLabel(balanceOverview.estimated_total_value.dunes?.amount || 0)}`,
        handleOpenInfo,
        value: formatCurrencyValue(
          currency === Currency.DOGE
            ? balanceOverview?.dunesValueInDoge
            : balanceOverview?.dunesValueInUSD,
          currency,
        ),
        valueDetail:
          balanceOverview?.estimated_total_value.dunes.performance == 0
            ? ""
            : `${formatCurrencyValue(getValueForCurrency(balanceOverview?.estimated_total_value.dunes.performance ?? 0, currency, dogePrice), currency)}`,
        colorForValueDetail:
          balanceOverview?.estimated_total_value.dunes.performance < 0
            ? "text-red-500"
            : "text-green-500",
      });
    }

    return items;
  }, [
    balanceOverview?.doginalsValueInDoge,
    balanceOverview?.doginalsValueInUSD,
    balanceOverview?.drc20sValueInDoge,
    balanceOverview?.drc20sValueInUSD,
    balanceOverview?.dunesValueInDoge,
    balanceOverview?.dunesValueInUSD,
    balanceOverview.estimated_total_value.doginals.performance,
    balanceOverview.estimated_total_value.drc20s.performance,
    balanceOverview.estimated_total_value.dunes.performance,
    balanceOverview.estimated_total_value.safeBalance,
    balanceOverview.safeBalance,
    balanceOverview?.safeBalanceInDoge,
    balanceOverview?.safeBalanceInUSD,
    balanceOverview?.totalInDOGE,
    balanceOverview?.totalInUSD,
    currency,
    dogePrice,
    handleOpenInfo,
  ]);

  /**
   * Effects
   */

  // Load Pending Balances (only once)
  useEffect(() => {
    refetchDrc20PendingBalances();
  }, [refetchDrc20PendingBalances]);

  // Modal Missing Wallet
  useEffect(() => {
    if (!isOverview) {
      const showMissingWallet = onShowMissingWallet();
      if (showMissingWallet) {
        setIsMissingWallet(true);
      } else {
        setIsMissingWallet(false);
      }
    }
  }, [isOverview, onShowMissingWallet]);

  return (
    <AppScreen withBottomNav>
      {/** Mobile View */}
      <div className="flex flex-1 flex-col items-center px-4 pt-2 lg:hidden">
        <div
          className={cn(
            "flex flex-col space-y-8 ",
            "mx-auto w-full max-w-xl drop-shadow-lg xl:mt-8 xl:rounded-2xl xl:border-0.5 xl:border-border-primary xl:bg-gradient-to-t xl:from-background-primary xl:to-background-secondary xl:p-4 xl:pb-4",
          )}
        >
          {/** Header */}
          <div className="flex flex-row items-center justify-between">
            {isOwner ? (
              <Button
                variant="icon"
                shape="circle"
                onClick={openModalSettings}
                className="h-8 w-8 text-text-tertiary"
              >
                <span className="material-symbols-rounded text-lg">
                  settings
                </span>
              </Button>
            ) : (
              <Button
                variant="icon"
                shape="circle"
                onClick={() => navigation(-1)}
                className="h-8 w-8 text-text-tertiary"
              >
                <span className="material-symbols-rounded text-lg">
                  arrow_back_ios
                </span>
              </Button>
            )}

            <div className="pointer flex flex-row items-center space-x-1">
              {isOwner && (
                <Button
                  variant="icon"
                  shape="circle"
                  disabled={isLoading}
                  onClick={openModalAccount}
                  className="h-8 w-8 text-text-tertiary"
                >
                  <span className="material-symbols-rounded text-lg">
                    expand_circle_down
                  </span>
                </Button>
              )}
              <span className="text-sm text-text-primary">{accountName}</span>

              <Button
                variant="icon"
                shape="circle"
                onClick={onCopyAddress}
                className="h-8 w-8 text-text-tertiary"
              >
                <span className="material-symbols-rounded text-md">
                  content_copy
                </span>
              </Button>
            </div>

            <ToggleSwitch
              value={content}
              icon={icon}
              onChange={changeContent}
            />
          </div>

          {/** Balance & Primary Actions */}
          <div className="flex flex-col items-center space-y-8">
            <AnimatedClickable
              className="flex flex-col space-y-1 text-center"
              onClick={isLoading ? undefined : openModalTotalValue}
              whileHover={{ scale: 1 }}
            >
              {isLoading ? (
                <div className="flex flex-col items-center space-y-1">
                  <Skeleton className="h-8 w-28 bg-background-tertiary/70" />
                  <Skeleton className="h-5 w-10 bg-background-tertiary/70" />
                </div>
              ) : (
                <>
                  <span
                    className="text-4xl font-medium text-text-primary"
                    dangerouslySetInnerHTML={{
                      __html: formattedTotalBalance,
                    }}
                  />
                  {totalBalancePerformance > 0 && (
                    <ChangeIndicator
                      change={formattedTotalBalancePerformance}
                      className="justify-center text-sm font-medium"
                    />
                  )}
                </>
              )}
            </AnimatedClickable>
            {/* Actions */}
            {isOwner && (
              <div className="flex w-full flex-1 flex-row space-x-2 px-2">
                <div className="flex w-full flex-1 flex-row space-x-2 px-2">
                  <Button
                    className="flex-1"
                    shape="circle"
                    onClick={openModalSendDoge}
                    disabled={
                      balanceOverview?.totalInDOGE === 0 ||
                      isLoadingBalanceOverviewOfAddress
                    }
                  >
                    {`Send ${Currency.DOGE}`}
                  </Button>
                  <Button
                    className="flex-1"
                    shape="circle"
                    onClick={onCopyAddress}
                  >
                    {`Receive ${Currency.DOGE}`}
                  </Button>
                  <Button className="flex-1" shape="circle" onClick={onBuy}>
                    {`Buy ${Currency.DOGE}`}
                  </Button>
                </div>
              </div>
            )}
          </div>

          {/** Tabs */}
          <div className={cn("mt-8 flex flex-col", "mx-auto w-full")}>
            {content === Content.DRC20 &&
              (isDrc20Loading ? (
                <div className="flex flex-col space-y-2">
                  <BalanceCardDrc20Loading />
                </div>
              ) : !balanceRowDataDRC20?.length ? (
                <div className="flex flex-col items-center p-10 text-sm text-text-primary">
                  <span>No collectibles found</span>
                </div>
              ) : (
                <div className="flex flex-col space-y-2">
                  {balanceRowDataDRC20.map((item) => (
                    <BalanceCardDRC20
                      {...item}
                      key={item.name}
                      tick={item.name}
                      change={item.valueChangeAbsolute}
                    />
                  ))}
                </div>
              ))}

            {content === Content.COLLECTIBLES &&
              (isCollectiblesLoading ? (
                <div className="flex flex-col space-y-2">
                  <BalanceCardCollectibleLoading />
                </div>
              ) : !balanceRowDataCollectible?.length ? (
                <div className="flex flex-col items-center p-10 text-sm text-text-primary">
                  <span>No collectibles found</span>
                </div>
              ) : (
                <div className="flex flex-col space-y-2">
                  {balanceRowDataCollectible.map((item) => (
                    <BalanceCardCollectible
                      symbol={item.symbol}
                      numPendingSending={item.numPendingSending}
                      numPendingReceiving={item.numPendingReceiving}
                      key={item.name}
                      title={item.name}
                      value={item.value}
                      change={item.valueChangeAbsolute}
                      amount={item.amount}
                      imageURI={item.imageURI ?? ""}
                      onClick={item.onClick}
                    />
                  ))}
                </div>
              ))}

            {content === Content.DUNES &&
              (isDunesLoading ? (
                <div className="flex flex-col items-center p-20">
                  <Spinner />
                </div>
              ) : !balanceRowDataDunes?.length ? (
                <div className="flex flex-col items-center p-10 text-sm text-text-primary">
                  <span>No collectibles found</span>
                </div>
              ) : (
                <div className="flex flex-col space-y-2">
                  {balanceRowDataDunes.map((item) => (
                    <BalanceCardDunes
                      {...item}
                      key={item.name}
                      tick={item.name}
                      change={item.valueChangeAbsolute}
                    />
                  ))}
                </div>
              ))}
          </div>
        </div>
      </div>

      {/** Desktop View */}
      <div className="hidden h-full lg:flex lg:flex-row lg:space-x-4 lg:px-4 lg:pb-4 lg:pt-8 3xl:px-0">
        <div className="flex h-full w-2/3 flex-1 flex-col rounded-lg bg-background-secondary p-4 2xl:w-3/4">
          {content === Content.DRC20 && (
            <div className="flex flex-col space-y-2">
              {/** Header */}
              <div className="flex flex-row space-x-2 p-4">
                <div className="flex w-1/3" />
                <div className="flex w-2/3 flex-row space-x-2">
                  {["PRICE", "24H", "7D", "VOL (7D)", "VALUE"].map(
                    (item, index) => (
                      <span
                        key={index}
                        className="flex-1 text-right text-xs font-medium uppercase text-text-tertiary"
                      >
                        {item}
                      </span>
                    ),
                  )}
                </div>
              </div>

              {/** Items */}
              {isLoading ? (
                <BalanceRowDrc20Loading />
              ) : (
                balanceRowDataDRC20.map((item) => (
                  <BalanceRowDRC20 key={item.name} {...item} />
                ))
              )}
              {!isLoading && balanceRowDataDRC20.length === 0 && (
                <div className="flex flex-col items-center p-10 text-sm text-text-primary">
                  <span>No collectibles found</span>
                </div>
              )}
            </div>
          )}

          {content === Content.COLLECTIBLES && (
            <div className="flex flex-col space-y-2">
              {/** Header */}
              <div className="flex flex-row space-x-2 p-4">
                <div className="flex w-1/3" />
                <div className="flex w-2/3 flex-row space-x-2">
                  {["FLOOR", "24H", "7D", "VOL (7D)", "VALUE"].map(
                    (item, index) => (
                      <span
                        key={index}
                        className="flex-1 text-right text-xs font-medium uppercase text-text-tertiary"
                      >
                        {item}
                      </span>
                    ),
                  )}
                </div>
              </div>

              {/** Items */}
              {isLoading ? (
                <BalanceRowCollectibleLoading />
              ) : (
                balanceRowDataCollectible.map((item) => (
                  <BalanceRowCollectible key={item.name} {...item} />
                ))
              )}
              {!isLoading && balanceRowDataCollectible.length === 0 && (
                <div className="flex flex-col items-center p-10 text-sm text-text-primary">
                  <span>No collectibles found</span>
                </div>
              )}
            </div>
          )}

          {content === Content.DUNES && (
            <div className="flex flex-col space-y-2">
              {/** Header */}
              <div className="flex flex-row space-x-2 p-4">
                <div className="flex w-1/3" />
                <div className="flex w-2/3 flex-row space-x-2">
                  {["PRICE", "24H", "7D", "VOL (7D)", "VALUE"].map(
                    (item, index) => (
                      <span
                        key={index}
                        className="flex-1 text-right text-xs font-medium uppercase text-text-tertiary"
                      >
                        {item}
                      </span>
                    ),
                  )}
                </div>
              </div>

              {/** Items */}
              {isLoading ? (
                <div className="flex flex-col items-center p-10">
                  <Spinner />
                </div>
              ) : (
                balanceRowDataDunes.map((item) => (
                  <BalanceRowDune key={item.name} {...item} />
                ))
              )}
              {!isLoading && balanceRowDataDunes.length === 0 && (
                <div className="flex flex-col items-center p-10 text-sm text-text-primary">
                  <span>No dunes found</span>
                </div>
              )}
            </div>
          )}
        </div>

        {/** Sidebar */}
        <div className="flex h-full w-1/3 flex-col space-y-8 rounded-lg bg-background-secondary p-4 2xl:w-1/4">
          {/** Header */}
          <div className="flex flex-row items-center justify-between">
            {isOwner ? (
              <Button
                shape="circle"
                disabled={isDrc20Loading}
                onClick={openModalAccount}
                className="h-8 w-8 text-text-tertiary"
              >
                <span className="material-symbols-rounded text-lg">person</span>
              </Button>
            ) : (
              <div className="w-8" />
            )}

            <Button
              shape="circle"
              variant="ghost"
              className="flex h-8 flex-row items-center space-x-2 p-2 pl-3"
              onClick={onCopyAddress}
            >
              <span className="text-sm font-medium">{accountName}</span>

              <span className="material-symbols-rounded text-xs">
                content_copy
              </span>
            </Button>

            {isOwner ? (
              <Button
                shape="circle"
                onClick={openModalSettings}
                className="h-8 w-8 text-text-tertiary"
              >
                <span className="material-symbols-rounded text-lg">
                  settings
                </span>
              </Button>
            ) : (
              <Button
                shape="circle"
                onClick={onRefetch}
                className="h-8 w-8 text-text-tertiary"
                disabled={isLoading}
              >
                <span className="material-symbols-rounded text-lg">
                  refresh
                </span>
              </Button>
            )}
          </div>

          {/** Balance */}
          <div className="flex flex-col space-y-2">
            {totalValueItems?.map((item, index) => {
              return (
                <BalanceListItem key={index} isLoading={isLoading} {...item} />
              );
            })}
          </div>

          {/** Primary Actions */}
          {isOwner && (
            <div className="flex flex-row space-x-2">
              <Button
                className="flex-1"
                shape="circle"
                onClick={openModalSendDoge}
                disabled={
                  balanceOverview?.totalInDOGE === 0 ||
                  isLoadingBalanceOverviewOfAddress
                }
              >
                {`Send ${Currency.DOGE}`}
              </Button>
              <Button className="flex-1" shape="circle" onClick={onCopyAddress}>
                {`Receive ${Currency.DOGE}`}
              </Button>
              <Button className="flex-1" shape="circle" onClick={onBuy}>
                {`Buy ${Currency.DOGE}`}
              </Button>
            </div>
          )}
        </div>
      </div>

      <>
        {modalType === ModalType.Account && (
          <ModalAccount
            isVisible={modalType === ModalType.Account}
            onClose={onCloseModal}
            title="Account"
          />
        )}

        {modalType === ModalType.Settings && (
          <ModalSettings
            isVisible={modalType === ModalType.Settings}
            onClose={onCloseModal}
            title="Settings"
          />
        )}

        {modalType === ModalType.SendDoge && (
          <ModalSendDoge
            title={`Send ${Currency.DOGE}`}
            isVisible={modalType === ModalType.SendDoge}
            onClose={onCloseModal}
          />
        )}

        {modalType === ModalType.TotalValue && (
          <ModalTotalValue
            title="Total Value"
            isVisible={modalType === ModalType.TotalValue}
            onClose={onCloseModal}
            items={totalValueItems}
          />
        )}

        {isMissingWallet && (
          <ModalMissingWallet
            withBottomNav
            isVisible={isMissingWallet}
            onClose={() => setIsMissingWallet(false)}
          />
        )}

        {infoVisible && (
          <ModalInfo
            info={info}
            isVisible={infoVisible}
            onClose={handleCloseInfo}
          />
        )}
      </>
    </AppScreen>
  );
};
