import { AnimatePresence } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  LOW_NETWORK_FEE_RATE,
  MATCHING_FEE,
  ONE_DOGE_IN_SHIBES,
  SERVICE_FEE_SATS,
} from "@/constants";
import { FeeType } from "@/context/wallet/calculateNetworkFees.ts";
import { useDogePrice, useTxWallet } from "@/contextHooks";
import { useGetMaxSendableDrc20 } from "@/hooks";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { Currency, Drc20Listing, Drc20TickData } from "@/types";
import { AccountDrc20Data } from "@/types/inscription.ts";
import { BigNumber } from "bignumber.js";
import {
  ErrorView,
  ModalNavigation,
  Sheet,
  SheetProps,
  SuccessView,
} from "../base";
import { ListTransactionStep } from "./constants";
import { ConfirmView, OrderView } from "./views";

interface ModalListDRC20 extends SheetProps {
  token?: string;
  accountData?: AccountDrc20Data;
  tokenData?: Drc20TickData;
  onSuccess?: (cachebreaker?: boolean) => void;
  listings?: Drc20Listing[];
}

enum IconBack {
  BACK = "arrow_back_ios",
  CLOSE = "close",
}

interface StepDetails {
  flowIndex: number;
  title: string;
  iconBack?: IconBack;
}

const FLOW = [ListTransactionStep.ORDER, ListTransactionStep.CONFIRM];

export const ModalListDRC20: React.FC<ModalListDRC20> = ({
  token,
  accountData,
  listings,
  tokenData,
  onClose,
  onSuccess,

  ...props
}) => {
  const { floorPrice } = tokenData || {};
  const { dogePrice } = useDogePrice();
  const { calculateNetworkFees, listDrc20, txWallet } = useTxWallet();

  /**
   * Local State
   */

  const [amount, setAmount] = useState<string>("");
  const { transferInscriptions, maxListable } = useGetMaxSendableDrc20({
    accountData,
    amount,
    token,
    listings,
    isForSending: false,
  });

  const [unitPrice, setUnitPrice] = useState<string>("");
  const [transactionStep, setTransactionStep] = useState(FLOW[0]);
  const [networkFee, setNetworkFee] = useState<string>(`${Currency.DOGE} 0`);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);

  const [error, setError] = useState({
    title: "Error",
    description: "An error occurred while processing your request.",
  });

  // Get the current step details
  const { flowIndex, title, iconBack } = useMemo<StepDetails>(() => {
    if (!token)
      return {
        flowIndex: 0,
        title: "No Token Found!",
        iconBack: IconBack.CLOSE,
      };

    const flowIndex = FLOW.indexOf(transactionStep);

    let title = "";
    let iconBack = IconBack.BACK;

    switch (transactionStep) {
      case ListTransactionStep.ORDER:
        title = `List ${token.toUpperCase()}`;
        iconBack = IconBack.CLOSE;
        break;
      case ListTransactionStep.CONFIRM:
        title = "Confirm Order";
        break;
      default:
        title = "";
    }

    return {
      flowIndex,
      title,
      iconBack,
    };
  }, [transactionStep, token]);

  /**
   * Functions
   */

  const handleNext = useCallback(async () => {
    if (flowIndex < FLOW.length - 1) {
      setTransactionStep(FLOW[flowIndex + 1]);
    }
  }, [flowIndex]);

  const handleBack = useCallback(() => {
    if (flowIndex > 0) {
      setTransactionStep(FLOW[flowIndex - 1]);
    } else {
      setAmount("");
      setUnitPrice("");
      onClose?.();
    }
  }, [flowIndex, onClose]);

  const handleSetError = useCallback(
    (title: string, description: string) => {
      setError({ title, description });
      setTransactionStep(ListTransactionStep.ERROR);
    },
    [setError],
  );

  /**
   * Step 1: Order
   */

  const handleSetAmount = useCallback(
    (value: string) => {
      // Set the amount; Never set the amount higher than the available balance maxAmountInToken or maxAmountInUSD
      if (parseFloat(value) > maxListable) {
        setAmount(maxListable.toString());
      } else {
        setAmount(value);
      }
    },
    [maxListable],
  );

  // @todo remove duplicate code - modalSendDRC20
  const handleAmountChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const cleanInput = inputValue.replace(/[^0-9.]/g, "");

      // Validate against non-numeric characters but allow empty string and dots for decimal input
      const validInput =
        cleanInput === "" || /^0$|^[1-9]\d*(\.\d*)?$|^0\.\d*$/.test(cleanInput);
      if (validInput) {
        handleSetAmount(cleanInput);
      } else if (!inputValue) {
        handleSetAmount("0");
      }
    },
    [handleSetAmount],
  );

  const handleSetAmountMax = useCallback(async () => {
    handleSetAmount(maxListable.toString());
  }, [maxListable, handleSetAmount]);

  const handleUnitPriceChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const cleanInput = inputValue.replace(/[^0-9.]/g, "");

      // Validate against non-numeric characters but allow empty string and dots for decimal input
      const validInput =
        cleanInput === "" || /^0$|^[1-9]\d*(\.\d*)?$|^0\.\d*$/.test(cleanInput);

      if (validInput) {
        setUnitPrice(cleanInput);
      } else if (!inputValue) {
        setUnitPrice("0");
      }
    },
    [],
  );

  const handleSetUnitPriceFloor = useCallback(async () => {
    if (floorPrice) {
      const floorInDoge = (floorPrice / ONE_DOGE_IN_SHIBES).toString();
      setUnitPrice(floorInDoge);
    }
  }, [floorPrice]);

  const { potentialProceedsInUSD, potentialProceedsInDoge } = useMemo(() => {
    if (!amount || !unitPrice)
      return {
        potentialProceedsInUSD: `${Currency.USD}0`,
        potentialProceedsInDoge: `${Currency.DOGE}0`,
      };

    // Calculate the potential proceeds
    const bigAmount = new BigNumber(amount);
    const bigUnit = new BigNumber(unitPrice);
    const bigPotentialProceeds = bigAmount.multipliedBy(bigUnit);
    const potentialProceedsInDoge = bigPotentialProceeds.toNumber();
    return {
      // potentialProceedsInDoge: `${Currency.DOGE} ${formatNumber({ value: potentialProceedsInDoge, type: NumberFormatType.Price })}`,
      potentialProceedsInDoge: `${Currency.DOGE}${potentialProceedsInDoge}`,
      potentialProceedsInUSD: `${Currency.USD}${formatNumber({ value: potentialProceedsInDoge * dogePrice, type: NumberFormatType.Price })}`,
    };
  }, [amount, unitPrice, dogePrice]);

  const floorPriceInDoge = useMemo(() => {
    if (!floorPrice) return `${Currency.DOGE}0`;
    return `${Currency.DOGE}${formatNumber({ value: floorPrice / ONE_DOGE_IN_SHIBES, type: NumberFormatType.Price })}`;
  }, [floorPrice]);

  const floorPriceDeltaPercentage = useMemo(() => {
    if (
      !floorPrice ||
      isNaN(floorPrice) ||
      isNaN(parseFloat(unitPrice)) ||
      parseFloat(unitPrice) <= 0
    )
      return "0%";
    const floorPriceInShibes = floorPrice / ONE_DOGE_IN_SHIBES;
    const delta =
      (parseFloat(unitPrice) - floorPriceInShibes) / floorPriceInShibes;
    return `${formatNumber({ value: delta * 100, type: NumberFormatType.Percentage })}`;
  }, [unitPrice, floorPrice]);

  // Build the transaction & calculate the fees
  useEffect(() => {
    if (
      !token ||
      !amount ||
      !transferInscriptions ||
      !txWallet ||
      calculateNetworkFees.isLoading
    )
      return;

    calculateNetworkFees.execute(FeeType.LISTDRC20, {
      tick: token,
      amt: parseFloat(amount),
      transferInscriptionUtxos: transferInscriptions,
      feePerVByte: LOW_NETWORK_FEE_RATE,
    });
  }, [amount, calculateNetworkFees, token, transferInscriptions, txWallet]);

  useEffect(() => {
    if (calculateNetworkFees.isError && calculateNetworkFees.error) {
      handleSetError(
        "Error",
        (calculateNetworkFees.error as string) ||
          "An error occurred while calculating your fees.",
      );
    }
  }, [
    calculateNetworkFees.error,
    calculateNetworkFees.isError,
    handleSetError,
  ]);

  useEffect(() => {
    if (calculateNetworkFees.isSuccess && calculateNetworkFees.totalFeeInDoge) {
      setNetworkFee(
        `${Currency.DOGE} ${formatNumber({ value: calculateNetworkFees.totalFeeInDoge, type: NumberFormatType.Price })}`,
      );
    }
  }, [calculateNetworkFees.isSuccess, calculateNetworkFees.totalFeeInDoge]);

  /**
   * Step 2: Confirm
   */

  const priceInShibs = useMemo(() => {
    const bigShibe = new BigNumber(ONE_DOGE_IN_SHIBES);
    const bigUnit = new BigNumber(unitPrice);
    const bigUnitInShibes = bigUnit.multipliedBy(bigShibe);
    const bigAmount = new BigNumber(amount);
    const bigAmountInShibes = bigAmount.multipliedBy(bigUnitInShibes);
    return bigAmountInShibes.toNumber();
  }, [unitPrice, amount]);

  const tradingFee = useMemo(() => {
    const feeInShibes = (MATCHING_FEE / 100) * priceInShibs;
    const feeInDoge = formatNumber({
      value: feeInShibes / ONE_DOGE_IN_SHIBES,
      type: NumberFormatType.Price,
    });

    return {
      feeInShibes,
      feeInDoge,
    };
  }, [priceInShibs]);

  const handleToggleCheckbox = useCallback(() => {
    setIsCheckboxChecked((prev) => !prev);
  }, []);

  const handleList = useCallback(async () => {
    if (
      !token ||
      !amount ||
      !transferInscriptions ||
      !priceInShibs ||
      listDrc20.isLoading
    )
      return;

    await listDrc20.execute({
      priceInShibs,
      tick: token,
      amt: parseFloat(amount),
      transferInscriptionUtxos: transferInscriptions,
      feePerVByte: LOW_NETWORK_FEE_RATE,
    });
  }, [token, amount, transferInscriptions, priceInShibs, listDrc20]);

  useEffect(() => {
    if (listDrc20.isError && listDrc20.error && !listDrc20.isLoading) {
      handleSetError(
        "Error",
        (listDrc20.error as string) ||
          "An error occurred while processing your request.",
      );
    }
  }, [handleSetError, listDrc20.error, listDrc20.isError, listDrc20.isLoading]);

  useEffect(() => {
    if (listDrc20.isSuccess && !listDrc20.isLoading) {
      // wait a bit before updating the UI to ensure caches are updated
      setTimeout(() => onSuccess?.(), 2000);
      setTransactionStep(ListTransactionStep.SUCCESS);
    }
  }, [listDrc20.isLoading, listDrc20.isSuccess, onSuccess]);

  const isClearingTransactionsRequired = useMemo<boolean>(() => {
    const availableAmount = Number(accountData?.available ?? "0");
    return maxListable >= Number(amount) && Number(amount) > availableAmount;
  }, [accountData?.available, maxListable, amount]);

  /**
   * Step 3: Success / Error
   */

  const reset = useCallback(() => {
    setAmount("0");
    setUnitPrice("0");
    setTransactionStep(FLOW[0]);
    setIsCheckboxChecked(false);
    setNetworkFee(`${Currency.DOGE}0`);
    setError({
      title: "Error",
      description: "An error occurred while processing your request.",
    });
  }, []);

  const handleClose = useCallback(() => {
    reset();
    onClose?.();
  }, [onClose, reset]);

  return (
    <Sheet
      withHeader={false}
      classNameContent="flex flex-col text-text-primary h-full max-h-[800px] rounded-none pt-safe-offset-4 pb-safe-offset-4"
      {...props}
    >
      <ModalNavigation title={title} iconBack={iconBack} onBack={handleBack} />
      <AnimatePresence>
        <div className="relative flex flex-1 flex-col overflow-y-auto">
          {transactionStep === ListTransactionStep.ORDER && (
            <OrderView
              listing={{
                amount,
                unitPrice,
                floorPrice: floorPriceInDoge,
                floorPriceDeltaPercentage,
                onAmountChange: handleAmountChange,
                onAmountMax: handleSetAmountMax,
                onUnitPriceChange: handleUnitPriceChange,
                onUnitPriceFloor: handleSetUnitPriceFloor,
              }}
              proceeds={{
                potentialProceedsInDoge,
                potentialProceedsInUSD,
              }}
              tick={token}
              maxListable={maxListable}
              handleContinue={handleNext}
            />
          )}

          {transactionStep === ListTransactionStep.CONFIRM && (
            <ConfirmView
              listing={{
                amount,
                unitPrice: `${Currency.DOGE} ${unitPrice}`,
                floorPrice: floorPriceInDoge,
                floorPriceDeltaPercentage,
              }}
              proceeds={{
                potentialProceedsInDoge,
                potentialProceedsInUSD,
              }}
              fees={{
                network: networkFee,
                trading: `${Currency.DOGE} ${tradingFee.feeInDoge}`,
                service: `${Currency.DOGE} ${formatNumber({ value: SERVICE_FEE_SATS / ONE_DOGE_IN_SHIBES, type: NumberFormatType.Price })}`,
              }}
              loading={listDrc20.isLoading || calculateNetworkFees.isLoading}
              tick={token}
              handleList={handleList}
              checkbox={{
                visible: isClearingTransactionsRequired,
                onClick: () => handleToggleCheckbox(),
                value: isCheckboxChecked,
              }}
              //@todo: tokenData is required by the ConfirmView type but it uses a different type than the one we have
              // tokenData={tokenData}
            />
          )}

          {transactionStep === ListTransactionStep.SUCCESS && (
            <SuccessView
              handleContinue={handleClose}
              info="The balance may take a minute to update."
              //   handleOpenTransactions={handleOpenTransactions}
            />
          )}

          {transactionStep === ListTransactionStep.ERROR && (
            <ErrorView
              handleContinue={handleClose}
              title={error.title}
              description={error.description}
            />
          )}
        </div>
      </AnimatePresence>
    </Sheet>
  );
};
