import { LEGAL_TERMS } from "@/constants";

interface LegalFooterProps {
  label: string;
}

export const LegalFooter: React.FC<LegalFooterProps> = ({ label }) => {
  return (
    <div
      className="flex h-5 w-full cursor-pointer flex-col items-center justify-center"
      onClick={() => window.open(LEGAL_TERMS, "_blank")}
    >
      <p className="text-center text-2xs italic text-text-tertiary">
        By clicking <span className="text-text-primary">{label}</span>, you
        agree to the{" "}
        <span className="ml-1 text-text-primary underline">
          Terms of Service
        </span>
        .
      </p>
    </div>
  );
};
