import React, { useMemo } from "react";

import { Button, Input, Separator } from "@/components";
import { DOGE_LOGO } from "@/constants";
import { getColorForFloorDifference, getIconForTick } from "@/utility";

import { AnimatedContent } from "../../base";
import { CardHeader } from "../components";
import { Currency } from "@/types/currency";
import { InputCurrency } from "@/components/inputCurrency";
import { cn } from "@/lib/utils";

interface Listing {
  amount: string;
  unitPrice: string;
  floorPrice: string;
  floorPriceDeltaPercentage: string;
  onAmountChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAmountMax: () => void;
  onUnitPriceChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUnitPriceFloor: () => void;
}

interface Proceeds {
  potentialProceedsInUSD: string;
  potentialProceedsInDoge: string;
}

interface OrderViewProps {
  listing: Listing;
  proceeds: Proceeds;
  maxListable: number;
  tick?: string;

  handleContinue: () => void;
}

export const OrderView: React.FC<OrderViewProps> = ({
  listing,
  proceeds,
  maxListable,
  tick,
  handleContinue,
}) => {
  const {
    amount,
    unitPrice,
    floorPrice,
    floorPriceDeltaPercentage,
    onAmountChange,
    onAmountMax,
    onUnitPriceChange,
    onUnitPriceFloor,
  } = listing;
  const { potentialProceedsInUSD, potentialProceedsInDoge } = proceeds;
  const imageToken = getIconForTick(tick);

  const colorPercentage =
    floorPriceDeltaPercentage === "0%"
      ? "text-text-tertiary"
      : getColorForFloorDifference(
          parseFloat(floorPriceDeltaPercentage.replace("%", "")),
        );

  // const isBelowThreshold = parseFloat(unitPrice.split(" ")[1]) <= 0;
  const isBelowThreshold = useMemo(() => {
    return (
      parseFloat(potentialProceedsInDoge.replace(Currency.DOGE, "").trim()) <= 2
    );
  }, [potentialProceedsInDoge]);

  const isDataInvalid = useMemo(() => {
    if (amount === "" || unitPrice === "") return true;
    return (
      parseFloat(amount) <= 0 ||
      parseFloat(unitPrice.replace(Currency.DOGE, "").trim()) <= 0 ||
      parseFloat(unitPrice.split(".")[1]) <= 0
    );
  }, [amount, unitPrice]);

  if (!tick) return null;

  return (
    <AnimatedContent id="order">
      <div className="flex flex-1 flex-col space-y-2">
        <div className="flex flex-col space-y-4 rounded-lg bg-background-secondary p-4">
          <CardHeader image={imageToken} tick={tick} />

          <Separator />

          {/** Amount */}
          <div className="flex flex-col space-y-1">
            <span className="text-xs uppercase text-text-tertiary">Amount</span>
            <div className="relative flex flex-row items-center space-x-1 rounded-md bg-background-tertiary pr-2">
              <InputCurrency
                className="h-16 bg-background-tertiary text-xl font-medium"
                onChange={onAmountChange}
                symbolDirection={"suffix"}
                symbol={tick}
                value={amount}
              />
              <Button
                variant="ghost"
                shape="circle"
                onClick={onAmountMax}
                className="h-8 bg-background-secondary"
              >
                <span className="text-xs font-bold">Max</span>
              </Button>
            </div>
            <span className="w-full text-right text-xs text-text-tertiary">
              {`Available: ${maxListable} ${tick.toUpperCase()}`}
            </span>
          </div>

          {/** Price */}
          <div className="flex flex-col space-y-1">
            <span className="text-xs uppercase text-text-tertiary">
              Unit Price
            </span>
            <div className="relative flex flex-row items-center space-x-1 rounded-md bg-background-tertiary pr-2">
              <InputCurrency
                className="h-16 bg-background-tertiary text-xl font-medium text-text-primary"
                onChange={onUnitPriceChange}
                value={unitPrice}
                symbol={Currency.DOGE}
              />
              <Button
                variant="ghost"
                shape="circle"
                onClick={onUnitPriceFloor}
                className="h-8 bg-background-secondary"
              >
                <span className="text-xs font-bold">Floor</span>
              </Button>
            </div>
            <span className="inline-flex w-full items-center justify-end gap-1 text-right text-xs text-text-tertiary">
              <span
                className="text-xs text-text-tertiary"
                dangerouslySetInnerHTML={{ __html: `Floor: ${floorPrice}` }}
              />
              <span
                className="text-xs text-text-tertiary"
                dangerouslySetInnerHTML={{ __html: `•` }}
              />
              <span
                className="text-xs text-text-tertiary"
                dangerouslySetInnerHTML={{ __html: `Difference:` }}
              />
              <span
                className={cn("text-xs text-text-tertiary", colorPercentage)}
                dangerouslySetInnerHTML={{
                  __html: `${floorPriceDeltaPercentage}`,
                }}
              />
            </span>
          </div>
        </div>

        <div className="flex w-full flex-col items-center">
          <div className="flex h-8 w-8 flex-col items-center justify-center rounded-full bg-background-secondary">
            <span className="material-symbols-rounded text-xl text-text-tertiary">
              arrow_downward
            </span>
          </div>
        </div>

        <div className="flex flex-col space-y-4 rounded-lg bg-background-secondary p-4">
          <CardHeader image={DOGE_LOGO} tick="DOGE" />

          <Separator />

          {/** Proceeds */}
          <div className="flex flex-col space-y-1">
            <span className="text-xs uppercase text-text-tertiary">
              Potential Proceeds
            </span>
            <div className="relative flex flex-row items-center space-x-1 rounded-md border border-background-tertiary bg-background-secondary pr-2">
              <Input
                className="h-16 text-xl font-medium disabled:bg-background-secondary disabled:text-text-primary disabled:opacity-100"
                placeholder="0"
                disabled
                value={potentialProceedsInDoge}
              />
            </div>
            <span
              className="w-full text-right text-xs text-text-tertiary"
              dangerouslySetInnerHTML={{ __html: potentialProceedsInUSD }}
            />
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col space-y-1">
        {isBelowThreshold && !isDataInvalid && (
          <span className="text-xs text-red-500">
            Listings must exceed the minimum 2 DOGE threshold.
          </span>
        )}
        <Button
          size="large"
          variant="inverse"
          onClick={handleContinue}
          disabled={isDataInvalid || isBelowThreshold}
        >
          Review Listing
        </Button>
      </div>
    </AnimatedContent>
  );
};
