import { useCallback, useEffect, useState } from "react";

import { fetchPendingDrc20Balance, PendingBalanceResponse } from "@/hooks";

type UsePendingDrc20BalanceOfAddressReturn = {
  pendingBalances: PendingBalanceResponse | null;
  isError: boolean;
  error: Error | unknown;
  isLoading: boolean;
  fetchData: () => Promise<void>;
};

const usePendingDrc20BalanceOfAddress = (
  address?: string,
): UsePendingDrc20BalanceOfAddressReturn => {
  const [pendingBalances, setPendingBalances] =
    useState<PendingBalanceResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<Error | unknown>(null);

  const fetchData = useCallback(async () => {
    if (!address) return;

    setIsLoading(true);
    setIsError(false);
    setError(null);

    let result = null as PendingBalanceResponse | null;
    try {
      const pendingData = await fetchPendingDrc20Balance(address);
      result = pendingData;
    } catch (e: Error | unknown) {
      setError(e);
      setIsError(true);
    } finally {
      setPendingBalances(result);
      setIsLoading(false);
    }
  }, [address]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    pendingBalances,
    isError,
    isLoading,
    error,
    fetchData,
  };
};

export { usePendingDrc20BalanceOfAddress };
