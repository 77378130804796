import { motion } from "framer-motion";
import React, { useCallback, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { useBottomNavHeight } from "@/hooks";
import { cn } from "@/lib/utils";

import { AnimatedClickable } from "../animatedClickable";

interface Tab {
  name: string;
  icon: string;
  path: string;
  disabled?: boolean;
}

interface BottomTabNavigationProps {
  tabs: Tab[];
  className?: string;
}

// Get the index of the tab based on the pathname
function getTabIndex(pathName: string, tabs: Tab[]): number {
  const pathIndex = tabs.findIndex((tab) => tab.path === pathName);
  return pathIndex === -1 ? 0 : pathIndex;
}

export const BottomTabNavigation: React.FC<BottomTabNavigationProps> = ({
  tabs,
  className,
}) => {
  const bottomNavRef = useBottomNavHeight();
  const { pathname } = useLocation();

  // Check if the tab is active
  const isActive = useCallback(
    (path: string, index: number) => {
      // Explicitly handle the root path for the first tab (index 0)
      return pathname === path || (pathname === "/" && index === 0);
    },
    [pathname],
  );

  // Check if the tab is grayed out
  const isDisabled = useCallback((tab: Tab) => {
    return tab.disabled;
  }, []);

  // Calculate the indicator position
  const indicatorPosition = useMemo(() => {
    // Determine active index based on the path
    const index = pathname === "/" ? 0 : getTabIndex(pathname, tabs);
    return `${(index * 100) / tabs.length}%`;
  }, [pathname, tabs]);

  return (
    <nav
      ref={bottomNavRef}
      className={cn(
        "fixed bottom-0 left-0 right-0 z-20 flex w-full flex-col pt-3 backdrop-blur-xl pb-safe-or-3",
        className,
      )}
    >
      <motion.div
        className="absolute top-0 h-0.25 rounded-xl bg-text-primary"
        animate={{ left: indicatorPosition }}
        transition={{ type: "spring", stiffness: 800, damping: 50 }}
        style={{ left: indicatorPosition, width: `${100 / tabs.length}%` }}
      />
      <div className="flex flex-1 flex-row items-center justify-between">
        {tabs.map((tab, index) =>
          isDisabled(tab) ? (
            <div
              key={index}
              className="flex flex-1 items-center justify-center opacity-15"
            >
              <div className="material-symbols-rounded text-xxl flex h-8 w-8 flex-col items-center justify-center text-text-primary">
                {tab.icon}
              </div>
            </div>
          ) : (
            <NavLink
              key={index}
              to={tab.path}
              className="flex flex-1 items-center justify-center"
            >
              <AnimatedClickable
                className="material-symbols-rounded text-xxl flex h-8 w-8 cursor-pointer flex-col items-center justify-center text-text-primary"
                animate={{ opacity: isActive(tab.path, index) ? 1 : 0.5 }} // Change opacity based on active state
              >
                {tab.icon}
              </AnimatedClickable>
            </NavLink>
          ),
        )}
      </div>
    </nav>
  );
};
