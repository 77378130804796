import React, { useCallback, useMemo } from "react";

import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { cn } from "@/lib/utils";
import { getColorForChange, getIconForTick } from "@/utility";

import { AnimatedClickable } from "../animatedClickable";
import { ImageWithFallback } from "../imageWithFallback";
import { PendingIndicator } from "../pendingIndicator";
import { useNavigate } from "react-router-dom";
import { ChangeIndicator } from "@/routes/Trading/components/changeIndicator";
import { Skeleton } from "../ui/skeleton";

interface BalanceCardDRC20Props {
  tick: string;
  amount: number;
  amountPending?: number;
  value: string;
  change: string;

  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  colorForBalanceChange?: string;
}

export const BalanceCardDRC20: React.FC<BalanceCardDRC20Props> = ({
  tick,
  amount,
  amountPending,
  value,
  change,
  className,
  style,
  onClick,
  colorForBalanceChange,
}) => {
  const navigate = useNavigate();

  const [image, colorPercentage] = useMemo(() => {
    const image = getIconForTick(tick);
    const colorPercentage = getColorForChange(change);
    return [image, colorPercentage];
  }, [tick, change]);

  // Click on image should link to drc20 overview
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation(); // Prevent the event from bubbling up to the parent
      navigate(`/drc20/${tick.toLowerCase()}`);
    },
    [tick],
  );

  return (
    <AnimatedClickable
      onClick={onClick}
      className={cn(
        "flex flex-row items-center justify-between rounded-lg border-0.5 border-border-secondary/50 bg-background-secondary p-3 hover:bg-background-tertiary/50",
        className,
      )}
      style={style}
      whileHover={{ scale: 1.01 }}
    >
      <div className="flex flex-row items-center space-x-3">
        <AnimatedClickable
          onClick={handleClick}
          className={cn("flex flex-row overflow-hidden", className)}
          style={style}
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.99 }}
        >
          <ImageWithFallback image={image} className="z-50 h-11 w-11" />
        </AnimatedClickable>
        <div className="flex flex-col space-y-0 text-text-primary">
          <span className="text-md font-bold">{tick.toUpperCase()}</span>
          <div className="flex flex-row items-center space-x-3">
            <div className="flex flex-col py-0.5">
              <span className="text-sm text-text-tertiary">{`${formatNumber({
                value: amount,
                type: NumberFormatType.Large_Number,
                decimalPlaces: 2,
              })}`}</span>
            </div>
            <PendingIndicator pendingAmount={amountPending} />
          </div>
        </div>
      </div>

      <div className="flex flex-col items-end space-y-0 text-text-primary">
        <span
          className={"text-md font-medium text-text-primary"}
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        />

        {change != "0" && (
          <ChangeIndicator change={change} className="text-sm" />
        )}
      </div>
    </AnimatedClickable>
  );
};

export const BalanceCardSkeleton = () => {
  return (
    <Skeleton className="flex items-center justify-between rounded-lg bg-background-tertiary/50 p-4">
      <div className="flex w-1/3 flex-row items-center space-x-3">
        <Skeleton className="h-11 w-11 rounded-full bg-background-tertiary/70" />
        <div className="flex flex-col space-y-0.5">
          <Skeleton className="h-5 w-10 bg-background-tertiary/70" />
          <Skeleton className="h-5 w-8 bg-background-tertiary/70" />
        </div>
      </div>
      <div className="flex w-2/3 flex-row items-center space-x-2">
        <div className="justify-cente flex flex-1 flex-col items-end space-y-1">
          <Skeleton className="h-6 w-full max-w-20 flex-shrink bg-background-tertiary/30 font-medium uppercase" />
        </div>
      </div>
    </Skeleton>
  );
};

export const BalanceCardDrc20Loading = () => {
  return Array(3)
    .fill("")
    .map((_, index) => <BalanceCardSkeleton key={index} />);
};
