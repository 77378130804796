import { useCallback, useEffect, useState } from "react";

import { marketplaceApiV2 } from "@/lib/fetch";
import { Currency, DoginalInfo } from "@/types";
import { fetchCollectiblesTradingData } from "./useFetchDoginals";
import { getValueForCurrency, handleError } from "@/utility";
import { useDogePrice } from "@/contextHooks";

type RequestParams = {
  [key: string]: any;
};

// gets all the collectibles includeing collectionSymbol the user has
const fetchDoginalInfo = async (
  address: string,
  params?: RequestParams,
): Promise<DoginalInfo[]> => {
  const response = await marketplaceApiV2(false, { ...params, address }).get<{
    collectibleInfo: DoginalInfo[];
  }>(`/doginals/collection/info`);

  return response?.data.collectibleInfo || [];
};

const useFetchDoginalInfo = (address?: string) => {
  const { dogePrice } = useDogePrice();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | unknown>(undefined);
  const [isError, setIsError] = useState(false);
  const [collectibleInfo, setCollectibleInfo] = useState<DoginalInfo[]>([]);

  const fetch = useCallback(
    async (includeCollectibles?: boolean) => {
      if (!address) {
        setCollectibleInfo([]);
        return;
      }
      let result = [] as DoginalInfo[];
      try {
        setLoading(true);
        setError(undefined);
        setIsError(false);
        const collectibleInfo = await fetchDoginalInfo(address, {
          includeCollectibles: includeCollectibles !== false,
        });

        result = collectibleInfo;

        const symbols = collectibleInfo.map(
          (collectible) => collectible.collectionSymbol,
        );

        try {
          const tradingData = await fetchCollectiblesTradingData(symbols);
          result = result.map((item: DoginalInfo) => {
            const currentTradingData = tradingData.find(
              (data) => data.symbol === item.collectionSymbol,
            );
            if (!currentTradingData) return item;
            const priceData = {
              floorPrice: {
                doge: getValueForCurrency(
                  currentTradingData?.floorPrice ?? 0,
                  Currency.DOGE,
                  dogePrice,
                ),
                usd: getValueForCurrency(
                  currentTradingData?.floorPrice ?? 0,
                  Currency.USD,
                  dogePrice,
                ),
              },
              twentyFourHourChangePercent:
                currentTradingData?.twentyFourHourChangePercent ?? 0,
              weeklyChangePercent: currentTradingData?.weeklyChangePercent ?? 0,
              sales: currentTradingData.weeklySales ?? 0,
              volume: {
                doge: currentTradingData?.volume?.doge ?? 0,
                usd: currentTradingData?.volume?.usd ?? 0,
              },
            };
            return {
              ...item,
              priceData,
            };
          });
        } catch (e) {
          handleError(e);
        }
      } catch (error: Error | unknown) {
        setIsError(true);
        setError(error);
      } finally {
        setCollectibleInfo(result);
        setLoading(false);
      }
    },
    [address, dogePrice],
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { getCollectibles: fetch, collectibleInfo, loading, error, isError };
};

export { useFetchDoginalInfo };
