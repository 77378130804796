import React, {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Button, InputPassword, InputPin } from "@/components";
import { PIN_LENGTH } from "@/constants";
import { useWalletManagement } from "@/contextHooks";
import { useSecurityPreference } from "@/contextHooks";
import { useCurrentAccount, usePins } from "@/hooks";
import { ModalResetApp } from "@/modals";
import { Header, Sheet } from "@/modals/base";
import { handleError } from "@/utility";
import { useToggle } from "@uidotdev/usehooks";

type ProviderProps = {
  children: ReactNode;
};

type LoginPromptContextProps = {
  isLoginPromptOpen: boolean;
  openLoginPrompt: (
    withCloseOption: boolean,
    onCloseAction?: () => void,
  ) => Promise<unknown>;
  loginPromptUI: React.JSX.Element;
};

export const LoginPromptContext = createContext<
  LoginPromptContextProps | undefined
>(undefined);

type ResolveFuncType = ((value: unknown) => void) | null;

export const LoginPromptProvider = ({ children }: ProviderProps) => {
  const { showAccountPrivateKey, updatePW } = useWalletManagement() || {};
  const { address } = useCurrentAccount();
  const { securityPreference } = useSecurityPreference();
  const { pins, handleSetPin, handleClearPin, isPinSet } = usePins({
    pinNames: ["pin"],
  });

  const submitPinDisabled = useMemo(() => !isPinSet("pin"), [isPinSet]);

  // Local State
  const [resolveFunc, setResolveFunc] = useState<
    string | null | ResolveFuncType
  >(null);
  const [error, setError] = useState<undefined | string>(undefined);

  const [isLoginPromptOpen, setIsLoginPromptOpen] = useState(false);
  const [hasCloseOption, setHasCloseOption] = useState(false);
  const [password, setPassword] = useState("");
  const [alertVisible, toggleAlertVisible] = useToggle(false);
  const onClose = useRef<() => void>(); // Function to be called when the prompt is closed

  // Functions
  const openLoginPrompt = (
    withCloseOpen: boolean,
    onCloseAction?: () => void,
  ) => {
    setIsLoginPromptOpen(true);
    setHasCloseOption(withCloseOpen);
    if (onCloseAction) {
      onClose.current = () => onCloseAction();
    }
    return new Promise((resolve) => {
      setResolveFunc(() => resolve);
    });
  };

  const closeLoginPrompt = useCallback(
    (withOnCloseAction = true) => {
      setIsLoginPromptOpen(false);
      setPassword("");
      setHasCloseOption(false);

      if (onClose.current && withOnCloseAction) {
        onClose.current();
        onClose.current = undefined;
      }

      if (typeof resolveFunc === "function") {
        resolveFunc(securityPreference === "pin" ? pins : password);
        setResolveFunc(null);
      }
    },
    [resolveFunc, pins, password, securityPreference, onClose],
  );

  const handlePinChange = useCallback(
    (index: number, value: string) => {
      handleSetPin("pin", index, value);
    },
    [handleSetPin],
  );

  const handlePasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      setPassword(event.target.value);
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    // Common input validation
    if (!address) {
      setError("Address is required.");
      return;
    }

    // Initialize credentials based on the security preference
    let credentials = password.trim();
    let errorType = "password";

    if (securityPreference === "pin") {
      if (!isPinSet("pin")) {
        setError("PIN is not set.");
        return;
      }
      credentials = pins.pin.join("");
      errorType = "PIN";
    } else {
      if (!credentials) {
        setError("Password is required.");
        return;
      }
    }

    try {
      await showAccountPrivateKey?.(address, credentials);
      updatePW?.(credentials);
      setError(undefined); // Clear any previous errors
      closeLoginPrompt(false); // Close prompt on success
      if (securityPreference === "pin") {
        handleClearPin("pin"); // Reset pin
      } else {
        setPassword(""); // Clear password
      }
    } catch (e: Error | unknown) {
      handleError(e);
      setError(`Invalid ${errorType}`);
    }
  }, [
    address,
    password,
    securityPreference,
    pins,
    isPinSet,
    handleClearPin,
    showAccountPrivateKey,
    updatePW,
    closeLoginPrompt,
  ]);

  // Automatically fire handleSubmit when the user inserts the last digit of the pin
  // This is only applicable when the security preference is set to pin
  useEffect(() => {
    const numberPinsSet = pins.pin.filter((pin) => pin !== "").length;
    if (securityPreference === "pin" && numberPinsSet === PIN_LENGTH) {
      handleSubmit();
    }
  }, [pins.pin, securityPreference, handleSubmit]);

  const loginPromptUI =
    securityPreference === "password" ? (
      <>
        <Sheet isVisible={isLoginPromptOpen} withHeader={false}>
          <Header
            title="Enter Password"
            onAction={() => setIsLoginPromptOpen(true)}
            onClose={hasCloseOption ? closeLoginPrompt : undefined}
          />
          <div className="flex flex-col space-y-4 p-4">
            <InputPassword
              password={password}
              onChange={handlePasswordChange}
              className="h-12 border-0.5 border-border-secondary bg-background-primary"
            />
            <i className="text-center text-xs text-red-500">
              {error || "\u00A0"}
            </i>
            <Button
              size="large"
              variant="default"
              onClick={handleSubmit}
              disabled={!password}
            >
              <span>Submit Password</span>
            </Button>
          </div>
        </Sheet>
        <ModalResetApp onClose={toggleAlertVisible} visible={alertVisible} />
      </>
    ) : (
      <>
        <Sheet isVisible={isLoginPromptOpen} withHeader={false}>
          <Header
            title="Enter PIN"
            onAction={() => setIsLoginPromptOpen(true)}
            onClose={hasCloseOption ? closeLoginPrompt : undefined}
          />
          <div className="flex flex-col space-y-4 p-4">
            <div className="flex flex-col items-center">
              <InputPin pin={pins.pin} onChange={handlePinChange} />
            </div>
            <i className="text-center text-xs text-red-500">
              {error || "\u00A0"}
            </i>
            <div className="flex flex-col space-y-3">
              <Button
                size="small"
                variant="ghost"
                onClick={() => toggleAlertVisible()}
                className="text-text-tertiary"
              >
                <span>Reset App</span>
              </Button>
              <Button
                size="large"
                variant="default"
                onClick={handleSubmit}
                disabled={submitPinDisabled}
              >
                <span>Submit PIN</span>
              </Button>
            </div>
          </div>
        </Sheet>
        <ModalResetApp onClose={toggleAlertVisible} visible={alertVisible} />
      </>
    );

  return (
    <LoginPromptContext.Provider
      value={{
        isLoginPromptOpen,
        openLoginPrompt,
        loginPromptUI,
      }}
    >
      {children}
    </LoginPromptContext.Provider>
  );
};
