import { useCallback, useState } from "react";

import { buildSendCollectiblesTxs } from "@/context/wallet/helpers/sendCollectibles.ts";
import { broadcastAll } from "@/context/wallet/lib/transaction.ts";
import {
  TxWallet,
  TxWithFeeEstimation,
  WalletContextHandleReturn,
} from "@/context/wallet/types.ts";
import { Inscription } from "@/types";
import { handleError } from "@/utility";

type UseSendCollectiblesParams = {
  txWallet: TxWallet | undefined;
  address: string | undefined;
};

export type SendCollectiblesParams = {
  receiverAddresses: string[];
  inscriptions: Inscription[];
  feePerVByte: number;
};

export interface SendCollectiblesReturn extends WalletContextHandleReturn {
  execute: (sendCollectiblesParams: SendCollectiblesParams) => Promise<void>;
  txHashes: string[];
}

export const useSendCollectibles = (
  useSendParams: UseSendCollectiblesParams,
): SendCollectiblesReturn => {
  const [isLoading, setIsSendingCollectibles] = useState<boolean>(false);
  const [isError, setIsErrorCollectiblesSend] = useState<boolean>(false);
  const [isSuccess, setIsSuccessCollectiblesSend] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [txHashes, setTxHashes] = useState<any[]>([]);

  const { txWallet, address } = useSendParams;

  const buildSendCollectibles = useCallback(
    async (
      buildSendCollectiblesParams: SendCollectiblesParams,
    ): Promise<TxWithFeeEstimation[] | undefined> => {
      const estimateFeesOnly = false;
      const buildSendCollectiblesTxsParams = {
        txWallet,
        address,
        estimateFeesOnly,
        ...buildSendCollectiblesParams,
      };

      return buildSendCollectiblesTxs(buildSendCollectiblesTxsParams);
    },
    [address, txWallet],
  );

  const sendCollectibles = useCallback(
    async (sendCollectiblesParams: SendCollectiblesParams) => {
      setIsSendingCollectibles(true);
      setIsErrorCollectiblesSend(false);
      setIsSuccessCollectiblesSend(false);
      try {
        if (!txWallet) {
          throw new Error("No wallet found");
        }

        if (!address) {
          throw new Error("No address found");
        }

        const txsWithFeeEstimation = await buildSendCollectibles(
          sendCollectiblesParams,
        );
        if (!txsWithFeeEstimation) {
          throw new Error("No txs found");
        }

        const txs = txsWithFeeEstimation.map(
          (txWithFeeEstimation) => txWithFeeEstimation.tx,
        );

        const sentTxHashes = await broadcastAll(txs, {
          address,
          receiverAddresses: sendCollectiblesParams.receiverAddresses,
        });
        if (
          !sentTxHashes ||
          sentTxHashes.length === 0 ||
          sentTxHashes.length !== txs.length
        ) {
          throw new Error("Failed to broadcast transaction");
        }

        // This is done in buildSendCollectibles already
        for (const tx of txs) {
          txWallet.updateUtxos({ address, tx });
        }

        setTxHashes(sentTxHashes);

        setIsSuccessCollectiblesSend(true);
      } catch (e: Error | unknown) {
        setIsErrorCollectiblesSend(true);
        const message = handleError(e);
        setError(message);
      } finally {
        setIsSendingCollectibles(false);
      }
    },
    [txWallet, buildSendCollectibles, address],
  );

  const reset = useCallback(() => {
    setIsSendingCollectibles(false);
    setIsErrorCollectiblesSend(false);
    setIsSuccessCollectiblesSend(false);
    setError(null);
    setTxHashes([]);
  }, []);

  return {
    isLoading,
    isError,
    isSuccess,
    execute: sendCollectibles,
    reset,
    txHashes,
    error,
  };
};
