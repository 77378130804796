import { Drc20Utxo, DunesUtxo, InscriptionUtxo } from "@/types/inscription.ts";
import { Inscription } from "@/types";

export const getDrc20UtxosForInscriptionIds = (
  inscriptionIds: string[],
  utxos: Drc20Utxo[],
): null | Drc20Utxo[] => {
  const filteredUtxo = utxos.filter(
    (utxo: Drc20Utxo) =>
      utxo.inscription_id && inscriptionIds.includes(utxo.inscription_id),
  );

  if (filteredUtxo.length === 0) {
    return null;
  }

  return filteredUtxo;
};

export const getCollectibleUtxosForInscriptionIds = (
  inscriptionIds: string[],
  inscriptions: Inscription[],
): null | InscriptionUtxo[] => {
  const filteredInscriptions = inscriptions.filter(
    (inscription: Inscription) =>
      inscription.inscription_id &&
      inscriptionIds.includes(inscription.inscription_id),
  );

  if (filteredInscriptions.length === 0) {
    return null;
  }

  return filteredInscriptions.map((inscription) => {
    return {
      txid: inscription.utxo.txid,
      vout: inscription.utxo.vout,
      script: inscription.utxo.script,
      shibes: inscription.utxo.shibes,
      inscription_id: inscription.inscription_id,
      inscription_number: inscription.inscription_number,
      offset: inscription.offset,
    };
  });
};

// SDOGGS Dunes enhancements
export const getDuneUtxosForTxOutpoints = (
  tx_hashes: string[],
  utxos: DunesUtxo[],
): null | DunesUtxo[] => {
  const filteredUtxos = utxos.filter((utxo: DunesUtxo) =>
    tx_hashes.some((tx_hash, index) => tx_hash === utxo.txid),
  );

  if (filteredUtxos.length === 0) {
    return null;
  }

  return filteredUtxos;
};
