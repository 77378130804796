import { cn } from "@/lib/utils";

export interface LiveIndicatorProps {
  className?: string;
}

export const LiveIndicator = ({ className }: LiveIndicatorProps) => {
  return (
    <div
      className={cn(
        "flex h-3 w-3 flex-col items-center justify-center rounded-full bg-background-secondary",
        className,
      )}
    >
      <div className="relative flex h-2 w-2">
        <div className="absolute inline-flex h-full w-full animate-ping rounded-full bg-green-400 opacity-75" />
        <div className="relative inline-flex h-2 w-2 rounded-full bg-green-500" />
      </div>
    </div>
  );
};
