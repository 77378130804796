import { useCallback, useState } from "react";

import { APP_VERSION } from "@/constants";

type SetValue<T> = (value: T | ((prevValue: T) => T)) => void;

export const useLocalStorage = <T>(
  name: string,
  initialValue: T,
): { value: T; updateValue: SetValue<T> } => {
  const key = `dogemp:version-${APP_VERSION}:${name}`;
  // Retrieve the value from local storage
  const storedValue = localStorage.getItem(key);
  const initial = storedValue ? JSON.parse(storedValue) : initialValue;

  // Set up state to track the value
  const [value, setValue] = useState<T>(initial);

  // Update local storage when the state changes
  const updateValue: SetValue<T> = useCallback(
    (newValue) => {
      const valueToStore =
        newValue instanceof Function ? newValue(value) : newValue;
      setValue(valueToStore);
      localStorage.setItem(key, JSON.stringify(valueToStore));
    },
    [key, value],
  );

  return { value, updateValue };
};
