import { useCallback, useEffect, useState } from "react";
import { useCurrentAccount, useFetchDuneListingsForAddress } from "@/hooks";
import { DuneListing, Utxo } from "@/types";
import { AccountDunesData } from "@/types/inscription.ts";
import { useBalance } from "@/contextHooks";

type UseGetMaxSendableDuneParams = {
  accountData?: AccountDunesData;
  amount: string;
  token?: string;
  listings?: DuneListing[];
  isForSending: boolean;
};

type UseGetMaxSendableDuneReturn = {
  maxListable: number;
};

const useGetMaxSendableDune = ({
  accountData,
  amount,
  token,
  listings,
  isForSending,
}: UseGetMaxSendableDuneParams): UseGetMaxSendableDuneReturn => {
  const { address } = useCurrentAccount();
  const [maxListable, setMaxListable] = useState(0);
  // preferably we get the listings as props, but if parent cannot pass it down, we fetch it
  const { listings: fallbackListings } = useFetchDuneListingsForAddress({
    address,
  });
  const [duneListings, setDuneListings] = useState<DuneListing[]>(
    listings || fallbackListings,
  );

  const getMaxListable = useCallback(async () => {
    // return early if amount is less than max amount
    if (!token || !accountData || !accountData?.utxos?.length) {
      setMaxListable(Number(accountData?.availableBalance ?? "0"));
      return;
    }

    // 1. calculate max listable amount
    const listedAmount = duneListings.reduce(
      (acc, listing) => listing.amount + acc,
      0,
    );

    setMaxListable(Number(accountData?.availableBalance ?? "0") - listedAmount);
  }, [token, accountData, duneListings, amount]);

  useEffect(() => {
    getMaxListable();
  }, [getMaxListable]);

  useEffect(() => {
    if (listings) {
      setDuneListings(listings);
    }
  }, [listings]);

  return {
    maxListable,
  };
};

export { useGetMaxSendableDune };
