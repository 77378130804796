import { useCallback, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { DOGE_LABS_LOGO } from "@/constants";
import { useAppContentSettings, useBalance, useCurrency } from "@/contextHooks";
import { cn } from "@/lib/utils";
import { Currency, TickerElement } from "@/types";

import { AnimatedClickable } from "../animatedClickable";
import { ImageWithFallback } from "../imageWithFallback";
import { SearchDesktop } from "../search";
import { ToggleSwitch } from "../toggleSwitch";
import { Button } from "../ui/button";

interface Link {
  name: string;
  path: string;
  disabled?: boolean;
}

interface AppHeaderDesktopProps {
  links: Link[];
  tickers: TickerElement[];
  className?: string;
}

export const AppHeaderDesktop: React.FC<AppHeaderDesktopProps> = ({
  links,
  tickers,
  className,
}) => {
  const { pathname } = useLocation();
  const { currency, setCurrency } = useCurrency();
  const { content, icon, changeContent } = useAppContentSettings();
  const { balanceInDOGEForHeader, balanceInUSDForHeader } = useBalance();

  // Toggle between USD and DOGE
  const toggleCurrency = useCallback(() => {
    setCurrency(currency === Currency.USD ? Currency.DOGE : Currency.USD);
  }, [currency, setCurrency]);

  // Calculate the balance value based on the selected currency
  const value = useMemo(() => {
    if (currency === Currency.USD) {
      return balanceInUSDForHeader;
    }
    return balanceInDOGEForHeader;
  }, [balanceInDOGEForHeader, balanceInUSDForHeader, currency]);

  // Check if the tab is active
  const isActive = useCallback(
    (link: string, index: number) => {
      // Explicitly handle the root path for the first link (index 0)
      return (
        pathname === link ||
        pathname.includes(link) ||
        (pathname === "/" && index === 0)
      );
    },
    [pathname],
  );

  const isDisabled = useCallback((link: Link) => {
    return link.disabled;
  }, []);

  return (
    <div
      className={cn(
        "sticky top-0 flex w-full flex-col border-b-0.5 border-border-primary bg-background-primary drop-shadow-xl",
        className,
      )}
      style={{ zIndex: 101 }}
    >
      <div className="flex w-full flex-row items-center justify-between space-x-4 p-4">
        <div className="w-14" />
        <Ticker tickers={tickers} />
        <LiveIndicator />
      </div>

      <div className="flex w-full flex-row items-center justify-between space-x-4 border-t-0.5 border-border-primary bg-background-primary px-4 py-4">
        <div className="flex flex-row items-center space-x-8">
          <NavLink to="/trading" className="no-focus cursor-pointer">
            <Logo />
          </NavLink>

          <div className="flex flex-row space-x-4">
            {links.map((link, index) =>
              isDisabled(link) ? (
                <div
                  key={index}
                  className="h-fit cursor-not-allowed items-center justify-center rounded-full border-0.5 bg-background-primary px-4 py-2 text-sm font-semibold text-text-primary opacity-15"
                >
                  {link.name}
                </div>
              ) : (
                <NavLink
                  key={`NavLink-${index}`}
                  to={link.path}
                  className="no-focus"
                >
                  <AnimatedClickable
                    key={`AnimatedClickable-${index}`}
                    className={cn(
                      "h-fit cursor-pointer items-center justify-center rounded-full border-0.5 px-4 py-2 text-sm font-semibold text-text-primary hover:bg-background-tertiary",
                      isActive(link.path, index)
                        ? "border-border-secondary/50 bg-background-secondary"
                        : "border-transparent bg-background-primary",
                    )}
                    animate={{ opacity: isActive(link.path, index) ? 1 : 0.5 }}
                  >
                    {link.name}
                  </AnimatedClickable>
                </NavLink>
              ),
            )}
          </div>
        </div>

        <div className="flex flex-1 flex-row items-center justify-end space-x-4">
          <SearchDesktop />
          <ToggleSwitch
            value={content}
            icon={icon}
            onChange={changeContent}
            className="rounded-lg"
          />
          <Balance onClick={toggleCurrency} value={value} />
        </div>
      </div>
    </div>
  );
};

const Logo: React.FC = () => (
  <div className="flex flex-row items-center space-x-3">
    <ImageWithFallback
      className="h-10 w-10 rounded-full"
      image={DOGE_LABS_LOGO}
    />
    <span className="text-md font-semibold text-text-primary">Doge Labs</span>
  </div>
);

interface BalanceProps {
  value: string;
  onClick: () => void;
}

const Balance: React.FC<BalanceProps> = ({ value, onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="default"
      size="default"
      className="min-w-20 border-0.5 border-border-secondary bg-background-primary text-sm font-semibold text-text-primary hover:bg-background-tertiary"
    >
      <span
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    </Button>
  );
};

interface TickerProps {
  tickers: TickerElement[];
}

const Ticker: React.FC<TickerProps> = ({ tickers }) => {
  return (
    <div className="flex flex-1 flex-row items-center justify-center">
      {tickers.map((ticker, index) => {
        const isLast = index === tickers.length - 1;
        return (
          <div
            key={index}
            className="flex flex-row items-center space-x-2 text-xs font-semibold"
          >
            <span className="text-text-primary">{ticker.label}:</span>
            <span className="text-text-highlight">{ticker.value}</span>
            {!isLast && (
              <>
                <span className="text-text-primary">•</span>
                <span> </span>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

const LiveIndicator: React.FC = () => {
  return (
    <div className="flex w-14 flex-row items-center justify-center space-x-2 rounded-full bg-background-secondary/50 py-1">
      <div className="relative flex h-2 w-2">
        <div className="absolute inline-flex h-full w-full animate-ping rounded-full bg-green-400 opacity-75" />
        <div className="relative inline-flex h-2 w-2 rounded-full bg-green-500" />
      </div>
      <span className="text-xs font-semibold text-text-primary">Live</span>
    </div>
  );
};
