import { useCallback, useRef } from "react";

interface UseInfiniteScrollOptions {
  isLoadingData: boolean;
  hasMoreData: boolean;
  isLoadingMoreData: boolean;
  setOffset?: (offset: number) => void;
  offset: number;
  paginationLimit: number;
}

export const useInfiniteScroll = ({
  isLoadingData,
  hasMoreData,
  isLoadingMoreData,
  setOffset,
  offset,
  paginationLimit,
}: UseInfiniteScrollOptions) => {
  const observer = useRef<IntersectionObserver | null>(null);

  const bottomOfTableRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoadingData || !hasMoreData || isLoadingMoreData || !setOffset)
        return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            window.requestAnimationFrame(() => {
              setOffset(offset + paginationLimit);
            });
          }
        },
        { threshold: 1 },
      );

      if (node) observer.current.observe(node);
    },
    [
      isLoadingData,
      hasMoreData,
      isLoadingMoreData,
      setOffset,
      offset,
      paginationLimit,
    ],
  );

  return bottomOfTableRef;
};
