import { FALLBACK_DOGE_PRICE } from "@/constants";
import { marketplaceApiV2 } from "@/lib/fetch";
import { handleError } from "@/utility";

type DogePriceInUsdResponse = {
  dogePriceInUsd: number;
};

const fromDogeToUSD = (dogeAmount: number, dogePrice: number): number => {
  return dogeAmount * dogePrice;
};

const fetchDogePrice = async () => {
  try {
    const endpoint = `/config/doge/price`;
    const response =
      await marketplaceApiV2(true).get<DogePriceInUsdResponse>(endpoint);

    return response?.data.dogePriceInUsd ?? FALLBACK_DOGE_PRICE;
  } catch (e: Error | unknown) {
    handleError(e);
    return FALLBACK_DOGE_PRICE;
  }
};

export { fromDogeToUSD, fetchDogePrice };
