import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  ActivityCardDRC20,
  Skeleton,
  TableContentDRC20,
  TableExplore,
} from "@/components";
import { PAGINATION_LIMIT } from "@/constants";
import {
  useCurrency,
  useDogePrice,
  useDrc20TokenList,
  useDrc20Watchlist,
} from "@/contextHooks";
import { useInfiniteScroll } from "@/hooks";
import { Tab } from "@/types";

import { EmptyTopMover, EmptyWatchlist, TopMoverGradient } from "../components";
import {
  ScrollabelCarousel,
  ScrollableCarouselItem,
} from "@/components/scrollableCarousel";

interface TradingDRC20Props {
  selectedTab: string;
}

export const TradingDRC20: React.FC<TradingDRC20Props> = ({ selectedTab }) => {
  const navigate = useNavigate();
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();

  /**
   * Tab Checks
   */

  const { isTabTop, isTabTrending, isTabRecent, isTabWatchlist } =
    useMemo(() => {
      return {
        isTabTop: selectedTab === Tab.Top,
        isTabTrending: selectedTab === Tab.Trending,
        isTabRecent: selectedTab === Tab.Recent,
        isTabWatchlist: selectedTab === Tab.Watchlist,
      };
    }, [selectedTab]);

  /**
   * Data Fetching & Processing
   */

  const {
    // Table Data
    tableOffset,
    isLoadingTableData,
    isLoadingMoreTableData,
    isMoversLoading,
    tableData,
    debouncedSetTableOffset,
    hasMoreTableData,
    // Movers Data
    topMovers,
    topSales,
    recentSales,
  } = useDrc20TokenList();

  const { watchlist, addToWatchlist, removeFromWatchlist, getIsOnWatchlist } =
    useDrc20Watchlist();

  // Table Data with Watchlist
  const drc20DataWithWatchlist = useMemo(() => {
    return tableData.map((token) => ({
      ...token,
      currency,
      currentDogePrice: dogePrice,
      watchlist: getIsOnWatchlist(token.id),
      onAddToWatchlist: () => addToWatchlist(token.id),
      onRemoveFromWatchlist: () => removeFromWatchlist(token.id),
    }));
  }, [
    currency,
    dogePrice,
    tableData,
    getIsOnWatchlist,
    addToWatchlist,
    removeFromWatchlist,
  ]);

  // For Infinite Scroll
  const bottomTableRef = useInfiniteScroll({
    isLoadingData: isLoadingTableData,
    hasMoreData: hasMoreTableData,
    isLoadingMoreData: isLoadingMoreTableData,
    setOffset: debouncedSetTableOffset,
    offset: tableOffset,
    paginationLimit: PAGINATION_LIMIT,
  });

  /**
   * Navigation
   */
  // Navigate to Token
  const onNavigateToToken = useCallback(
    (tick: string) => {
      navigate(`/drc20/${tick.toLowerCase()}`);
    },
    [navigate],
  );

  return (
    <>
      <div className="relative">
        <div className="lg:py4 py-2">
          {isMoversLoading ? (
            <div className="flex flex-row space-x-2 overflow-x-auto px-4 py-2 lg:space-x-4 lg:py-4">
              {Array.from({ length: 10 }).map((_, index) => (
                <Skeleton
                  key={index}
                  className="aspect-square w-28 flex-shrink-0 rounded-lg md:w-40"
                />
              ))}
            </div>
          ) : (
            <>
              {isTabTop &&
                (topMovers.length > 0 ? (
                  <ScrollabelCarousel>
                    {topMovers.map((data) => {
                      const {
                        id: tick,
                        value,
                        percentage,
                        percentageTimeframe,
                      } = data;
                      const cardData = { ...{ tick }, ...data };
                      const navigateToToken = () => onNavigateToToken(tick);
                      return (
                        <ScrollableCarouselItem
                          key={`carousel-${tick}`}
                          className="basis-[25%] sm:basis-[18%] lg:basis-[15%] xl:basis-[11.25%]"
                        >
                          <ActivityCardDRC20
                            key={tick}
                            valuePrimary={value}
                            valueSecondary={percentage}
                            valueSecondarySuffix={percentageTimeframe}
                            onClick={navigateToToken}
                            includeIndicatorSymbol
                            {...cardData}
                          />
                        </ScrollableCarouselItem>
                      );
                    })}
                  </ScrollabelCarousel>
                ) : (
                  <EmptyTopMover />
                ))}

              {isTabTrending &&
                (topSales.length > 0 ? (
                  <ScrollabelCarousel>
                    {topSales.map((data) => {
                      const { id: tick, value, sales, timeframe } = data;
                      const cardData = { ...{ tick }, ...data };
                      const navigateToToken = () => onNavigateToToken(tick);
                      return (
                        <ScrollableCarouselItem
                          key={`carousel-${tick}`}
                          className="basis-[25%] sm:basis-[18%] lg:basis-[15%] xl:basis-[11.25%]"
                        >
                          <ActivityCardDRC20
                            key={tick}
                            valuePrimary={value}
                            valueSecondary={sales}
                            valueSecondarySuffix={timeframe}
                            onClick={navigateToToken}
                            {...cardData}
                          />
                        </ScrollableCarouselItem>
                      );
                    })}
                  </ScrollabelCarousel>
                ) : (
                  <EmptyTopMover />
                ))}

              {isTabRecent &&
                (recentSales.length > 0 ? (
                  <ScrollabelCarousel>
                    {recentSales.map((data) => {
                      const { id: tick, value, sales, timeframe } = data;
                      const cardData = { ...{ tick }, ...data };
                      const navigateToToken = () => onNavigateToToken(tick);
                      return (
                        <ScrollableCarouselItem
                          key={`carousel-${tick}`}
                          className="basis-[25%] sm:basis-[18%] lg:basis-[15%] xl:basis-[11.25%]"
                        >
                          <ActivityCardDRC20
                            key={tick}
                            valuePrimary={value}
                            valuePrimarySuffix={timeframe}
                            valueSecondary={sales}
                            onClick={navigateToToken}
                            {...cardData}
                          />
                        </ScrollableCarouselItem>
                      );
                    })}
                  </ScrollabelCarousel>
                ) : (
                  <EmptyTopMover />
                ))}

              {isTabWatchlist &&
                (watchlist.length > 0 ? (
                  <ScrollabelCarousel id="watchlist-drc20">
                    {watchlist.map((data) => {
                      const { id: tick, value, percentage, mcapInUSD } = data;
                      const cardData = { ...{ tick }, ...data };
                      const navigateToToken = () => onNavigateToToken(tick);
                      return (
                        <ScrollableCarouselItem
                          key={`carousel-${tick}`}
                          className="basis-[25%] sm:basis-[18%] lg:basis-[15%] xl:basis-[11.25%]"
                        >
                          <ActivityCardDRC20
                            key={tick}
                            valuePrimary={value}
                            valueSecondary={mcapInUSD || ""}
                            valueSecondaryIsChange={false}
                            onClick={navigateToToken}
                            {...cardData}
                          />
                        </ScrollableCarouselItem>
                      );
                    })}
                  </ScrollabelCarousel>
                ) : (
                  <EmptyWatchlist />
                ))}
            </>
          )}
        </div>

        {/** Don't display the gradient on the <EmptyWatchlist /> */}
        {!(isTabWatchlist && watchlist.length === 0) && <TopMoverGradient />}
      </div>

      <TableExplore
        defaultSortId="volumeThirtyDays"
        columns={TableContentDRC20}
        data={drc20DataWithWatchlist}
        loading={isLoadingTableData}
        loadingMore={isLoadingMoreTableData}
      />

      <div ref={bottomTableRef} />
    </>
  );
};
