import { useLocalStorage } from "@/hooks";
import { PendingCollectibleInscription } from "@/types";
import { ReactNode, createContext, useCallback } from "react";

/*
  This provider is for sent or delisted collectibles that are pending.
  Users can do 3 actions with Collectibles: Send, List, Delist.
  - Listing is immediate, we don't wait for confirmations on the network.
  - Sending and Delisting are not immediate, we wait for confirmations on the network. So we need to keep track of these actions, and show them in the UI as pending until they are confirmed.
  - We use this context to save and update these pending items in localStorage, the data structure is as follows:
    {
      collectionSymbol: [
        {
          inscriptionId: string,
          action: "send" | "delist",
        }
      ]
    }
  - The useRemovePendingCollectibles hook is used to remove pending items from localStorage when they are confirmed.
*/

type CollectiblePendingItemsContextType = {
  pendingItems: { [key: string]: PendingCollectibleInscription[] };
  addPendingInscriptions: (
    inscriptions:
      | PendingCollectibleInscription
      | PendingCollectibleInscription[],
    collectionName?: string,
  ) => void;
  removePendingInscriptions: (
    inscriptionIds: string | string[],
    collectionName?: string,
  ) => void;
};

type CollectiblePendingItemsProviderProps = {
  children: ReactNode;
};

const CollectiblePendingItemsContext =
  createContext<CollectiblePendingItemsContextType>({
    pendingItems: {},
    addPendingInscriptions: () => {},
    removePendingInscriptions: () => {},
  });

const CollectiblePendingItemsProvider = ({
  children,
}: CollectiblePendingItemsProviderProps) => {
  const { value: actionedInscriptions, updateValue } = useLocalStorage<{
    [key: string]: PendingCollectibleInscription[];
  }>("pendingInscriptions", {});

  const addPendingInscriptions = useCallback(
    (
      inscriptions:
        | PendingCollectibleInscription
        | PendingCollectibleInscription[],
      collectionName?: string,
    ) => {
      if (!collectionName) return;
      updateValue(
        (prev: { [key: string]: PendingCollectibleInscription[] }) => {
          const updatedInscriptions = { ...prev };
          const newInscriptions = Array.isArray(inscriptions)
            ? inscriptions
            : [inscriptions];

          if (!updatedInscriptions[collectionName]) {
            updatedInscriptions[collectionName] = [];
          }

          updatedInscriptions[collectionName] = [
            ...updatedInscriptions[collectionName],
            ...newInscriptions,
          ];

          return updatedInscriptions;
        },
      );
    },
    [updateValue],
  );

  const removePendingInscriptions = useCallback(
    (inscriptionIds: string | string[], collectionName?: string) => {
      if (!collectionName) return;
      updateValue(
        (prev: { [key: string]: PendingCollectibleInscription[] }) => {
          const updatedInscriptions = { ...prev };
          const idsToRemoveArray = Array.isArray(inscriptionIds)
            ? inscriptionIds
            : [inscriptionIds];

          if (updatedInscriptions[collectionName]) {
            updatedInscriptions[collectionName] = updatedInscriptions[
              collectionName
            ].filter(
              (item: PendingCollectibleInscription) =>
                !idsToRemoveArray.includes(item.inscriptionId),
            );
            if (updatedInscriptions[collectionName].length === 0) {
              delete updatedInscriptions[collectionName];
            }
          }
          return updatedInscriptions;
        },
      );
    },
    [updateValue],
  );

  return (
    <CollectiblePendingItemsContext.Provider
      value={{
        pendingItems: actionedInscriptions,
        addPendingInscriptions,
        removePendingInscriptions,
      }}
    >
      {children}
    </CollectiblePendingItemsContext.Provider>
  );
};

export { CollectiblePendingItemsProvider, CollectiblePendingItemsContext };
