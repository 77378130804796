import React, { useCallback, useRef } from "react";

import { Input } from "@/components";
import { cn } from "@/lib/utils";

interface InputPinProps {
  pin: string[]; // Array of strings representing each digit of the pin
  onChange: (index: number, value: string) => void;
  className?: string;
  classNameInput?: string;
}

export const InputPin: React.FC<InputPinProps> = ({
  pin,
  onChange,
  className,
  classNameInput,
}) => {
  // Create an array of refs for each pin input
  const pinInputRef = useRef<Array<React.RefObject<HTMLInputElement>>>(
    Array.from({ length: pin.length }, () =>
      React.createRef<HTMLInputElement>(),
    ),
  );

  // Handle pin change
  const handlePinChange = useCallback(
    (index: number, value: string) => {
      onChange(index, value);

      // Focus on the next input when a digit is entered
      if (value && index < pin.length - 1) {
        pinInputRef.current[index + 1].current?.focus();
      }
    },
    [pin, onChange],
  );

  // Handle backspace key press
  const handleKeyDownPin = useCallback(
    (index: number, event: React.KeyboardEvent) => {
      // Focus on the previous input when backspace is pressed
      if (event.key === "Backspace" && !pin[index] && index > 0) {
        pinInputRef.current[index - 1].current?.focus();
      }
    },
    [pin],
  );

  // Focus on first input when component mounts
  // useEffect(() => {
  //   pinInputRef.current[0].current?.focus();
  // }, []);

  return (
    <div
      className={cn(
        "flex space-x-2 rounded-full bg-background-primary p-2",
        className,
      )}
    >
      {pin.map((digit, index) => (
        <Input
          key={index}
          ref={pinInputRef.current[index]}
          type="password"
          maxLength={1}
          className={cn(
            "h-9 w-9 rounded-full bg-background-secondary text-center",
            classNameInput,
          )}
          value={digit}
          onChange={(event) => handlePinChange(index, event.target.value)}
          onKeyDown={(event) => handleKeyDownPin(index, event)}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          pattern="[0-9]*"
          inputMode="numeric"
        />
      ))}
    </div>
  );
};
