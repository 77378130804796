import { useCallback } from "react";
import { useShowInstallMessage } from "./useShowInstallMessage";
import { AccountProps } from "@/types";

const hasCompletedWalletSetupStorageKey = `hasCompletedWalletSetup`;

interface ShowMissingWalletFunctionReturn {
  onShowMissingWallet: () => boolean;
}

export const useShowMissingWallet = ({
  address,
}: AccountProps): ShowMissingWalletFunctionReturn => {
  const showInstallMessage = useShowInstallMessage();

  const onShowMissingWallet = useCallback((): boolean => {
    if (!showInstallMessage) {
      const hasCompletedWalletSetup =
        localStorage.getItem(hasCompletedWalletSetupStorageKey) === "true";
      const shouldOpenMissingWalletModal = !hasCompletedWalletSetup && !address;
      const shouldCloseModal = hasCompletedWalletSetup;

      // Open the missing wallet modal if necessary
      if (shouldOpenMissingWalletModal) {
        return true;
      }
      // Close the modal if the wallet setup is completed
      else if (shouldCloseModal) {
        return false;
      }
    } else {
      return false;
    }
    return false;
  }, [address, showInstallMessage]);

  return {
    onShowMissingWallet,
  };
};
