import { useCallback, useEffect, useState } from "react";

import { d20ApiV2 } from "@/lib/fetch";
import { handleError } from "@/utility";

type RawHolder = {
  rank: number;
  percentage: string;
  address: string;
  b58Address: string;
  value: number;
  transferable: number;
  available: number;
};

type Drc20Holder = {
  rank: number;
  percentage: string;
  address: string;
  value: number;
  transferable: number;
  available: number;
};

const useFetchTopHolders = (tick?: string, amt: number = 100) => {
  const [topHolders, setTopHolders] = useState<Drc20Holder[]>([]);
  const [isTopHoldersLoading, setIsTopHoldersLoading] = useState(false);

  const fetchTop100Holders = useCallback(async () => {
    setIsTopHoldersLoading(true);
    try {
      if (!tick) throw new Error("Tick not found");
      const res = await d20ApiV2().get(
        `/trading/tokenholderinfo/${tick}?size=${amt}&page=0`,
      );

      if (!res.data.length) {
        setTopHolders([]);
        return;
      }
      const holders: Drc20Holder[] = res.data.map((holder: RawHolder) => ({
        rank: holder.rank,
        percentage: holder.percentage,
        address: holder.b58Address,
        value: holder.value,
        transferable: holder.transferable,
        available: holder.available,
      }));

      setTopHolders(holders);
    } catch (e: Error | unknown) {
      handleError(e);
      setTopHolders([]);
      throw new Error("Failed to fetch top 100 holders");
    } finally {
      setIsTopHoldersLoading(false);
    }
  }, [amt, tick]);

  useEffect(() => {
    fetchTop100Holders();
  }, [fetchTop100Holders]);

  return {
    topHolders,
    isTopHoldersLoading,
    fetchTop100Holders,
  };
};

export { useFetchTopHolders };
export type { Drc20Holder };
