import { DogeUtxo } from "@/types/transaction.ts";

export type Inscription = {
  length: number;
  utxo: DogeUtxo;
  content: any;
  content_length: number;
  content_type: string;
  genesis_height: number;
  inscription_id: string;
  inscription_number: number;
  timestamp: number;
  offset: number;
};

export type InscriptionWithPrice = Inscription & {
  priceInShibes: number;
};

export type CollectibleTradingData = {
  symbol: string;
  twentyFourHourChangePercent: number;
  weeklySales: number;
  weeklyChangePercent: number;
  floorPrice: number;
  volume: {
    doge: number;
    usd: number;
  };
};

// export type CollectibleCollectionData = {
//   collectionSymbol: string;
//   lastPrice: number;
//   volume: number;
//   twentyFourHourSales: number;
//   sales: number; // total sales
//   holders: number;
//   maxSupply: string;
//   currentSupply: string;
//   twentyFourHourVolume: number;
//   twentyFourHourChangePercent: number;
//   weeklyChangePercent: number;
//   monthlyChangePercent: number;
//   floorPrice: number;
// };

interface PriceData {
  floorPrice: {
    doge: number;
    usd: number;
  };
  twentyFourHourChangePercent: number;
  weeklyChangePercent: number;
  sales: number;
  volume: {
    doge: number;
    usd: number;
  };
}

export type Doginal = {
  inscriptionId: string;
  inscriptionNumber: number;
  name: string;
  price?: number;
  imageURI: string;
  owner?: string;
  collectionSymbol?: string;
  listed: boolean;
  timestamp?: string;
  metadata?: Record<string, any>;
};

export type CollectibleOverviewDetail = {
  amount: number;
  receivingPendingAmount: number;
  sendingPendingAmount: number;
  floorPrice: number;

  valueDollar: number;
  valueDoge: number;
  changeDollar: number;
  changeDoge: number;
};

export type DoginalInfo = CollectibleOverviewDetail & {
  collectionName: string;
  collectionSymbol: string;
  description: string;
  collectionImageURI: string;
  totalSupply: number;
  priceData?: PriceData;
  doginals?: Doginal[];
};

export enum CollectiblePendingActionType {
  // "list" does not exist, because we don't need to wait when listing collectibles
  Delist = "delist",
  Send = "send",
}

export type PendingCollectibleInscription = {
  inscriptionId: string;
  action: CollectiblePendingActionType;
  timestamp?: number;
};
