import { AnimatePresence } from "framer-motion";
import { useCallback, useEffect, useMemo, useState } from "react";

import { CollectionListingProps, Currency } from "@/types";

import {
  DEFAULT_ERROR,
  ErrorView,
  ModalNavigation,
  Sheet,
  SheetProps,
  SuccessView,
} from "../base";
import { TransactionStep } from "./constants";
import { Listing, ListView } from "./views";
import {
  NumberFormatType,
  dogeToSatoshi,
  formatNumber,
} from "@/lib/numbers.ts";
import { calculateFloorDifference, getFormattedValue } from "@/utility";
import {
  useBalance,
  useCurrency,
  useDogePrice,
  useTxWallet,
} from "@/contextHooks";
import { MATCHING_FEE, ONE_DOGE_IN_SHIBES } from "@/constants";
import { fromDogeToUSD } from "@/context/helpers/fetchDogePrice";
import { PendingCollectibleInscription } from "@/types";

interface ModalListCollectibleProps extends SheetProps {
  listings: CollectionListingProps[];
  onSuccess?: (actionedInscriptionIds: PendingCollectibleInscription[]) => void;
}

type ListingWithStringPrice = Omit<CollectionListingProps, "price"> & {
  price: string;
};

export const ModalListCollectible: React.FC<ModalListCollectibleProps> = ({
  onClose,
  onSuccess,
  ...props
}) => {
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();
  const { listCollectibles } = useTxWallet();
  const { collectiblesBalance } = useBalance();

  const { listings: preselectedListings } = props;
  const formattedPreselectedListings = preselectedListings.map((listing) => ({
    ...listing,
    price: listing.price.toString(),
  }));

  /**
   * Local State
   */

  const [error, setError] = useState(DEFAULT_ERROR);
  const [transactionStep, setTransactionStep] = useState(TransactionStep.LIST);
  const [listings, setListings] = useState<ListingWithStringPrice[]>(
    formattedPreselectedListings,
  );

  const inscriptionsForListing = useMemo(
    () =>
      collectiblesBalance
        .filter((inscriptionUtxo) =>
          listings.some(
            (listing) =>
              listing.inscriptionId === inscriptionUtxo.inscription_id,
          ),
        )
        .map((inscriptionUtxo) => {
          const listing = listings.find(
            (listing) =>
              listing.inscriptionId === inscriptionUtxo.inscription_id,
          );
          return {
            ...inscriptionUtxo,
            priceInShibes: listing
              ? Number(listing.price) * ONE_DOGE_IN_SHIBES
              : 0, // Add priceInShibes from listing
          };
        }),
    [collectiblesBalance, listings],
  );

  const totalPriceInShibes = useMemo(
    () =>
      inscriptionsForListing.reduce(
        (acc, inscription) => acc + inscription.priceInShibes,
        0,
      ),
    [inscriptionsForListing],
  );

  const tradingFee = useMemo(() => {
    const feeInShibes = (MATCHING_FEE / 100) * totalPriceInShibes;
    const feeInDoge = formatNumber({
      value: feeInShibes / ONE_DOGE_IN_SHIBES,
      type: NumberFormatType.Price,
    });

    return {
      feeInShibes,
      feeInDoge,
    };
  }, [totalPriceInShibes]);

  const potentialProceeds = useMemo(() => {
    const potentialProceedsInDoge = formatNumber({
      value: totalPriceInShibes / ONE_DOGE_IN_SHIBES,
      type: NumberFormatType.Price,
    });

    const potentialProceedsInUSD = fromDogeToUSD(
      totalPriceInShibes / ONE_DOGE_IN_SHIBES,
      dogePrice,
    );

    return {
      potentialProceedsInDoge,
      potentialProceedsInUSD,
    };
  }, [dogePrice, totalPriceInShibes]);

  const isBelowThreshold = useMemo(
    () => totalPriceInShibes <= 2 * ONE_DOGE_IN_SHIBES,
    [totalPriceInShibes],
  );

  const hasItemWithoutPrice = useMemo(
    () =>
      listings.some((listing) => listing.price === "0" || listing.price === ""),
    [listings],
  );

  // sets the price of the CollectionListingProps[inscriptionId] to price input and is then updating
  // preparedListings which is then used in the <ListView>
  const onPriceChange = useCallback(
    (inscriptionId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const newPrice = inputValue.replace(/[^0-9.]/g, "");

      // Validate against non-numeric characters but allow empty string and dots for decimal input
      const validInput =
        newPrice === "" || /^0$|^[1-9]\d*(\.\d*)?$|^0\.\d*$/.test(newPrice);

      // debounce((inscriptionId: string, newPrice: string) => {
      setListings((prevListings) =>
        prevListings.map((listing) =>
          listing.inscriptionId === inscriptionId
            ? { ...listing, price: validInput ? newPrice : "0" }
            : listing,
        ),
      );
      // }, 200)(inscriptionId, newPrice);
    },
    [],
  );

  // sets the price of the CollectionListingProps[inscriptionId] to floorPrice and is then updating
  // preparedListings which is then used in the <ListView>
  const onPriceFloor = useCallback(
    (floorPrice: number, inscriptionId: string) => {
      setListings((prevListings) =>
        prevListings.map((listing) =>
          listing.inscriptionId === inscriptionId
            ? {
                ...listing,
                price: (floorPrice / ONE_DOGE_IN_SHIBES).toString(),
              }
            : listing,
        ),
      );
    },
    [],
  );

  const onRemove = useCallback((inscriptionId: string) => {
    setListings((prevListings) =>
      prevListings.filter((listing) => listing.inscriptionId !== inscriptionId),
    );
  }, []);

  const onFloorAll = useCallback(() => {
    setListings((prevListings) =>
      prevListings.map((listing) => ({
        ...listing,
        price: listing.floorPrice
          ? (listing.floorPrice / ONE_DOGE_IN_SHIBES).toString()
          : "0",
      })),
    );
  }, []);

  const preparedListings = useMemo(() => {
    return listings.map((listing) => {
      const {
        imageURI,
        name,
        floorDifferencePercentage,
        price,
        inscriptionId,
        floorPrice,
      } = listing;

      // const { value: totalPriceForCurrency } = getFormattedValue(
      //   price,
      //   dogePrice,
      //   currency,
      //   true,
      // );

      const { valueInDoge: floorPriceForCurrency } = getFormattedValue(
        floorPrice || 0,
        dogePrice,
        currency,
        true,
      );

      const priceInDoge = dogeToSatoshi(parseFloat(price));

      const floorPriceDeltaPercentage = calculateFloorDifference(
        priceInDoge || 0,
        floorPrice || 0,
      );

      return {
        ...listing,
        image: imageURI,
        itemName: name,
        price: price.toString(),
        floorPrice: floorPriceForCurrency,
        floorPriceDeltaPercentage: formatNumber({
          value:
            floorPriceDeltaPercentage == -1 ? 0 : floorPriceDeltaPercentage,
          decimalPlaces: 2,
        }),
        onRemove: () => onRemove(inscriptionId),
        onPriceChange: onPriceChange(inscriptionId),
        onPriceFloor: () => onPriceFloor(floorPrice || 0, inscriptionId),
      } as Listing;
    });
  }, [currency, dogePrice, listings, onPriceChange, onPriceFloor, onRemove]);

  /**
   * Actions
   */

  const handleList = useCallback(async () => {
    if (!inscriptionsForListing.length || !listCollectibles) return;

    await listCollectibles.execute({
      inscriptions: inscriptionsForListing,
    });
  }, [inscriptionsForListing, listCollectibles]);

  const handleListError = useCallback((title: string, description: string) => {
    setError({ title, description });
    setTransactionStep(TransactionStep.ERROR);
  }, []);

  const handleClose = useCallback(() => {
    if (listCollectibles.isLoading) return;

    // reset values
    setError(DEFAULT_ERROR);
    setTransactionStep(TransactionStep.LIST);
    listCollectibles?.reset();

    onClose?.();
  }, [listCollectibles, onClose]);

  useEffect(() => {
    if (
      listCollectibles.isError &&
      listCollectibles.error &&
      !listCollectibles.isLoading
    ) {
      handleListError(
        "Error",
        (listCollectibles.error as string) ||
          "An error occurred while processing your request.",
      );
    }
  }, [handleListError, listCollectibles]);

  useEffect(() => {
    if (listCollectibles.isSuccess && !listCollectibles.isLoading) {
      setTransactionStep(TransactionStep.SUCCESS);
      setTimeout(() => onSuccess?.([]), 400);
    }
  }, [listCollectibles.isSuccess, listCollectibles.isLoading, onSuccess]);

  return (
    <Sheet
      withHeader={false}
      classNameContent="flex flex-col text-text-primary h-full rounded-none pt-safe-offset-4 pb-safe-offset-4"
      onClose={handleClose}
      {...props}
    >
      <ModalNavigation title="List" iconBack="close" onBack={handleClose} />
      <AnimatePresence>
        <div className="relative flex flex-1 flex-col">
          {transactionStep === TransactionStep.LIST && (
            <ListView
              fees={{
                trading: `${Currency.DOGE}${tradingFee.feeInDoge}`,
                service: `${Currency.DOGE}0.00`,
                total: `${Currency.DOGE}${tradingFee.feeInDoge}`,
              }}
              proceeds={{
                potentialProceedsInUSD: `${Currency.USD}${potentialProceeds.potentialProceedsInUSD}`,
                potentialProceedsInDoge: `${Currency.DOGE}${potentialProceeds.potentialProceedsInDoge}`,
              }}
              loading={false}
              handleList={handleList}
              onFloorAll={onFloorAll}
              listings={preparedListings}
              isBelowThreshold={isBelowThreshold}
              hasItemWithoutPrice={hasItemWithoutPrice}
            />
          )}
          {transactionStep === TransactionStep.SUCCESS && (
            <SuccessView handleContinue={handleClose} />
          )}
          {transactionStep === TransactionStep.ERROR && (
            <ErrorView
              title={error.title}
              description={error.description}
              handleContinue={handleClose}
            />
          )}
        </div>
      </AnimatePresence>
    </Sheet>
  );
};
