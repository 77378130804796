import { AnimatePresence } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { MATCHING_FEE, ONE_DOGE_IN_SHIBES } from "@/constants";
import { FeeType } from "@/context/wallet/calculateNetworkFees.ts";
import { useCurrency, useDogePrice, useTxWallet } from "@/contextHooks";
import { formatNumber, NumberFormatType } from "@/lib/numbers";
import { Currency, InscriptionType, TokenListingProps } from "@/types";
import { CalculateFeesReturn, getFormattedValue } from "@/utility";

import {
  DEFAULT_ERROR,
  ErrorView,
  ModalNavigation,
  Sheet,
  SheetProps,
  SuccessView,
} from "../base";
import { BuyTransactionStep } from "./constants";
import { BuyView } from "./views";

interface ModalBuyDuneProps extends SheetProps {
  offer?: TokenListingProps;
  offers?: TokenListingProps[];
  refetchListings: (resetListing?: boolean) => Promise<void>;
  removeFromListings: (offerId: string) => void;
}

const processOffers = ({
  offers,
  dogePrice,
  currency,
}: {
  offers?: TokenListingProps[];
  dogePrice: number;
  currency: Currency;
}) => {
  if (offers === undefined || offers?.length === 0) {
    return {
      pay: { value: "N/A", valueInUSD: "N/A" },
      receive: { value: "N/A", valueInUSD: "N/A" },
      tick: undefined,
    };
  }

  let totalValuePay = 0;
  let totalValueReceive = 0;
  let tick = offers[0].tick;

  // Aggregate total values from the offers
  for (const { totalPrice, amount, tick: offerTick } of offers) {
    totalValuePay += totalPrice;
    totalValueReceive += amount;
    tick = offerTick; // Assumes all offers have the same tick value
  }

  // Get the formatted total price in both DOGE and USD
  const { valueInDoge, valueInUSD } = getFormattedValue(
    totalValuePay,
    dogePrice,
    currency,
  );

  // Extract numeric values from formatted strings
  const totalPriceValueForCurrency =
    currency === Currency.DOGE
      ? parseFloat(valueInDoge.replace(/[^0-9,.]/g, "").replace(",", ""))
      : parseFloat(valueInUSD.replace(/[^0-9,.]/g, "").replace(",", ""));

  // Calculate and format the average unit price
  let formattedUnitPrice = "N/A";
  if (totalValueReceive > 0) {
    const averageUnitPrice = totalPriceValueForCurrency / totalValueReceive;
    if (!isNaN(averageUnitPrice) && isFinite(averageUnitPrice)) {
      formattedUnitPrice = formatNumber({
        value: averageUnitPrice,
        type: NumberFormatType.Price,
      });
    }
  }

  return {
    pay: {
      value: valueInDoge,
      valueInUSD,
    },
    receive: {
      value: `${formatNumber({ value: totalValueReceive, type: NumberFormatType.Large_Number })} ${tick.toUpperCase()}`,
      valueInUSD: undefined,
      valueDetails: `at ${currency}${formattedUnitPrice}/${tick.toUpperCase()}`,
    },
    tick,
  };
};

const calculateFees = ({
  offers,
  txFeeInSats,
}: {
  offers?: TokenListingProps[];
  txFeeInSats: number;
}): CalculateFeesReturn => {
  if (!offers?.length) return { network: "N/A", taker: "N/A" };

  let totalFeeInShibes = 0;

  offers.forEach((offer) => {
    const { totalPrice, amount } = offer;

    // Assuming unitPrice can be derived from totalPrice / amount
    const unitPrice = totalPrice / amount;
    const totalPriceInShibes = amount * unitPrice;
    const feeInShibes = (MATCHING_FEE / 100) * totalPriceInShibes;

    totalFeeInShibes += feeInShibes;
  });

  const takerFeeInDoge = totalFeeInShibes / ONE_DOGE_IN_SHIBES;
  const networkFeeInDoge = txFeeInSats / ONE_DOGE_IN_SHIBES;

  return {
    network: `${Currency.DOGE} ${formatNumber({ value: networkFeeInDoge, type: NumberFormatType.Price })}`,
    taker: `${Currency.DOGE} ${formatNumber({ value: takerFeeInDoge, type: NumberFormatType.Price })}`,
  };
};

export const ModalBuyDune: React.FC<ModalBuyDuneProps> = ({
  onClose,
  offers,
  isVisible,
  refetchListings,
  removeFromListings,
  ...props
}) => {
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();
  const { buyItem, calculateNetworkFees } = useTxWallet();

  // Local State
  const [localIsVisible, setLocalIsVisible] = useState<boolean>(isVisible);
  const [txFeeInSats, setTxFeeInSats] = useState(0);
  const [error, setError] = useState(DEFAULT_ERROR);
  const [transactionStep, setTransactionStep] = useState(
    BuyTransactionStep.BUY,
  );

  useEffect(() => {
    setLocalIsVisible(isVisible);
  }, [isVisible]);

  // Handle buy error
  const handleBuyError = useCallback((title: string, description: string) => {
    setError({ title, description });
    setTransactionStep(BuyTransactionStep.ERROR);
    setLocalIsVisible(true);
  }, []);

  // Build the transaction and set the buyerTx and sellerPsdtHex
  useEffect(() => {
    if (
      !offers?.length ||
      calculateNetworkFees.isLoading ||
      calculateNetworkFees.isError ||
      calculateNetworkFees.isSuccess
    )
      return;

    const offerIds = offers.map((offer) => offer.offerId);
    calculateNetworkFees.execute(FeeType.BUYDUNES, {
      offerIds: offerIds,
    });
  }, [offers]);

  // Close the modal
  const handleClose = useCallback(() => {
    if (buyItem.isLoading) return;

    // reset values
    setError(DEFAULT_ERROR);
    setTransactionStep(BuyTransactionStep.BUY);
    buyItem.reset();

    setLocalIsVisible(false);
    onClose?.();
  }, [buyItem, onClose]);

  useEffect(() => {
    if (calculateNetworkFees.isError && calculateNetworkFees.error) {
      // @todo: this needs to go into a custom hook for error handling calculateNetworkFees

      //ensure the selected listings are updated after an error
      if (calculateNetworkFees.error instanceof Map) {
        for (const offerId of calculateNetworkFees.error.keys()) {
          removeFromListings(offerId);
        }
      }

      handleBuyError(
        "Error",
        calculateNetworkFees.error instanceof Map
          ? Array.from(calculateNetworkFees.error.entries())
              .map(([key, value]) => `${key}: ${value}`)
              .join(", ")
          : (calculateNetworkFees.error as string) ||
              "An error occurred while calculating your fees.",
      );
    }
  }, [
    calculateNetworkFees.error,
    calculateNetworkFees.isError,
    handleBuyError,
    removeFromListings,
  ]);

  useEffect(() => {
    if (calculateNetworkFees.isSuccess && calculateNetworkFees.totalFeeInSats) {
      setTxFeeInSats(calculateNetworkFees.totalFeeInSats);
    }
  }, [calculateNetworkFees.isSuccess, calculateNetworkFees.totalFeeInSats]);

  // Handle buy success
  const handleBuySuccess = useCallback(() => {
    setTransactionStep(BuyTransactionStep.SUCCESS);
    // Ensure the list is updated after a successful buy
    setTimeout(() => refetchListings(true), 2000);
  }, [refetchListings]);

  // Handle buy transaction
  const handleBuy = useCallback(async () => {
    if (offers?.length) {
      const offerIds = offers.map((offer) => offer.offerId);
      await buyItem.execute(offerIds, InscriptionType.DUNE);
    }
  }, [offers, buyItem]);

  // On success, show the success view
  useEffect(() => {
    if (buyItem.isSuccess) {
      handleBuySuccess();
    }
  }, [handleBuySuccess, buyItem.isSuccess]);

  // On error, show the error view
  useEffect(() => {
    if ((buyItem.isError && buyItem.error) || buyItem.errors.length > 0) {
      let message = "An error occurred while processing your request.";
      if (buyItem.errors.length > 0) {
        message = `${message}, ${buyItem.errors.join(",")}`;
      }

      if (buyItem.error) {
        message = `${message}, ${buyItem.error as string}`;
      }

      handleBuyError("Error", message);
    }
  }, [handleBuyError, buyItem.isError, buyItem.error, buyItem.errors]);

  // Displayed fees
  const fees = useMemo(() => {
    return calculateFees({ offers, txFeeInSats });
  }, [offers, txFeeInSats]);

  // Displayed values
  const { pay, receive, tick } = useMemo(() => {
    const { pay, receive, tick } = processOffers({
      offers,
      dogePrice,
      currency,
    });

    return {
      pay: { value: pay.value, valueInUSD: pay.valueInUSD },
      receive: {
        value: receive.value,
        valueInUSD: receive.valueInUSD,
        valueDetails: receive.valueDetails,
      },
      tick,
    };
  }, [offers, dogePrice, currency]);

  return (
    <Sheet
      withHeader={false}
      isVisible={localIsVisible}
      classNameContent="flex flex-col text-text-primary h-full md:min-h-2/3 md:max-h-5/6 md:aspect-auto rounded-none pt-safe-offset-4 pb-safe-offset-4"
      onClose={handleClose}
      {...props}
    >
      <ModalNavigation
        title="Review Order"
        iconBack="close"
        onBack={handleClose}
      />
      <AnimatePresence>
        <div className="relative flex flex-1 flex-col">
          {transactionStep === BuyTransactionStep.BUY &&
            fees.network &&
            fees.taker && (
              <BuyView
                pay={pay}
                tick={tick}
                receive={receive}
                handleBuy={handleBuy}
                loading={
                  (buyItem.isLoading as boolean) ||
                  (calculateNetworkFees.isLoading as boolean)
                }
                fees={{ network: fees.network, taker: fees.taker }}
              />
            )}
          {transactionStep === BuyTransactionStep.SUCCESS && (
            <SuccessView handleContinue={handleClose} />
          )}
          {transactionStep === BuyTransactionStep.ERROR && (
            <ErrorView
              handleContinue={handleClose}
              title={error.title}
              description={error.description}
            />
          )}
        </div>
      </AnimatePresence>
    </Sheet>
  );
};
