import coinstring from "coinstring";

import { handleError } from "./handleError";

export const isValidDogecoinAddress = (address: string): boolean => {
  try {
    const version = 0x1e; //Dogecoin Address
    const addressValidator = coinstring.createValidator(version);
    return addressValidator(address);
  } catch (e: Error | unknown) {
    handleError(e);
    return false;
  }
};
