import dogecore from "bitcore-lib-doge";
import { useCallback, useState } from "react";

import { AVERAGE_NETWORK_FEE_RATE } from "@/constants.ts";
import { getAccessToken } from "@/context/wallet/helpers/listItems.ts";
import { fundWallet } from "@/context/wallet/lib/transaction.ts";
import {
  BuildCollectiblesTxsResult,
  BuildCollectibleTxError,
  SignTxResult,
  TxWallet,
  UnlistCollectibleOffersResult,
  WalletContextHandleReturn,
} from "@/context/wallet/types.ts";
import { marketplaceApiV2 } from "@/lib/fetch.ts";
import { handleError } from "@/utility";
import { useBalance } from "@/contextHooks";
import { InscriptionUtxo } from "@/types";
import { getCollectibleUtxosForInscriptionIds } from "@/context/wallet/helpers/delistItems.ts";

const { Transaction } = dogecore;

export type UseDelistCollectiblesParams = {
  txWallet: TxWallet | undefined;
};

export interface DelistCollectiblesReturn extends WalletContextHandleReturn {
  execute: (inscriptionIds: string[], offerIds: string[]) => Promise<void>;
  preparationErrors: null | string[] | object[];
  data: any;
}

export type PreparationError = {
  txId: string;
  txOutput: number;
  inscriptionId: string | undefined;
  error: Error | never;
};

export const useDelistCollectibles = (
  useDelistParams: UseDelistCollectiblesParams,
): DelistCollectiblesReturn => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsErrorCollectiblesDelist] = useState<boolean>(false);
  const [isSuccess, setIsSuccessCollectiblesDelist] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [preparationErrors, setPreparationErrors] = useState<
    null | BuildCollectibleTxError[]
  >(null);
  const [delistData, setDelistData] =
    useState<UnlistCollectibleOffersResult | null>(null);

  const { collectiblesBalance } = useBalance();
  const { txWallet } = useDelistParams;

  // @todo: Refactor so buildSendCollectiblesTxs is used
  const buildDelistCollectiblesTxs = useCallback(
    async (
      inscriptionUtxos: InscriptionUtxo[],
    ): Promise<BuildCollectiblesTxsResult | undefined> => {
      if (!txWallet) {
        throw new Error("No wallet found");
      }

      const errors: BuildCollectibleTxError[] = [];
      const successfullySignedCancelTxs: SignTxResult[] = [];

      inscriptionUtxos.map((inscriptionUtxo) => {
        try {
          const tx = new Transaction();

          const utxoFormattedForTx = {
            txid: inscriptionUtxo.txid,
            vout: inscriptionUtxo.vout,
            satoshis: inscriptionUtxo.shibes,
            script: inscriptionUtxo.script,
          };
          tx.from(utxoFormattedForTx);
          tx.to(txWallet.address, inscriptionUtxo.shibes);

          // Fund & sign
          const { tx: fundedTx } = fundWallet(
            txWallet,
            tx,
            AVERAGE_NETWORK_FEE_RATE,
            false,
          );
          txWallet.updateUtxos({ tx: fundedTx });

          successfullySignedCancelTxs.push({
            tx: fundedTx.toString("hex"),
            inscriptionId: inscriptionUtxo.inscription_id as string,
          });
        } catch (e: Error | unknown) {
          handleError(e);

          console.error(
            `Error creating cancelTx for utxo ${inscriptionUtxo}:`,
            e,
          );

          errors.push({
            inscriptionUtxo,
            error: e as Error,
          });
          return null;
        }
      });

      return {
        signedTxs: successfullySignedCancelTxs as SignTxResult[],
        errors,
      };
    },
    [txWallet],
  );

  const performDelistCollectibless = useCallback(
    async (signedCancelTxs: SignTxResult[], offerIds: string[]) => {
      if (!txWallet) {
        console.log("delistCollectibles - no wallet found");
        return;
      }

      try {
        setIsLoading(true);
        setIsErrorCollectiblesDelist(false);
        setIsSuccessCollectiblesDelist(false);

        // 1. get token
        const token = await getAccessToken(txWallet.address, txWallet);

        // 2. delist put request to BE
        const response = await marketplaceApiV2(
          true,
        ).put<UnlistCollectibleOffersResult>(
          `offer/doginals/unlist`,
          {
            offerIds,
            signedCancelTxs,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setIsSuccessCollectiblesDelist(true);
        setDelistData(response.data);
      } catch (e: Error | unknown) {
        setIsErrorCollectiblesDelist(true);
        const message = handleError(e);
        setError(message);
      } finally {
        setIsLoading(false);
      }
    },
    [txWallet],
  );

  const delistCollectibless = useCallback(
    async (inscriptionIds: string[], offerIds: string[]) => {
      try {
        if (!collectiblesBalance) {
          throw new Error("No collectiblesBalance found");
        }

        const utxosForInscriptionIds = getCollectibleUtxosForInscriptionIds(
          inscriptionIds,
          collectiblesBalance,
        );
        if (!utxosForInscriptionIds) {
          throw new Error(
            `No utxos containing ${inscriptionIds} were found in users wallet`,
          );
        }

        const buildResult = await buildDelistCollectiblesTxs(
          utxosForInscriptionIds,
        );
        if (!buildResult) {
          throw new Error("Failed to build delist transactions");
        }

        const { signedTxs, errors } = buildResult;

        if (signedTxs.length > 0) {
          await performDelistCollectibless(signedTxs, offerIds);
        }

        if (errors.length > 0) {
          console.error(
            "Errors occurred while building delist transactions",
            errors,
          );
          setPreparationErrors(errors);
        }
      } catch (e: Error | unknown) {
        setIsErrorCollectiblesDelist(true);
        const message = handleError(e);
        setError(message);
      }
    },
    [
      collectiblesBalance,
      buildDelistCollectiblesTxs,
      performDelistCollectibless,
    ],
  );

  const reset = useCallback(() => {
    setIsLoading(false);
    setIsErrorCollectiblesDelist(false);
    setIsSuccessCollectiblesDelist(false);
    setPreparationErrors(null);
    setError(null);
    setDelistData(null);
  }, []);

  return {
    isLoading,
    isError,
    isSuccess,
    execute: delistCollectibless,
    data: delistData,
    error,
    preparationErrors,
    reset,
  };
};
