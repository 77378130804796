import React, { useCallback, useMemo, useState } from "react";

import {
  Appear,
  Button,
  ButtonBar,
  TableActivity,
  TableContentActivityDRC20,
} from "@/components";
import { PAGINATION_LIMIT } from "@/constants";
import { useCurrency, useDogePrice } from "@/contextHooks";
import { useFetchDrc20Activities } from "@/hooks";
import {
  ModalActivityFilter,
  ModalActivitySearch,
  ModalActivitySort,
} from "@/modals";
import {
  ActivityFilters,
  ActivitySort,
  ActivitySortParam,
  ActivityType,
  SortOrder,
  TokenActivity,
} from "@/types";
import { Currency } from "@/types";
import { calculateFloorDifference, getFormattedValue } from "@/utility";

import { ModalType } from "../types";

const FILTER_DEFAULT = {
  offset: 0,
  limit: 20,
  type: undefined,
};

const SORT_DEFAULT = {
  sortParam: ActivitySortParam.Recent,
  sortOrder: SortOrder.Descending,
};

interface TableTokenActivityProps {
  tick: string | undefined;
}

export const TabTokenActivity: React.FC<TableTokenActivityProps> = ({
  tick,
}) => {
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();

  /**
   * Local State
   */
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [modalType, setModalType] = useState<ModalType | undefined>();

  const [sort, setSort] = useState<ActivitySort>(SORT_DEFAULT);

  const [filters, setFilters] = useState<ActivityFilters>({
    tick,
    ...FILTER_DEFAULT,
  });

  const resetFilters = useCallback((forTick: string | undefined) => {
    // Reset filters, offset, sort, search
    setOffset(0);
    setSearch("");
    setSort(SORT_DEFAULT);
    forTick && setFilters({ tick: forTick, ...FILTER_DEFAULT });
  }, []);

  const { hasActiveFilters, hasActiveSorting, hasActiveSearch } =
    useMemo(() => {
      const { address, ...otherFilters } = filters;

      return {
        hasActiveFilters: !Object.entries(otherFilters).every(
          // @ts-expect-error internal type is wrong
          ([key, value]) => FILTER_DEFAULT[key] === value,
        ),
        hasActiveSorting: !Object.entries(sort).every(
          // @ts-expect-error internal type is wrong
          ([key, value]) => SORT_DEFAULT[key] === value,
        ),
        hasActiveSearch: !!address,
      };
    }, [filters, sort]);

  /**
   * DRC20 Activities
   */

  const { drc20Activities, isDrc20ActivitiesLoading, getDrc20Activities } =
    useFetchDrc20Activities({
      tick,
      offset,
      limit: PAGINATION_LIMIT,
      action: filters.type,
      address: filters.address,
      sortParam: sort.sortParam,
      sortOrder: sort.sortOrder,
    });

  /**
   * Button Bar: Modals
   */

  const onOpenModalFilter = useCallback(() => {
    setModalType(ModalType.Filter);
  }, []);

  const onOpenModalSort = useCallback(() => {
    setModalType(ModalType.Sort);
  }, []);

  const onOpenModalSearch = useCallback(() => {
    setModalType(ModalType.Search);
  }, []);

  const onCloseModal = useCallback(() => {
    setModalType(undefined);
  }, []);

  /**
   * Button Bar: Other Actions
   */

  const onRefresh = useCallback(async () => {
    resetFilters(tick);
    await getDrc20Activities();
  }, [tick, resetFilters, getDrc20Activities]);

  /**
   * Apply Filters/Sort/Search
   */

  const onApplyFilter = useCallback(
    (type?: ActivityType) => {
      setFilters((prev) => ({ ...prev, type }));
      onCloseModal();
    },
    [onCloseModal, setFilters],
  );

  const onApplySort = useCallback(
    (sort: ActivitySort | undefined) => {
      setSort(sort ?? SORT_DEFAULT);
      onCloseModal();
    },
    [onCloseModal],
  );

  const onApplySearch = useCallback(
    (search: string) => {
      setFilters((prev) => ({
        ...prev,
        address: search?.length ? search : undefined,
      }));
      onCloseModal();
    },
    [onCloseModal],
  );

  /**
   * Data Transformations
   */

  const dataTokenActivity = useMemo<TokenActivity[]>(() => {
    if (!drc20Activities) return [];

    return drc20Activities.map((activity) => {
      const {
        tick,
        inscriptionNumber,
        type,
        price,
        totalPrice,
        amount,
        from,
        to,
        createdAt,
        inscriptionId,
        floorPrice,
      } = activity;

      const { valueInDoge: totalPriceDoge, valueInUSD: totalPriceUSD } =
        getFormattedValue(totalPrice, dogePrice, currency);
      const { valueInDoge: priceDoge, valueInUSD: priceUSD } =
        getFormattedValue(price, dogePrice, currency);

      const calculatedFloorDiff = calculateFloorDifference(price, floorPrice);

      return {
        tick,
        inscriptionNumber,
        type: type as ActivityType,
        price,
        priceForCurrency: currency === Currency.DOGE ? priceDoge : priceUSD,
        priceDoge,
        priceUSD,
        totalPrice,
        totalPriceForCurrency:
          currency === Currency.DOGE ? totalPriceDoge : totalPriceUSD,
        totalPriceDoge,
        totalPriceUSD,
        amount,
        from,
        to,
        createdAt,
        inscriptionId,
        floorDifference: calculatedFloorDiff, // Percentage
        floorPrice, // Shibes
      };
    });
  }, [currency, dogePrice, drc20Activities]);

  const buttonBarConfig = useMemo(() => {
    return [
      {
        icon: "filter_list",
        onClick: onOpenModalFilter,
        isActive: hasActiveFilters,
      },

      {
        icon: "swap_vert",
        onClick: onOpenModalSort,
        isActive: hasActiveSorting,
      },
      { icon: "search", onClick: onOpenModalSearch, isActive: hasActiveSearch },
      { icon: "refresh", onClick: onRefresh },
    ];
  }, [onOpenModalFilter, onOpenModalSearch, onOpenModalSort, onRefresh]);

  /**
   * Table Pagination
   */

  // For Infinite Scroll
  // TODO: @artdoge - Enable infinite scroll in useFetchDrc20Listings
  // const bottomTableRef = useInfiniteScroll({
  //   isLoadingData: isDrc20ActivitiesLoading,
  //   hasMoreData: hasMoreTableData,
  //   isLoadingMoreData: isLoadingMoreTableData,
  //   setOffset: debouncedSetTableOffset,
  //   offset: tableOffset,
  //   paginationLimit: PAGINATION_LIMIT,
  // });

  // TODO: @artdoge - Remove the pagination buttons and enable infinite scroll
  const onPaginateNext = useCallback(() => {
    setOffset((prev) => prev + PAGINATION_LIMIT);
  }, []);

  const onPaginatePrevious = useCallback(() => {
    setOffset((prev) => (prev === 0 ? 0 : prev - PAGINATION_LIMIT));
  }, []);

  const { isPaginationPreviousDisabled, isPaginationNextDisabled } = useMemo(
    () => ({
      isPaginationPreviousDisabled: offset === 0,
      isPaginationNextDisabled: drc20Activities.length < PAGINATION_LIMIT,
    }),
    [drc20Activities.length, offset],
  );

  return (
    <>
      <div className="flex flex-1 flex-col">
        {/** Actions */}
        <div className="w-full px-4 md:px-0">
          <ButtonBar buttons={buttonBarConfig} />
        </div>

        {/** Activity */}
        <div className="mt-4 pb-16 md:mt-8">
          <TableActivity
            data={dataTokenActivity}
            columns={TableContentActivityDRC20}
            loading={isDrc20ActivitiesLoading}
          />
          {/* <div ref={bottomTableRef} /> */}
        </div>
      </div>

      {/** Buttons: @artdoge Remove after enabling infinite scrolling */}
      <Appear isVisible from="bottom">
        <div className="flex w-full flex-row items-center justify-end space-x-4 px-4 py-2">
          <Button
            onClick={onPaginatePrevious}
            disabled={isPaginationPreviousDisabled}
            size="icon"
            shape="circle"
            variant="inverse"
            className="h-8 w-8 drop-shadow-md"
          >
            <span className="material-symbols-rounded text-xl">arrow_left</span>
          </Button>
          <Button
            onClick={onPaginateNext}
            disabled={isPaginationNextDisabled}
            size="icon"
            shape="circle"
            variant="inverse"
            className="h-8 w-8 drop-shadow-md"
          >
            <span className="material-symbols-rounded text-xl">
              arrow_right
            </span>
          </Button>
        </div>
      </Appear>

      {/** Modals */}

      <ModalActivitySearch
        title="Search"
        search={search}
        onClose={onCloseModal}
        onApply={onApplySearch}
        isVisible={modalType === ModalType.Search}
      />

      <ModalActivityFilter
        title="Filter"
        type={filters.type}
        onClose={onCloseModal}
        onApply={({ type }) => onApplyFilter(type)}
        isVisible={modalType === ModalType.Filter}
      />

      <ModalActivitySort
        title="Sort"
        sort={sort}
        onClose={onCloseModal}
        onApply={onApplySort}
        isVisible={modalType === ModalType.Sort}
      />
    </>
  );
};
