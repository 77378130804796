import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  ActivityCardDune,
  Skeleton,
  TableContentDunes,
  TableExplore,
} from "@/components";
import { PAGINATION_LIMIT } from "@/constants";
import {
  useCurrency,
  useDogePrice,
  useDunesTokenList,
  useDunesWatchlist,
} from "@/contextHooks";
import { useInfiniteScroll } from "@/hooks";
import { Tab } from "@/types";

import { EmptyTopMover, EmptyWatchlist, TopMoverGradient } from "../components";

interface TradingDunesProps {
  selectedTab: string;
}

export const TradingDunes: React.FC<TradingDunesProps> = ({ selectedTab }) => {
  const navigate = useNavigate();
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();

  /**
   * Tab Checks
   */

  const { isTabTop, isTabTrending, isTabRecent, isTabWatchlist } =
    useMemo(() => {
      return {
        isTabTop: selectedTab === Tab.Top,
        isTabTrending: selectedTab === Tab.Trending,
        isTabRecent: selectedTab === Tab.Recent,
        isTabWatchlist: selectedTab === Tab.Watchlist,
      };
    }, [selectedTab]);

  /**
   * Data Fetching & Processing
   */
  const {
    // Table Data
    tableOffset,
    isLoadingTableData,
    isLoadingMoreTableData,
    isMoversLoading,
    tableData,
    debouncedSetTableOffset,
    hasMoreTableData,
    // Movers Data
    topMovers,
    topSales,
    recentSales,
  } = useDunesTokenList();

  const { watchlist, addToWatchlist, removeFromWatchlist, getIsOnWatchlist } =
    useDunesWatchlist();

  // Table Data with Watchlist
  const dunesDataWithWatchlist = useMemo(() => {
    return tableData.map((token) => ({
      ...token,
      currency,
      currentDogePrice: dogePrice,
      watchlist: getIsOnWatchlist(token.id),
      onAddToWatchlist: () => addToWatchlist(token.id),
      onRemoveFromWatchlist: () => removeFromWatchlist(token.id),
    }));
  }, [
    currency,
    dogePrice,
    tableData,
    getIsOnWatchlist,
    addToWatchlist,
    removeFromWatchlist,
  ]);

  // For Infinite Scroll
  const bottomTableRef = useInfiniteScroll({
    isLoadingData: isLoadingTableData,
    hasMoreData: hasMoreTableData,
    isLoadingMoreData: isLoadingMoreTableData,
    setOffset: debouncedSetTableOffset,
    offset: tableOffset,
    paginationLimit: PAGINATION_LIMIT,
  });

  /**
   * Navigation
   */
  // Navigate to Token
  const onNavigateToToken = useCallback(
    (tick: string) => {
      navigate(`/dune/${tick}`);
    },
    [navigate],
  );

  return (
    <>
      <div className="relative">
        <div className="flex flex-row space-x-2 overflow-x-auto px-4 py-2 lg:space-x-4 lg:py-4">
          {isMoversLoading ? (
            <>
              {Array.from({ length: 10 }).map((_, index) => (
                <Skeleton
                  key={index}
                  className="aspect-square w-28 flex-shrink-0 rounded-lg md:w-40"
                />
              ))}
            </>
          ) : (
            <>
              {isTabTop &&
                (topMovers.length > 0 ? (
                  topMovers.map((data) => {
                    const {
                      id: tick,
                      value,
                      percentage,
                      percentageTimeframe,
                    } = data;
                    const cardData = { ...{ tick }, ...data };
                    const navigateToToken = () => onNavigateToToken(tick);
                    return (
                      <ActivityCardDune
                        key={tick}
                        valuePrimary={value}
                        valueSecondary={percentage}
                        valueSecondarySuffix={percentageTimeframe}
                        onClick={navigateToToken}
                        includeIndicatorSymbol
                        {...cardData}
                      />
                    );
                  })
                ) : (
                  <EmptyTopMover />
                ))}

              {isTabTrending &&
                (topSales.length > 0 ? (
                  topSales.map((data) => {
                    const { id: tick, value, sales, timeframe } = data;
                    const cardData = { ...{ tick }, ...data };
                    const navigateToToken = () => onNavigateToToken(tick);
                    return (
                      <ActivityCardDune
                        key={tick}
                        valuePrimary={value}
                        valueSecondary={sales}
                        valueSecondarySuffix={timeframe}
                        onClick={navigateToToken}
                        {...cardData}
                      />
                    );
                  })
                ) : (
                  <EmptyTopMover />
                ))}

              {isTabRecent &&
                (recentSales.length > 0 ? (
                  recentSales.map((data) => {
                    const { id: tick, value, sales, timeframe } = data;
                    const cardData = { ...{ tick }, ...data };
                    const navigateToToken = () => onNavigateToToken(tick);
                    return (
                      <ActivityCardDune
                        key={tick}
                        valuePrimary={value}
                        valueSecondary={sales}
                        valueSecondarySuffix={timeframe}
                        onClick={navigateToToken}
                        {...cardData}
                      />
                    );
                  })
                ) : (
                  <EmptyTopMover />
                ))}

              {isTabWatchlist &&
                (watchlist.length > 0 ? (
                  watchlist.map((data) => {
                    const { id: tick, value, percentage } = data;
                    const cardData = { ...{ tick }, ...data };
                    const navigateToToken = () => onNavigateToToken(tick);
                    return (
                      <ActivityCardDune
                        key={`${tick}-${value}`}
                        valuePrimary={value}
                        valueSecondary={percentage}
                        onClick={navigateToToken}
                        {...cardData}
                      />
                    );
                  })
                ) : (
                  <EmptyWatchlist />
                ))}
            </>
          )}
        </div>
        {/** Don't display the gradient on the <EmptyWatchlist /> */}
        {!(isTabWatchlist && watchlist.length === 0) && <TopMoverGradient />}
      </div>

      <TableExplore
        defaultSortId="volumeThirtyDays"
        columns={TableContentDunes}
        data={dunesDataWithWatchlist}
        loading={isLoadingTableData}
        loadingMore={isLoadingMoreTableData}
      />

      <div ref={bottomTableRef} />
    </>
  );
};
