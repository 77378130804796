import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  AppScreen,
  Button,
  DetailCard,
  ImageWithFallback,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  TokenDetailsCardSkeleton,
} from "@/components";
import { useCurrency, useDogePrice, useDrc20Watchlist } from "@/contextHooks";
import { useCurrentAccount, useFetchDrc20 } from "@/hooks";
import { cn } from "@/lib/utils";
import { ModalListDRC20 } from "@/modals";
import { getIconForTick } from "@/utility";
import { useToggle } from "@uidotdev/usehooks";

import { getFormattedTokenDetails } from "./helper";
import {
  TabTokenActivity,
  TabTokenDetails,
  TabTokenListings,
  TabTokenOwners,
} from "./tabs";
import { TokenDetailProps } from "./types";

export const Token: React.FC = () => {
  const navigate = useNavigate();
  const { currency } = useCurrency();
  const { token: tick } = useParams();
  const { dogePrice } = useDogePrice();
  const { address } = useCurrentAccount();

  const {
    tokenData,
    accountDrc20Data,
    getDrc20Data,
    loading: isTokenDataLoading,
  } = useFetchDrc20(tick);

  const {
    addToWatchlist: addToDrc20Watchlist,
    removeFromWatchlist: removeFromDrc20Watchlist,
    getIsOnWatchlist: getIsOnDrc20Watchlist,
  } = useDrc20Watchlist();

  const tokenImage = getIconForTick(tick);

  const [modalListVisible, toggleModalListVisible] = useToggle(false);

  /**
   * Token Details: Horizontal List
   */

  const tokenDetails: TokenDetailProps[] | undefined = useMemo(() => {
    if (!tokenData) return;
    return getFormattedTokenDetails(tokenData, dogePrice, currency);
  }, [tokenData, currency, dogePrice]);

  const listActionVisible = useMemo(
    () =>
      tick !== undefined && tokenData !== undefined && address !== undefined,
    [tick, tokenData, address],
  );

  const toggleWatchlist = useCallback(
    async (tick?: string) => {
      if (!tick) return;
      if (getIsOnDrc20Watchlist(tick)) {
        removeFromDrc20Watchlist(tick);
      } else {
        await addToDrc20Watchlist(tick);
      }
    },
    [addToDrc20Watchlist, getIsOnDrc20Watchlist, removeFromDrc20Watchlist],
  );

  const handleSuccess = () => {
    getDrc20Data(tick);
    if (modalListVisible) toggleModalListVisible(false);
  };

  return (
    <>
      <AppScreen>
        <div className="flex w-full flex-1 flex-col pt-2">
          {/* Screen Header */}
          <div className="flex flex-row justify-between px-2 py-2 md:px-4 md:py-4">
            <div className="flex flex-1 flex-row justify-start space-x-1 lg:space-x-4">
              <Button
                variant="ghost"
                size="icon"
                shape="circle"
                className="h-8 w-8"
                onClick={() => navigate(-1)}
              >
                <span className="material-symbols-rounded text-lg">
                  arrow_back_ios
                </span>
              </Button>
            </div>

            <div className="flex flex-1 flex-row items-center justify-center space-x-2 lg:space-x-4">
              <ImageWithFallback
                image={tokenImage}
                className="h-8 w-8 lg:h-10 lg:w-10"
              />
              <span className="text-center text-lg font-bold uppercase text-text-primary lg:text-2xl">
                {tick}
              </span>
            </div>

            <div className="flex flex-1 flex-row justify-end space-x-1 lg:space-x-4">
              <Button
                variant="ghost"
                size="icon"
                shape="circle"
                className="h-8 w-8 hover:bg-background-secondary lg:h-10 lg:w-10"
                onClick={() => toggleModalListVisible()}
                disabled={!listActionVisible}
              >
                <span className="material-symbols-rounded text-xl text-text-highlight hover:text-text-primary lg:text-2xl">
                  sell
                </span>
              </Button>
              <Button
                variant="ghost"
                size="icon"
                shape="circle"
                className="h-8 w-8 hover:bg-background-secondary lg:h-10 lg:w-10 "
                onClick={() => toggleWatchlist(tick)}
                disabled={!listActionVisible}
              >
                <span
                  className={cn(
                    "material-symbols-rounded text-2xl hover:text-text-primary lg:text-3xl",
                    tick && getIsOnDrc20Watchlist(tick)
                      ? "text-text-highlight"
                      : "text-text-tertiary",
                  )}
                >
                  star
                </span>
              </Button>
            </div>
          </div>
          {/** Token Details */}
          <div className="flex w-full flex-row space-x-2 overflow-x-auto p-4 xl:px-5">
            {!tokenDetails || !tokenDetails?.length ? (
              <TokenDetailsCardSkeleton />
            ) : (
              tokenDetails?.map((detail) => (
                <DetailCard key={detail.label} {...detail} />
              ))
            )}
          </div>

          {/** Tabs */}
          <div
            className={cn(
              "flex flex-1 flex-col bg-background-secondary",
              "lg:w-full lg:items-center 2xl:bg-transparent",
            )}
          >
            <Tabs defaultValue="listings" className="w-full">
              <TabsList
                className={cn(
                  "mt-2 grid grid-cols-4 px-4",
                  "w-full max-w-2xl bg-transparent md:mx-auto md:mt-8 md:px-0",
                )}
              >
                <TabsTrigger value="listings" className="bg-transparent">
                  Listings
                </TabsTrigger>
                <TabsTrigger value="activity" className="bg-transparent">
                  Activity
                </TabsTrigger>
                <TabsTrigger value="details" className="bg-transparent">
                  Details
                </TabsTrigger>
                <TabsTrigger value="owners" className="bg-transparent">
                  Owners
                </TabsTrigger>
              </TabsList>

              <TabsContent value="listings">
                <TabTokenListings tick={tick} />
              </TabsContent>

              <TabsContent value="activity">
                <TabTokenActivity tick={tick} />
              </TabsContent>

              <TabsContent value="details">
                <TabTokenDetails tick={tick} />
              </TabsContent>

              <TabsContent value="owners">
                <TabTokenOwners tick={tick} />
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </AppScreen>

      {listActionVisible && !isTokenDataLoading && (
        <ModalListDRC20
          token={tick}
          tokenData={tokenData}
          accountData={accountDrc20Data}
          isVisible={modalListVisible}
          onClose={toggleModalListVisible}
          onSuccess={handleSuccess}
        />
      )}
    </>
  );
};
