import { useCallback } from "react";

import { Button, ImageWithFallback, useToast } from "@/components";
import { CollectionData } from "@/types";
import { useCopyToClipboard } from "@uidotdev/usehooks";

interface TabCollectionDetailsProps {
  collectionDetails: CollectionData | undefined;
}

export const TabCollectionDetails: React.FC<TabCollectionDetailsProps> = ({
  collectionDetails,
}) => {
  const { toast } = useToast();
  const [, copy] = useCopyToClipboard();

  const onOpenDiscord = () => {
    window.open(collectionDetails?.discordLink || "#", "_blank");
  };

  const onOpenX = () => {
    window.open(collectionDetails?.twitterLink || "#", "_blank");
  };

  const onOpenWebsite = () => {
    window.open(collectionDetails?.websiteLink || "#", "_blank");
  };

  const onCopyCollectionLink = useCallback(() => {
    const collectionLink = `${window.location.origin}/#/collectible/${collectionDetails?.collectionSymbol}`;

    copy(collectionLink);
    toast({
      title: "Copied",
      description: "Copied collection link to clipboard",
    });
  }, [copy, collectionDetails?.collectionSymbol, toast]);

  const onShareCollectionLink = useCallback(() => {
    const collectionLink = `${window.location.origin}/#/collectible/${collectionDetails?.collectionSymbol}`;
    if (navigator.share) {
      navigator.share({
        title: collectionDetails?.name,
        text: `${collectionDetails?.name}: `,
        url: collectionLink,
      });
    } else {
      onCopyCollectionLink();
    }
  }, [onCopyCollectionLink]);

  return (
    <div className="mx-auto flex max-w-2xl flex-1 flex-col space-y-4 p-4">
      <div className="text-center text-md font-bold text-text-primary">{`${collectionDetails?.name} Details`}</div>
      <div className="flex flex-1 flex-col space-y-2">
        <div className="flex flex-row space-x-2 rounded-lg bg-background-primary/50 p-2 2xl:bg-background-secondary">
          <ImageWithFallback
            image={collectionDetails?.imageURI || null}
            className="h-32 w-32 rounded-sm"
          />
          <div className="flex flex-1 flex-col justify-between pb-1">
            <div className="flex flex-row items-center justify-between pl-2">
              <span className="text-md font-bold text-text-primary">
                {collectionDetails?.name || null}
              </span>
              <Button
                onClick={onShareCollectionLink}
                className="h-8 w-8"
                variant="ghost"
              >
                <span className="material-symbols-rounded text-lg text-text-tertiary">
                  ios_share
                </span>
              </Button>
            </div>

            <div className="flex flex-row space-x-4">
              <Button
                size="icon"
                variant="icon"
                className="h-8 w-8"
                onClick={onOpenWebsite}
              >
                <span className="material-symbols-rounded text-lg text-text-primary opacity-80">
                  language
                </span>
              </Button>
              <Button
                size="icon"
                variant="icon"
                className="h-8 w-8"
                onClick={onOpenDiscord}
              >
                <img
                  src="/images/icons/icon-social-discord.svg"
                  alt="discord"
                  className="h-4 w-4 opacity-80"
                />
              </Button>
              <Button
                size="icon"
                variant="icon"
                className="h-8 w-8"
                onClick={onOpenX}
              >
                <img
                  src="/images/icons/icon-social-x.svg"
                  alt="x"
                  className="h-4 w-4 opacity-80"
                />
              </Button>
            </div>

            <span className="pl-2 text-xs font-semibold text-text-tertiary">
              {`Range: ${collectionDetails?.minInscriptionNumber || 0} to ${collectionDetails?.maxInscriptionNumber || 0}`}
            </span>
          </div>
        </div>

        <p className="rounded-lg bg-background-primary/50 p-2 text-sm text-text-primary 2xl:bg-background-secondary">
          {collectionDetails?.description || null}
        </p>
      </div>
    </div>
  );
};
