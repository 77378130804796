import { ActivityType } from "@/types";

export function getLabelForActivityType(type: string): string {
  switch (type) {
    case ActivityType.Sale:
      return "Sold";
    case ActivityType.List:
      return "List";
    case ActivityType.Unlist:
      return "Delist";
    default:
      return "Unknown";
  }
}

export function getColorsForActivityType(type: string): string {
  switch (type) {
    case ActivityType.Sale:
      return "bg-green-900 text-green-100";
    default:
      return "bg-background-tertiary text-white";
  }
}

export const mapHoursToRange = (createdAt: string): string => {
  const hoursSinceCreatedAt =
    (Date.now() - new Date(createdAt).getTime()) / (1000 * 60 * 60);

  if (hoursSinceCreatedAt <= 1) return "1h";
  if (hoursSinceCreatedAt <= 6) return "6h";
  if (hoursSinceCreatedAt <= 24) return "24h";
  if (hoursSinceCreatedAt <= 24 * 2) return "48h";
  if (hoursSinceCreatedAt <= 24 * 3) return "72h";
  if (hoursSinceCreatedAt <= 24 * 7) return "7d";
  return "30d";
};
