import React, { useMemo } from "react";

import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { cn } from "@/lib/utils";
import { getIconForTick } from "@/utility";

import { AnimatedClickable } from "../animatedClickable";
import { ImageWithFallback } from "../imageWithFallback";
import { ChangeIndicator } from "@/routes/Trading/components/changeIndicator";

interface BalanceCardDunesProps {
  tick: string;
  amount: number;
  value: string;
  change: string;

  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  colorForBalanceChange?: string;
}

export const BalanceCardDunes: React.FC<BalanceCardDunesProps> = ({
  tick,
  amount,
  value,
  change,
  className,
  style,
  onClick,
  colorForBalanceChange,
}) => {
  const [image] = useMemo(() => {
    const image = getIconForTick(tick);
    // const colorPercentage = getColorForChange(change);
    return [image]; // , colorPercentage
  }, [tick]); //, change

  return (
    <AnimatedClickable
      onClick={onClick}
      className={cn(
        "flex flex-row items-center justify-between rounded-lg border-0.5 border-border-secondary/50 bg-background-secondary p-3 hover:bg-background-tertiary/50",
        className,
      )}
      style={style}
      whileHover={{ scale: 1.01 }}
    >
      <div className="flex flex-row items-center space-x-3">
        <ImageWithFallback image={image} className="h-11 w-11" />

        <div className="flex flex-col space-y-0 text-text-primary">
          <span className="text-md font-bold">{tick.toUpperCase()}</span>
          <div className="flex flex-row items-center space-x-3">
            <div className="flex flex-col py-0.5">
              <span className="text-sm text-text-tertiary">{`${formatNumber({
                value: amount,
                type: NumberFormatType.Large_Number,
                decimalPlaces: 2,
              })}`}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-end space-y-0 text-text-primary">
        <span
          className={"text-md font-medium text-text-primary"}
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        />

        {change != "0" && (
          <ChangeIndicator change={change} className="text-sm" />
        )}
      </div>
    </AnimatedClickable>
  );
};
