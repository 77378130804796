import React from "react";
import { Spinner } from "../spinner";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { getColorForChange } from "@/utility";
import { cn } from "@/lib/utils";

const isPositive = (num: number) => num > 0;

const PendingIndicator = ({ pendingAmount }: { pendingAmount?: number }) => {
  if (
    typeof pendingAmount !== "number" ||
    isNaN(pendingAmount) ||
    pendingAmount === 0
  ) {
    return null;
  }

  const colorPercentage = getColorForChange(pendingAmount);

  return (
    <div className="mx-auto flex flex-row items-center space-x-1.5 rounded-full bg-background-tertiary px-2 py-0.5">
      <Spinner size={10} />
      <span
        className={cn("text-2xs font-bold text-text-primary", colorPercentage)}
      >
        {isPositive(pendingAmount) ? "+" : ""}
        {formatNumber({
          value: pendingAmount,
          type: NumberFormatType.Large_Number,
          decimalPlaces: 2,
        })}
      </span>
    </div>
  );
};

export { PendingIndicator };
