import { ONE_DOGE_IN_SHIBES } from "@/constants";

enum NumberFormatType {
  Percentage = "Percentage",
  Large_Number = "Large_Number",
  Price = "Price",
}

type FormatNumberArgs = {
  value: number;
  type?: NumberFormatType;
  decimalPlaces?: number;
  // for exceptions
  customFormatter?: (value: number) => string;
};

const formatNumber = ({
  value,
  type = NumberFormatType.Percentage,
  decimalPlaces,
  customFormatter,
}: FormatNumberArgs) => {
  switch (type) {
    case NumberFormatType.Percentage:
      return formatPercentage(value);
    case NumberFormatType.Large_Number:
      return formatLargeNumber(value);
    case NumberFormatType.Price:
      return formatPrice(value, decimalPlaces);
    default:
      return customFormatter ? customFormatter(value) : value.toString();
  }
};

/**
 * Function to format percentages with specific rules:
 * - Always round down to 1 decimal place.
 * - If the decimal is a trailing zero, remove it and do not show the decimal.
 * - No rounding up, only down.
 *
 * @param {number} num - The number to format as a percentage.
 * @returns {string} - The formatted percentage.
 */
const formatPercentage = (num: number): string => {
  // Return "0" if the number is zero
  if (num === 0) return "0%";

  // Round down to 2 decimal place
  const roundedDownNumber = Math.floor(num * 100) / 100;

  // Convert to string and remove trailing zero if present
  const formattedNumber = roundedDownNumber.toFixed(1).replace(/\.0$/, "");

  return formattedNumber + "%";
};

/**
 * Function to format prices with specific rules:
 * - Numbers bigger than 10,000: Round down to full number and use commas.
 * - Numbers between 10 and 10,000: Truncate to 2 decimals and use commas.
 * - Numbers between 1 and 10: Truncate to 3 decimals.
 * - Numbers between 0 and 1: Truncate to 4 decimals.
 * - Numbers smaller than 0.01: Use subscripts for leading zeros in decimals.
 * - Trailing zeros should be removed.
 * - No rounding up, only down.
 *
 * @param {number} num - The number to format.
 * @returns {string} - The formatted price.
 */
const formatPrice = (num: number, decimalPlaces?: number): string => {
  // Return "0" if the number is zero
  if (num === 0) return "0";

  let formattedNumber;
  const isNegative = num < 0; // Identify if the number is negative
  const absNum = Math.abs(num); // Use the absolute value for formatting

  // Handle normal numbers
  if (absNum >= 0.01) {
    if (decimalPlaces !== undefined) {
      formattedNumber = absNum.toLocaleString("en-US", {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      });
    } else {
      if (absNum >= 10_000) {
        formattedNumber = Math.floor(absNum).toLocaleString("en-US");
      } else if (absNum >= 10) {
        formattedNumber = (Math.floor(absNum * 100) / 100).toLocaleString(
          "en-US",
        );
      } else if (absNum >= 1) {
        formattedNumber = (Math.floor(absNum * 1000) / 1000).toLocaleString(
          "en-US",
        );
      } else {
        formattedNumber = (Math.floor(absNum * 10000) / 10000).toLocaleString(
          "en-US",
          {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          },
        );
      }
    }

    formattedNumber = removeTrailingZeros(formattedNumber, decimalPlaces);
  } else {
    // Handle very small numbers
    const str = absNum.toExponential(5);
    const [base, exp] = str.split("e-");
    const subscript = Number(exp) - 1;
    const significant = (parseFloat(base) * 10)
      .toFixed(3)
      .replace(".", "")
      .slice(0, 3)
      .replace(/0+$/, "");
    formattedNumber = `0.0<span style="font-size: 70%; position: relative; top: 20%;">${subscript}</span>${significant}`;
    formattedNumber = removeTrailingZeros(formattedNumber);
  }

  return isNegative ? `${formattedNumber}` : formattedNumber;
};

/**
 * Function to format large numbers with specific rules:
 * - Thousands (K): Allow up to 2 decimal places.
 * - Millions (M): Allow up to 3 decimal places.
 * - Billions (B): Allow up to 3 decimal places.
 * - Trillions (T): Allow up to 3 decimal places.
 * - Ensure the total length does not exceed 4 digits (ignoring the decimal point).
 * - Trailing zeros should be removed.
 * - No rounding up, only down.
 *
 * @param {number} num - The number to format.
 * @returns {string} - The formatted number.
 */
const formatLargeNumber = (num: number) => {
  // For numbers less than 1000, return the whole number
  if (num < 1000) {
    return Math.floor(num).toString();
  }

  let formattedNumber;
  let abbreviation = "";
  const absNum = Math.abs(num);

  // Determine the appropriate abbreviation and format the number
  if (absNum >= 1e12) {
    // Trillions: Allow up to 3 decimal places
    formattedNumber = (Math.floor(absNum / 1e9) / 1e3).toFixed(3);
    abbreviation = "T";
  } else if (absNum >= 1e9) {
    // Billions: Allow up to 3 decimal places
    formattedNumber = (Math.floor(absNum / 1e6) / 1e3).toFixed(3);
    abbreviation = "B";
  } else if (absNum >= 1e6) {
    // Millions: Allow up to 3 decimal places
    formattedNumber = (Math.floor(absNum / 1e3) / 1e3).toFixed(3);
    abbreviation = "M";
  } else {
    // Thousands: Allow up to 2 decimal places
    formattedNumber = (Math.floor(absNum * 100) / 100e3).toFixed(2);
    abbreviation = "K";
  }

  // Remove unnecessary trailing zeros
  formattedNumber = removeTrailingZeros(formattedNumber);

  // Ensure the formatted number does not exceed 4 digits in total (ignoring the decimal point)
  const [integerPart, decimalPart] = formattedNumber.split(".");
  if (integerPart.length >= 4) {
    // If the integer part alone exceeds 4 digits, truncate it to 4 digits
    formattedNumber = integerPart.slice(0, 4);
  } else if (decimalPart) {
    // If there is a decimal part, ensure the total length is 4 digits
    const digitsNeeded = 4 - integerPart.length;
    formattedNumber = integerPart + "." + decimalPart.slice(0, digitsNeeded);
  }

  // Return the formatted number with the abbreviation
  return (formattedNumber + abbreviation).trim();
};

/**
 * Function to remove trailing zeros from a formatted number string.
 * - Removes trailing zeros after wanted decimal places.
 * - Removes the decimal point if it is followed by only zeros.
 *
 * @param {string} str - The formatted number string.
 * @returns {string} - The number string without unnecessary trailing zeros.
 */
const removeTrailingZeros = (str: string, decimalPlaces?: number) => {
  if (decimalPlaces) {
    // Split the number into integer and decimal parts
    // eslint-disable-next-line prefer-const
    let [integerPart, decimalPart] = str.split(".");

    if (decimalPart) {
      // Ensure amount of decimal places
      decimalPart = decimalPart.slice(0, decimalPlaces);
      if (/^0+$/.test(decimalPart)) {
        // If all decimal places are zeros, return only the integer part
        return integerPart;
      }
      return `${integerPart}.${decimalPart}`;
    }
    // If there's no decimal part, return the integer part as is
    return str;
  } else {
    return str.replace(/(\.\d*[1-9])0+$/, "$1").replace(/\.0+$/, "");
  }
};

const satoshisToDoge = (satoshis: number) => satoshis / ONE_DOGE_IN_SHIBES;
const dogeToSatoshi = (satoshis: number) => satoshis * ONE_DOGE_IN_SHIBES;

const usdToDoge = (usdValue: number, dogePriceInUsd: number) =>
  dogePriceInUsd <= 0 ? 0 : usdValue / dogePriceInUsd;

export {
  formatNumber,
  formatLargeNumber,
  formatPercentage,
  dogeToSatoshi,
  satoshisToDoge,
  usdToDoge,
  NumberFormatType,
};
export type { FormatNumberArgs };
