import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  ActivityCardNFT,
  TableContentCollection,
  TableExplore,
} from "@/components";
import { PAGINATION_LIMIT } from "@/constants";
import {
  useCurrency,
  useDogePrice,
  useDoginalCollectionList,
  useDoginalWatchlist,
} from "@/contextHooks";
import { useInfiniteScroll } from "@/hooks";
import { Tab } from "@/types";

import { EmptyTopMover, EmptyWatchlist, TopMoverGradient } from "../components";
import {
  ScrollableCarouselItem,
  ScrollabelCarousel,
} from "@/components/scrollableCarousel";

interface TradingCollectiblesProps {
  selectedTab: string;
}

export const TradingCollectibles: React.FC<TradingCollectiblesProps> = ({
  selectedTab,
}) => {
  const navigate = useNavigate();
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();
  const { isTabTop, isTabTrending, isTabRecent, isTabWatchlist } =
    useMemo(() => {
      return {
        isTabTop: selectedTab === Tab.Top,
        isTabTrending: selectedTab === Tab.Trending,
        isTabRecent: selectedTab === Tab.Recent,
        isTabWatchlist: selectedTab === Tab.Watchlist,
      };
    }, [selectedTab]);

  // const { refreshKey } = useRefreshKey();

  const {
    // Table Data
    tableOffset,
    isLoadingTableData,
    isLoadingMoreTableData,
    tableData,
    debouncedSetTableOffset,
    hasMoreTableData,
    // Movers Data
    topMovers,
    topSales,
    recentSales,
  } = useDoginalCollectionList();

  const { watchlist, addToWatchlist, removeFromWatchlist, getIsOnWatchlist } =
    useDoginalWatchlist();

  const collectionDataWithWatchlist = useMemo(() => {
    return tableData.map((token) => ({
      ...token,
      currency,
      currentDogePrice: dogePrice,
      listed: `${token.totalListed} / ${token.supply}`,
      watchlist: getIsOnWatchlist(token.collectionSymbol),
      onAddToWatchlist: () => addToWatchlist(token.collectionSymbol),
      onRemoveFromWatchlist: () => removeFromWatchlist(token.collectionSymbol),
    }));
  }, [
    tableData,
    currency,
    dogePrice,
    getIsOnWatchlist,
    addToWatchlist,
    removeFromWatchlist,
  ]);

  const onNavigateToCollection = useCallback(
    (collectionSymbol: string) => {
      navigate(`/collectible/${collectionSymbol.toLowerCase()}`);
    },
    [navigate],
  );

  const bottomTableRef = useInfiniteScroll({
    isLoadingData: isLoadingTableData,
    hasMoreData: hasMoreTableData,
    isLoadingMoreData: isLoadingMoreTableData,
    setOffset: debouncedSetTableOffset,
    offset: tableOffset,
    paginationLimit: PAGINATION_LIMIT,
  });

  return (
    <>
      <ScrollabelCarousel />
      <div className="relative">
        <div className="py-2 lg:py-4">
          {isTabTop &&
            (topMovers.length > 0 ? (
              <ScrollabelCarousel>
                {topMovers.map((collection) => (
                  <ScrollableCarouselItem key={`carousel-${collection.id}`}>
                    <ActivityCardNFT
                      key={collection.id}
                      onClick={onNavigateToCollection.bind(null, collection.id)}
                      {...collection}
                    />
                  </ScrollableCarouselItem>
                ))}
              </ScrollabelCarousel>
            ) : (
              <EmptyTopMover />
            ))}
          {isTabTrending &&
            (topSales.length > 0 ? (
              <ScrollabelCarousel>
                {topSales.map((collection) => (
                  <ScrollableCarouselItem key={`carousel-${collection.id}`}>
                    <ActivityCardNFT
                      key={collection.id}
                      onClick={onNavigateToCollection.bind(null, collection.id)}
                      {...collection}
                    />
                  </ScrollableCarouselItem>
                ))}
              </ScrollabelCarousel>
            ) : (
              <EmptyTopMover />
            ))}

          {isTabRecent &&
            (recentSales.length > 0 ? (
              <ScrollabelCarousel>
                {recentSales.map((recentSale) => (
                  <ScrollableCarouselItem key={`carousel-${recentSale.id}`}>
                    <ActivityCardNFT
                      key={recentSale.id}
                      onClick={onNavigateToCollection.bind(
                        null,
                        recentSale.collectionSymbol ?? "",
                      )}
                      showInfoOnHover={false}
                      {...recentSale}
                    />
                  </ScrollableCarouselItem>
                ))}
              </ScrollabelCarousel>
            ) : (
              <EmptyTopMover />
            ))}

          {isTabWatchlist &&
            (watchlist.length > 0 ? (
              <ScrollabelCarousel id="watchlist-collectibles">
                {watchlist.map((data) => {
                  const { id, imageURI, title, value, description, mcapInUSD } =
                    data;
                  return (
                    <ScrollableCarouselItem key={`carousel-${id}`}>
                      <ActivityCardNFT
                        key={id}
                        onClick={onNavigateToCollection.bind(null, id)}
                        title={title}
                        valuePrimary={value}
                        valueSecondary={mcapInUSD}
                        valueSecondaryIsChange={false}
                        image={imageURI || ""}
                        description={description}
                      />
                    </ScrollableCarouselItem>
                  );
                })}
              </ScrollabelCarousel>
            ) : (
              <EmptyWatchlist />
            ))}
        </div>

        {/** Don't display the gradient on the <EmptyWatchlist /> */}
        {!(isTabWatchlist && watchlist.length === 0) && <TopMoverGradient />}
      </div>

      <TableExplore
        defaultSortId="volumeThirtyDays"
        columns={TableContentCollection}
        data={collectionDataWithWatchlist}
        loading={isLoadingTableData}
        loadingMore={isLoadingMoreTableData}
        className="lg:mt-2"
      />

      <div ref={bottomTableRef} />
    </>
  );
};
