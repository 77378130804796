import * as bitcoin from "bitcoinjs-lib";
import * as dogecore from "bitcore-lib-doge";
import * as ecc from "tiny-secp256k1";

import { LOW_NETWORK_FEE_RATE } from "@/constants";

import {
  fundWallet,
  getTotalTransactionFeeInSats,
} from "@/context/wallet/lib/transaction.ts";

const { Transaction } = dogecore;
bitcoin.initEccLib(ecc);

import { Inscription, Utxo } from "@/types";
import { TxWallet, TxWithFeeEstimation } from "@/context/wallet/types.ts";

type BuildSendCollectiblesTxsParams = {
  txWallet: TxWallet | undefined;
  address: string | undefined;
  receiverAddresses: string[];
  inscriptions: Inscription[];
  feePerVByte: number;
  estimateFeesOnly: boolean;
};

const buildSendCollectiblesTxs = async (
  buildSendCollectiblesTxsParams: BuildSendCollectiblesTxsParams,
): Promise<TxWithFeeEstimation[]> => {
  const {
    txWallet,
    address,
    receiverAddresses,
    inscriptions,
    feePerVByte,
    estimateFeesOnly,
  } = buildSendCollectiblesTxsParams;

  if (!txWallet) {
    console.warn("buildSendCollectiblesTxs - no wallet found");
    throw new Error("No wallet found");
  }

  if (!address) {
    console.warn("buildSendCollectiblesTxs - no address given");
    throw new Error("No no address given");
  }

  // array to store all txs that we will broadcast at the end
  const txs = [];

  const pairs: [Inscription, string][] = inscriptions.map(
    (inscription, index) => [inscription, receiverAddresses[index]],
  );

  for (const [inscription, receiverAddress] of pairs) {
    // Build transaction
    const tx = new Transaction();
    const utxoFormattedForTx: Utxo = {
      txid: inscription.utxo.txid,
      vout: inscription.utxo.vout,
      satoshis: inscription.utxo.shibes,
      script: inscription.utxo.script,
    };
    tx.from(utxoFormattedForTx);
    tx.to(receiverAddress, inscription.utxo.shibes);

    // Fund & sign
    const { tx: fundedTx } = fundWallet(
      txWallet,
      tx,
      LOW_NETWORK_FEE_RATE,
      false,
    );

    if (!estimateFeesOnly) {
      txWallet.updateUtxos({ address, tx: fundedTx });
    }

    txs.push({
      tx: fundedTx,
      txFeeInSats: getTotalTransactionFeeInSats(tx, feePerVByte),
    });
  }

  return txs;
};

export { buildSendCollectiblesTxs };
