import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  AnimatedClickable,
  AppNavigation,
  AppScreen,
  Appear,
  Button,
  List,
  ListingCardDune,
  Spinner,
  useToast,
  ImageWithFallback,
} from "@/components";
import { ONE_DOGE_IN_SHIBES } from "@/constants";
import { useCurrency, useDogePrice, useTxWallet } from "@/contextHooks";
import { calculateMarketCap } from "@/hooks";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { cn } from "@/lib/utils";
import { ModalListDune, ModalSendDune } from "@/modals";
import { Currency, DuneListing } from "@/types";
import {
  calculateFloorDifference,
  getFormattedValue,
  getIconForTick,
} from "@/utility";

export const AccountDuneDetail: React.FC = () => {
  const { toast } = useToast();
  const { token } = useParams();
  const navigate = useNavigate();
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();
  const {
    txWallet,
    accountDuneListings,
    accountDuneData: accountData,
    delistDune,
  } = useTxWallet();

  /**
   * Local State
   */

  const intervalId = useRef<null | NodeJS.Timeout>(null);
  const [selectedListings, setSelectedListings] = useState<string[]>([]);
  const [modalSendVisible, setModalSendVisible] = useState(false);
  const [modalListVisible, setModalListVisible] = useState(false);

  /**
   * Data Fetching
   */

  const { error, isError, preparationErrors, isSuccess, execute, isLoading } =
    delistDune;

  /**
   * Actions
   */

  const toggleModalSend = useCallback(() => {
    setModalSendVisible((prev) => !prev);
  }, []);

  const toggleModalList = useCallback(() => {
    setModalListVisible((prev) => !prev);
  }, []);

  const onSelectListing = useCallback(
    (id: string) => {
      setSelectedListings((prev) => {
        if (prev.includes(id)) {
          return prev.filter((i) => i !== id);
        }
        return [...prev, id];
      });
    },
    [setSelectedListings],
  );

  // Open the token page
  const openTokenPageHandler = useCallback(() => {
    token && navigate(`/dune/${token}`);
  }, [navigate, token]);

  // Send, List and Buy actions
  const onSend = useCallback(() => {
    toggleModalSend();
  }, [toggleModalSend]);

  const onList = useCallback(() => {
    toggleModalList();
  }, [toggleModalList]);

  const onDelist = useCallback(() => {
    // get the inscriptionIds of the selected listings by
    // filtering the listings based on the selected offerIds
    const filteredListings = accountDuneListings.listings.filter(
      (listing: DuneListing) => selectedListings.includes(listing.offerId),
    );

    const tx_hashes = filteredListings.map(
      (listing: DuneListing) => listing.txHash,
    );
    const tx_vouts = filteredListings.map((listing: DuneListing) =>
      Number(listing.txOut),
    );
    const offerIds = filteredListings.map(
      (listing: DuneListing) => listing.offerId,
    );

    execute(tx_hashes, tx_vouts, offerIds);
  }, [accountDuneListings.listings, execute, selectedListings]);

  const onBuy = useCallback(() => {
    openTokenPageHandler();
  }, [openTokenPageHandler]);

  // Value and valueDetail for the token
  const { value, valueDetail } = useMemo(() => {
    if (!accountData || accountData.loading || !accountData.accountDunesData)
      return { value: "0.00", valueDetail: "0.00" };

    const detail =
      currency === Currency.USD
        ? accountData.accountDunesData.walletTokenValueInUSD
        : accountData.accountDunesData.walletTokenValueInDoge;

    return {
      value: `${accountData?.accountDunesData?.availableBalance} ${token?.toUpperCase()}`,
      valueDetail: `${currency}${detail}`,
    };
  }, [accountData, token, currency]);

  const listAbout = useMemo(() => {
    if (!accountData || !accountData.tokenData) return [];
    return [
      {
        key: "floor",
        label: "Floor",
        value: `${getFormattedValue(accountData.tokenData.floorPrice || 0, dogePrice, currency, true).value}`,
      },
      {
        key: "marketCap",
        label: "Market Cap",
        value: `${getFormattedValue(calculateMarketCap(accountData.tokenData), dogePrice, currency, false).value}`,
      },
      {
        key: "twentyFourHourVolume",
        label: "24 Hour Volume",
        value: `${getFormattedValue(accountData.tokenData.twentyFourHourVolume || 0, dogePrice, currency, false).value}`,
      },
      {
        key: "volume",
        label: "Volume",
        value: `${getFormattedValue(accountData.tokenData.volume || 0, dogePrice, currency, false).value}`,
      },
      {
        key: "holders",
        label: "Owners",
        value: formatNumber({
          value: accountData.tokenData.holders || 0,
          type: NumberFormatType.Large_Number,
        }),
      },
    ];
  }, [dogePrice, accountData, currency]);

  /**
   * Effects
   */
  const refetch = useCallback(
    async (cachebreaker: boolean = false) => {
      if (token && txWallet?.address && !accountData.loading) {
        console.log("AccountDuneDetail - Fetching listings and data");
        await Promise.all([
          accountDuneListings.fetchListings(token, cachebreaker),
          accountData.getDuneData(token),
          accountData.getDuneAccountData(token),
        ]);
      }
    },
    [token, txWallet?.address, accountDuneListings, accountData],
  );

  // Delist Success
  useEffect(() => {
    if (isSuccess) {
      const numberOfListings = selectedListings.length;
      toast({
        title: "Success",
        variant: "default",
        description: `Successfully delisted ${numberOfListings} ${numberOfListings === 1 ? "listing" : "listings"}.`,
      });

      setSelectedListings([]);
      // reload with waiting for caches to be invalidated
      setTimeout(() => accountDuneListings.fetchListings(token), 2000);
    }
  }, [isSuccess, selectedListings, toast, accountDuneListings, token]);

  // Delist Error
  useEffect(() => {
    if ((isError && error) || preparationErrors) {
      toast({
        title: "Error",
        variant: "error",
        description: error ?? "An unknown error occurred …",
      });

      setSelectedListings([]);
      refetch();
    }
  }, [token, error, isError, toast, preparationErrors, refetch]);

  useEffect(() => {
    if (txWallet?.address) {
      console.log("AccountDuneDetail - Initial loading");
      if (!intervalId.current) {
        refetch();
      }

      if (intervalId.current) {
        clearInterval(intervalId.current);
      }

      // Set a new interval
      intervalId.current = setInterval(() => refetch(), 60000);

      // Cleanup on unmount
      return () => clearInterval(intervalId.current as NodeJS.Timeout);
    }
  }, [txWallet?.address, refetch]);

  return !accountData ? (
    <></>
  ) : (
    <>
      <AppScreen withNavigationHeader>
        <AppNavigation
          title={token?.toUpperCase() || " "}
          onAction={openTokenPageHandler}
          onBack={() => navigate(-1)}
          iconAction="open_in_new"
          withAppHeader
          className="lg:hidden"
        />

        {accountData && accountData.loading ? (
          <div className="flex flex-1 flex-col items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div
            className={cn(
              "flex flex-col space-y-8 py-4",
              "mx-auto w-full max-w-xl drop-shadow-lg xl:mt-12 xl:rounded-2xl xl:border-0.5 xl:border-border-primary xl:bg-gradient-to-t xl:from-background-primary xl:to-background-secondary xl:p-4",
            )}
          >
            {/** Header */}
            <div className="hidden flex-row items-center justify-between lg:flex">
              <Button
                variant="icon"
                shape="circle"
                onClick={() => navigate(-1)}
                className="h-8 w-8 text-text-tertiary"
              >
                <span className="material-symbols-rounded text-lg">
                  arrow_back
                </span>
              </Button>
              <Button
                variant="icon"
                shape="circle"
                onClick={openTokenPageHandler}
                className="h-8 w-8 text-text-tertiary"
              >
                <span className="material-symbols-rounded text-lg">
                  open_in_new
                </span>
              </Button>
            </div>

            {/** Balance */}
            <div className="flex flex-col space-y-1">
              <div className="flex flex-1 flex-row items-center justify-center space-x-2 lg:space-x-4">
                {token && (
                  <ImageWithFallback
                    image={getIconForTick(token.toLowerCase())}
                    className="h-8 w-8 lg:h-10 lg:w-10"
                  />
                )}
                <span
                  className="text-center text-4xl font-bold text-text-primary"
                  dangerouslySetInnerHTML={{ __html: value }}
                />
              </div>
              <span
                className="text-center text-sm font-medium text-text-tertiary"
                dangerouslySetInnerHTML={{ __html: valueDetail }}
              />
            </div>

            {/** Actions */}
            <div className="flex w-full flex-row space-x-2 px-6">
              <Button
                className="flex-1"
                shape="circle"
                onClick={onSend}
                disabled={accountData.loading}
              >
                Send
              </Button>
              <Button
                className="flex-1"
                shape="circle"
                onClick={onList}
                disabled={accountData.loading}
              >
                List
              </Button>
              <Button
                className="flex-1"
                shape="circle"
                onClick={onBuy}
                disabled={accountData.loading}
              >
                Buy
              </Button>
            </div>

            {/** Listings */}
            <div className="flex flex-col space-y-2">
              <span className="ml-4 text-lg font-medium text-text-primary">
                Listings
              </span>
              <div className="flex flex-row space-x-2 overflow-x-auto px-4 py-2">
                {accountDuneListings.listings.length ? (
                  accountDuneListings.listings.map((listing: any) => {
                    const { offerId, tick, amount, price, status, unitPrice } =
                      listing;

                    const onSelect = () => onSelectListing(offerId);
                    const selected = selectedListings.includes(offerId);

                    const totalPriceInDoge = formatNumber({
                      value: price / ONE_DOGE_IN_SHIBES,
                      type: NumberFormatType.Price,
                    });

                    const unitPriceInDoge = formatNumber({
                      value: unitPrice / ONE_DOGE_IN_SHIBES,
                      type: NumberFormatType.Price,
                    });

                    const totalPriceInUsd = formatNumber({
                      value: (price / ONE_DOGE_IN_SHIBES) * (dogePrice ?? 0),
                      type: NumberFormatType.Price,
                    });

                    const unitPriceInUsd = formatNumber({
                      value:
                        (unitPrice / ONE_DOGE_IN_SHIBES) * (dogePrice ?? 0),
                      type: NumberFormatType.Price,
                    });

                    const floorDifferencePercentage = calculateFloorDifference(
                      unitPrice,
                      accountData.tokenData?.floorPrice || 0,
                    );

                    return (
                      <ListingCardDune
                        key={offerId}
                        tick={tick}
                        onSelect={onSelect}
                        selected={selected}
                        className="w-48 flex-shrink-0"
                        value={`${amount}`}
                        floorDifferencePercentage={floorDifferencePercentage}
                        totalPriceDoge={`${Currency.DOGE}${totalPriceInDoge}`}
                        unitPriceDoge={`${Currency.DOGE}${unitPriceInDoge}`}
                        totalPriceUSD={`${Currency.USD}${totalPriceInUsd}`}
                        unitPriceUSD={`${Currency.USD}${unitPriceInUsd}`}
                        status={status}
                        displayPendingState
                      />
                    );
                  })
                ) : (
                  <AnimatedClickable
                    onClick={onList}
                    className="flex h-52 w-48 cursor-pointer flex-col items-center justify-center space-y-2 rounded-lg bg-background-secondary p-4 hover:bg-background-tertiary"
                  >
                    <span className="material-symbols-rounded text-2xl text-text-primary">
                      add_circle
                    </span>
                    <span className="text-center text-sm font-semibold text-text-primary">
                      {`List ${token?.toUpperCase()}`}
                    </span>
                  </AnimatedClickable>
                )}
              </div>
            </div>

            {/** About */}
            <List title={`About ${token?.toUpperCase()}`} items={listAbout} />
          </div>
        )}
      </AppScreen>

      <Appear isVisible={selectedListings.length > 0} from="bottom">
        <Button
          size="large"
          shape="circle"
          variant="inverse"
          onClick={onDelist}
          className="w-1/2 shadow-lg"
          disabled={isLoading}
        >
          {isLoading ? <Spinner size={24} /> : "Delist Selected"}
        </Button>
      </Appear>

      {token !== undefined && (
        <ModalSendDune
          token={token}
          isVisible={modalSendVisible}
          listings={accountDuneListings.listings}
          onClose={toggleModalSend}
        />
      )}

      {token !== undefined && true && accountData.tokenData !== undefined && (
        <ModalListDune
          listings={accountDuneListings.listings}
          token={token}
          tokenData={accountData.tokenData}
          accountData={accountData.accountDunesData}
          isVisible={modalListVisible}
          onClose={toggleModalList}
          onSuccess={refetch}
        />
      )}
    </>
  );
};
