import { ONE_DOGE_IN_SHIBES } from "@/constants";
import { NumberFormatType, formatNumber } from "@/lib/numbers";

/**
 * Calculates the value of a token amount in Doge and formats it.
 *
 * @param amount - The amount of the token.
 * @param floorPrice - The floor price of the token.
 * @param maximumFractionDigits - The maximum number of fraction digits to display.
 * @returns The formatted value in Doge.
 */
export const calculateTokenValueInDoge = (
  amount: number,
  floorPrice: number,
): string => {
  const valueInDoge = (amount * floorPrice) / ONE_DOGE_IN_SHIBES;
  return formatNumber({ value: valueInDoge, type: NumberFormatType.Price });
};
