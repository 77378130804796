import React from "react";
import {
  Route,
  RouterProvider,
  createHashRouter,
  createRoutesFromElements,
} from "react-router-dom";

import {
  Account,
  AccountCollectionDetail,
  AccountTokenDetail,
  AccountDuneDetail,
  Collection,
  CollectionDetail,
  Dune,
  LayoutApp,
  NoResults,
  PageError,
  PageNotFound,
  Token,
  Trading,
  WalletOverview,
} from "./routes";

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<LayoutApp />} errorElement={<PageError />}>
      {/* Make Trading the index route and accessible via /trading */}
      <Route index element={<Trading />} />
      <Route path="trading" element={<Trading />} />
      <Route path="trading/:content" element={<Trading />} />

      {/* Other Tabs */}
      {/*<Route path="chat" element={<Chat />} />*/}

      <Route path="account">
        <Route index element={<Account />} />
        <Route path="drc20/:token" element={<AccountTokenDetail />} />
        <Route
          path="collectible/:collectionSymbol"
          element={<AccountCollectionDetail />}
        />
        <Route path="dune/:token" element={<AccountDuneDetail />} />
      </Route>

      <Route path="drc20/:token" element={<Token />} />
      <Route path="dune/:token" element={<Dune />} />
      <Route path="collectible/:collectionSymbol" element={<Collection />} />
      <Route
        path="collectible/:collectionSymbol/:id"
        element={<CollectionDetail />}
      />
      <Route path="collectible/:collectionSymbol" element={<Collection />} />

      <Route path="wallet/:walletAddress" element={<WalletOverview />} />

      <Route path="search/noresults" element={<NoResults />} />

      {/* Fallback for any unmatched routes */}
      <Route path="*" element={<PageNotFound />} />
    </Route>,
  ),
);

export const AppRouter: React.FC = () => {
  return <RouterProvider router={router} />;
};
