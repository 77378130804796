export const EmptyWatchlist: React.FC = () => (
  <div className="flex h-28 w-full flex-shrink-0 cursor-pointer flex-col items-center justify-center space-y-2 rounded-lg border-0.5 border-border-primary bg-background-secondary md:h-40 lg:h-56">
    <span className="text-center text-sm font-semibold text-text-secondary">
      Watchlist Empty
    </span>
    <span className="text-center text-xs text-text-secondary">
      Add to your watchlist by pressing the{" "}
      <span className="material-symbols-rounded text-xs text-text-primary">
        star
      </span>{" "}
      icon.
    </span>
  </div>
);
