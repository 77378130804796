import { createContext, useCallback, useEffect, useState, FC } from "react";

// Assuming these are your custom hooks and constants
import { PAGINATION_LIMIT } from "@/constants";
import { useCurrency, useDogePrice } from "@/contextHooks";
import { useLocalStorage, useOnlineStatus } from "@/hooks";
import { Currency, Sorting } from "@/types";
import {
  WatchlistContextType,
  WatchlistItem,
  WatchlistProviderProps,
} from "@/types/watchlist.ts";
import { handleError } from "@/utility";

const createWatchlistContext = <T extends { [key: string]: any }>() => {
  return createContext<WatchlistContextType<T>>({
    watchlist: [],
    addToWatchlist: async () => {},
    removeFromWatchlist: () => {},
    syncWatchlist: async () => {},
    isWatchlistLoading: false,
    getIsOnWatchlist: () => false,
  });
};

const WatchlistProvider: FC<WatchlistProviderProps<any>> = ({
  children,
  watchlistName,
  fetchTokens,
  mapTokenToItem,
  watchlistKey,
  sortParam,
  Context,
}) => {
  const isOnline = useOnlineStatus();
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();
  const [isWatchlistLoading, setIsWatchlistLoading] = useState(false);
  const [isWatchlistSuccess, setIsWatchlistSuccess] = useState(false);
  const [isRemoveFromWatchlistSuccess, setIsRemoveFromWatchlistSuccess] =
    useState(false);
  const [isAddWatchlistSuccess, setIsAddWatchlistSuccess] = useState(false);
  const [watchlist, setWatchlist] = useState<WatchlistItem[]>([]);
  const { value: storedWatchlist, updateValue: updateStoredWatchlist } =
    useLocalStorage<[] | any[]>(watchlistName, []);

  const processWatchlist = useCallback(
    (
      list: any[],
      sortParam: keyof any,
      dogePrice: number,
      currency: Currency,
    ): WatchlistItem[] => {
      if (list.length === 0) {
        return [];
      }

      if (
        list.length >= 0 &&
        (!Object.keys(list[0]).includes(sortParam as string) ||
          typeof list[0][sortParam] !== "number")
      ) {
        throw new Error(
          `${String(sortParam)} must be a key of the item with a numeric value`,
        );
      }

      const sortedList = list.sort(
        (a, b) =>
          Math.abs((b[sortParam] as number) || 0) -
          Math.abs((a[sortParam] as number) || 0),
      );

      return sortedList.map((item) => {
        const mappedItem = mapTokenToItem(item, dogePrice);
        return {
          ...mappedItem,
          value:
            currency === Currency.DOGE
              ? mappedItem.priceInDOGE
              : mappedItem.priceInUSD,
        };
      });
    },
    [mapTokenToItem],
  );

  const getTokenDataFromContext = useCallback(
    (id: string) => {
      return watchlist.find(
        (item) => item.id.toLowerCase() === id.toLowerCase(),
      );
    },
    [watchlist],
  );

  const getIsOnWatchlist = useCallback(
    (id: string) => {
      return storedWatchlist.some(
        (item) =>
          (item[watchlistKey] as unknown as string).toLowerCase() ===
          id.toLowerCase(),
      );
    },
    [storedWatchlist, watchlistKey],
  );

  const addToWatchlist = useCallback(
    async (id: string) => {
      setIsAddWatchlistSuccess(false);
      if (storedWatchlist.length === 20) {
        throw new Error("Watchlist is full");
      }
      if (getIsOnWatchlist(id)) {
        return;
      }

      const tokenFromContext = getTokenDataFromContext(id);
      if (tokenFromContext) {
        updateStoredWatchlist((prevStoredWatchlist) => {
          const updatedWatchlist = [
            ...prevStoredWatchlist,
            {
              ...(tokenFromContext as unknown as any),
            },
          ];
          const uniqueWatchlistMap = new Map(
            updatedWatchlist.map((item) => [item[watchlistKey], item]),
          );
          return Array.from(uniqueWatchlistMap.values());
        });
      } else {
        try {
          setIsWatchlistLoading(true);
          const result = await fetchTokens({
            offset: 0,
            limit: PAGINATION_LIMIT,
            sortParam: Sorting.top,
            currency: currency,
            filter: id,
          });

          if (result.length === 0) {
            throw new Error(`Token ${id} not found. Cannot add to watchlist.`);
          }

          updateStoredWatchlist((prevStoredWatchlist) => {
            const updatedWatchlist = [
              ...prevStoredWatchlist,
              {
                ...result[0],
              },
            ];

            const uniqueWatchlistMap = new Map(
              updatedWatchlist.map((item) => [item[watchlistKey], item]),
            );
            return Array.from(uniqueWatchlistMap.values());
          });
        } catch (e: Error | unknown) {
          handleError(e);
        } finally {
          setIsWatchlistLoading(false);
        }
      }
      setIsAddWatchlistSuccess(true);
    },
    [
      storedWatchlist,
      getIsOnWatchlist,
      getTokenDataFromContext,
      updateStoredWatchlist,
      fetchTokens,
      currency,
      watchlistKey,
    ],
  );

  const removeFromWatchlist = useCallback(
    (id: string) => {
      setIsRemoveFromWatchlistSuccess(false);
      const newWatchlist = storedWatchlist.filter(
        (item) =>
          (item[watchlistKey] as unknown as string).toLowerCase() !==
          id.toLowerCase(),
      );
      updateStoredWatchlist(newWatchlist);
      setIsRemoveFromWatchlistSuccess(true);
    },
    [storedWatchlist, updateStoredWatchlist, watchlistKey],
  );

  const syncWatchlist = useCallback(async () => {
    try {
      setIsWatchlistLoading(true);
      setIsWatchlistSuccess(false);
      const idList = storedWatchlist.map(
        (item) => item[watchlistKey] as unknown as string,
      );

      if (idList.length === 0) {
        setIsWatchlistLoading(false);
        return;
      }

      if (isOnline) {
        const result = await fetchTokens({
          offset: 0,
          limit: PAGINATION_LIMIT,
          sortParam: Sorting.top,
          currency: currency,
          filter: idList.join(","),
        });

        updateStoredWatchlist(result);
      }
    } catch (e: Error | unknown) {
      handleError(e);
    } finally {
      setIsWatchlistLoading(false);
      setIsWatchlistSuccess(true);
    }
  }, [
    storedWatchlist,
    isOnline,
    watchlistKey,
    fetchTokens,
    currency,
    updateStoredWatchlist,
  ]);

  const getWatchlist = useCallback(() => {
    if (!storedWatchlist) return;

    const mappedWatchlist = processWatchlist(
      storedWatchlist,
      sortParam,
      dogePrice,
      currency,
    );

    setWatchlist(mappedWatchlist);
  }, [storedWatchlist, processWatchlist, sortParam, dogePrice, currency]);

  // Initial fetch of watchlist without any dependencies to avoid duplicate fetchings
  useEffect(() => {
    syncWatchlist();
    getWatchlist();
    // Tbh this is not super clean but no time to refactor now
  }, [currency, dogePrice]);

  const reset = useCallback(() => {
    setIsAddWatchlistSuccess(false);
    setIsWatchlistSuccess(false);
  }, [setIsAddWatchlistSuccess, setIsWatchlistSuccess]);

  useEffect(() => {
    if (
      (isAddWatchlistSuccess || isRemoveFromWatchlistSuccess) &&
      !isWatchlistLoading
    ) {
      reset();
      getWatchlist();
    }
  }, [
    getWatchlist,
    isAddWatchlistSuccess,
    isRemoveFromWatchlistSuccess,
    isWatchlistLoading,
    reset,
  ]);

  return (
    <Context.Provider
      value={{
        watchlist,
        addToWatchlist,
        removeFromWatchlist,
        syncWatchlist,
        isWatchlistLoading,
        getIsOnWatchlist,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { WatchlistProvider, createWatchlistContext };
