import {
  isAndroid,
  isDesktop,
  isIOS,
  isMobile,
  osName,
} from "react-device-detect";

export function useOperatingSystem() {
  return {
    isDesktop,
    isAndroid,
    isIOS,
    isMobile,
    osName,
  };
}
