import React from "react";

import { cn } from "@/lib/utils";

import { AnimatedClickable } from "../animatedClickable";

interface ToggleSwitchProps {
  value: string;
  icon: string;
  disabled?: boolean;
  className?: string;
  onChange: () => void;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  value,
  icon,
  className,
  disabled = false,
  onChange,
}) => {
  const handleChange = () => !disabled && onChange();
  return (
    <AnimatedClickable
      key={value}
      className={cn(
        "relative flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-background-secondary hover:bg-background-tertiary",
        "lg:h-10 lg:w-10",
        className,
      )}
      whileHover={{ scale: 1.1 }}
      onClick={handleChange}
    >
      <span
        className={cn(
          "material-symbols-rounded text-xl",
          disabled ? "text-text-disabled" : "text-text-primary",
          "lg:text-2xl",
        )}
      >
        {icon}
      </span>

      <div
        className={cn(
          "absolute -bottom-0.5 -right-0.5 flex h-4 w-4 flex-col items-center justify-center rounded-full border-2 border-background-primary ",
          "lg:-bottom-1 lg:-right-1 lg:h-5 lg:w-5 ",
          disabled
            ? "bg-background-secondary text-text-disabled"
            : "bg-primary-500 text-white",
        )}
      >
        <span className="material-symbols-rounded text-2xs lg:text-sm">
          cached
        </span>
      </div>
    </AnimatedClickable>
  );
};
