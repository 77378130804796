import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentAccount } from "@/hooks";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { cn } from "@/lib/utils";
import { ListingStatus } from "@/types";
import { getColorForFloorDifference, getIconForTick } from "@/utility";

import { AnimatedClickable } from "../animatedClickable";
import { ImageWithFallback } from "../imageWithFallback";
import { Spinner } from "../spinner";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { ListingCardDuneProps } from "./type";
import { Link } from "react-router-dom";

export const ListingCardDune: React.FC<ListingCardDuneProps> = ({
  tick,
  selected,
  value,
  floorDifferencePercentage,
  totalPrice,
  unitPrice,
  totalPriceDoge,
  unitPriceDoge,
  totalPriceUSD,
  unitPriceUSD,
  status,
  className,
  style,
  onSelect,
  onBuy,
  displayPendingState = false,
  seller,
}) => {
  // TODO: This should not be in here but rather in the parent component
  const navigate = useNavigate();
  const { address } = useCurrentAccount();
  const currentUserIsSeller = useMemo(
    () => address === seller,
    [address, seller],
  );
  const image = useMemo(() => getIconForTick(tick), [tick]);

  const floorDifference = formatNumber({
    value: floorDifferencePercentage,
    type: NumberFormatType.Percentage,
  });
  const colorPercentage = getColorForFloorDifference(floorDifferencePercentage);

  const isPendingStateVisible = useMemo(() => {
    return status === ListingStatus.Pending && displayPendingState;
  }, [status, displayPendingState]);

  const handleBuy = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent the event from bubbling up to the parent

    if (!address) {
      navigate("/account");
    } else if (onBuy) {
      onBuy();
    }
  };

  const handleSelect = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation(); // Prevent the event from bubbling up to the parent
      if (!onSelect || isPendingStateVisible) return;
      onSelect();
    },
    [isPendingStateVisible, onSelect],
  );

  return (
    <AnimatedClickable
      onClick={!currentUserIsSeller ? handleSelect : undefined}
      className={cn(
        "relative flex w-48 flex-col space-y-0 rounded-lg bg-background-secondary p-2",
        selected && "bg-background-tertiary",
        className,
      )}
      style={style}
    >
      <div className="flex flex-row items-center justify-between space-x-2">
        <ImageWithFallback image={image} className="h-6 w-6" />

        {isPendingStateVisible ? (
          <div className="flex h-6 w-6 flex-col items-center justify-center rounded-full bg-background-tertiary">
            <Spinner size={16} />
          </div>
        ) : selected ? (
          <Checkbox
            id={tick}
            checked={selected}
            className="h-6 w-6 rounded-full border-none bg-background-tertiary text-white data-[state=checked]:bg-primary-500"
          />
        ) : !currentUserIsSeller ? (
          <div className="flex h-6 w-6 flex-col items-center justify-center rounded-full border-none bg-background-tertiary">
            <span className="material-symbols-rounded text-sm text-text-primary">
              add
            </span>
          </div>
        ) : (
          <div className="flex h-6 w-6 flex-col items-center justify-center rounded-full border-none bg-text-highlight">
            <span className="material-symbols-rounded text-sm text-text-primary">
              person
            </span>
          </div>
        )}
      </div>
      <div className="flex justify-center pt-4">
        <span className="flex flex-1 justify-center text-sm font-bold text-white">
          {tick.toUpperCase()}
        </span>
      </div>
      <div className="flex flex-col items-center justify-center space-y-2 py-4">
        <span
          className="text-center text-2xl font-bold text-white"
          dangerouslySetInnerHTML={{ __html: value }}
        />

        {isPendingStateVisible ? (
          <div className="flex h-8 flex-col items-center justify-center">
            <div className="flex h-5 flex-row items-center justify-center space-x-1.5 rounded-full bg-background-tertiary px-2">
              <span className={"text-2xs font-bold text-text-primary"}>
                Listing…
              </span>
              <Spinner size={10} />
            </div>
          </div>
        ) : (
          <div className="flex h-8 flex-col items-center justify-center">
            <div className="flex h-4 w-full flex-col items-center justify-center">
              {floorDifferencePercentage < 0 ? (
                <Spinner size={12} />
              ) : (
                <span
                  className={cn(
                    "text-center text-xs font-bold text-white",
                    colorPercentage,
                  )}
                >
                  {floorDifference}
                </span>
              )}
            </div>
            <span className="text-center text-2xs font-bold uppercase text-text-tertiary">
              Floor Diff
            </span>
          </div>
        )}
      </div>
      <div
        className={cn(
          "flex flex-row space-x-1 rounded-md bg-background-tertiary p-2 text-text-secondary",
          selected && "bg-background-secondary",
        )}
      >
        <div className="flex flex-1 flex-col space-y-1 text-left font-medium">
          <span className="text-2xs font-bold uppercase text-text-tertiary">
            Total
          </span>
          {totalPriceDoge && totalPriceUSD ? (
            <div className="flex flex-col space-y-0">
              <span
                className="text-xs text-text-secondary"
                dangerouslySetInnerHTML={{ __html: totalPriceDoge }}
              />

              <span
                className="text-xs text-text-tertiary"
                dangerouslySetInnerHTML={{ __html: totalPriceUSD }}
              />
            </div>
          ) : (
            totalPrice && (
              <span
                className="text-xs text-text-secondary"
                dangerouslySetInnerHTML={{ __html: totalPrice }}
              />
            )
          )}
        </div>
        <div className="flex flex-1 flex-col space-y-1 text-right font-medium">
          <span className="text-2xs font-bold uppercase text-text-tertiary">
            Unit
          </span>
          {unitPriceDoge && unitPriceUSD ? (
            <div className="flex flex-col space-y-0">
              <span
                className="text-xs text-text-secondary"
                dangerouslySetInnerHTML={{ __html: unitPriceDoge }}
              />

              <span
                className="text-xs text-text-tertiary"
                dangerouslySetInnerHTML={{ __html: unitPriceUSD }}
              />
            </div>
          ) : (
            unitPrice && (
              <span
                className="text-xs text-text-secondary"
                dangerouslySetInnerHTML={{ __html: unitPrice }}
              />
            )
          )}
        </div>
      </div>
      {!currentUserIsSeller ? (
        onBuy !== undefined && (
          <div className="pt-2">
            <Button
              className="w-full rounded-md"
              size="small"
              variant="inverse"
              onClick={handleBuy}
            >
              {address ? "Buy" : "Connect"}
            </Button>
          </div>
        )
      ) : (
        <Link to={`/account/dune/${tick}`}>
          <Button
            className="w-full rounded-md bg-text-highlight text-text-primary"
            size="small"
            variant="inverse"
            onClick={() => {}}
          >
            My listing
          </Button>
        </Link>
      )}
    </AnimatedClickable>
  );
};
