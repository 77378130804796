import React, { useEffect, useMemo } from "react";

import { AppScreen, ScrollableTabs, TabItem, ToggleSwitch } from "@/components";
import { useAppContentSettings } from "@/contextHooks";
import { Content, Tab } from "@/types";

import { TradingCollectibles, TradingDRC20, TradingDunes } from "./tabs";
import { useParams } from "react-router-dom";

const TABS = [
  { value: Tab.Top, label: "Top Movers" },
  { value: Tab.Trending, label: "Trending" },
  { value: Tab.Recent, label: "Recent Sales" },
  { value: Tab.Watchlist, label: "Watchlist" },
];

export const Trading: React.FC = () => {
  const { content: paramContent } = useParams();
  const {
    content,
    icon,
    changeContent,
    setContent,
    tabExplore,
    setTabExplore,
  } = useAppContentSettings();

  useEffect(() => {
    if (paramContent) {
      setContent(paramContent as Content);
    }
  }, []);

  const contentChecks = useMemo(() => {
    return {
      isContentDRC20: content === Content.DRC20,
      isContentCollectibles: content === Content.COLLECTIBLES,
      isContentDunes: content === Content.DUNES,
    };
  }, [content]);

  const tabItems = useMemo<TabItem[]>(() => {
    return TABS.map((tab) => ({
      label: tab.label,
      value: tab.value,
      isActive: tabExplore === tab.value,
      onSelect: (value: Tab) => setTabExplore(value),
    }));
  }, [tabExplore, setTabExplore]);

  return (
    <AppScreen withBottomNav>
      <div className="flex w-full flex-1 flex-col">
        <div className="flex h-12 w-full flex-row items-center space-x-2 pr-4 md:h-auto">
          <ScrollableTabs
            tabs={tabItems}
            className="mr-4 overflow-hidden pl-4 lg:py-2"
          />
          <ToggleSwitch
            value={content}
            icon={icon}
            onChange={changeContent}
            className="lg:hidden"
          />
        </div>
        {contentChecks.isContentDRC20 && (
          <TradingDRC20 selectedTab={tabExplore} />
        )}
        {contentChecks.isContentCollectibles && (
          <TradingCollectibles selectedTab={tabExplore} />
        )}
        {contentChecks.isContentDunes && (
          <TradingDunes selectedTab={tabExplore} />
        )}
      </div>
    </AppScreen>
  );
};
