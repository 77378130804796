import { useCallback, useEffect, useMemo, useState } from "react";

import {
  DUNES_LISTINGS_PAGINATION_LIMIT,
  FEATURE_ACTIVATION_DUNES,
  ONE_DOGE_IN_SHIBES,
} from "@/constants";
import { fetchDunesListings } from "@/context/helpers/fetchDunesListings";
import {
  DuneListing,
  FetchDuneListingsReturn,
  FilterListingsDunes,
  SortListingsDunes,
  SortOrder,
  SortParamListingsDunes,
} from "@/types";
import { ensureUniqueTableDataByTwoKeys, handleError } from "@/utility";

const FILTER_DEFAULT = {
  offset: 0,
  limit: 100,
  priceMin: "0",
  priceMax: undefined,
  floorDiffMinPercent: "0",
  listed: true,
};

const SORT_DEFAULT = {
  sortOrder: SortOrder.Ascending,
  sortParam: SortParamListingsDunes.UnitPrice,
};

export const useFetchDuneListings = (
  tick?: string,
): FetchDuneListingsReturn => {
  const [duneListings, setDuneListings] = useState<DuneListing[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [lastOffset, setLastOffset] = useState(0);
  const [sorting, setSorting] = useState<SortListingsDunes>(SORT_DEFAULT);
  const [filters, setFilters] = useState<FilterListingsDunes>({
    ...FILTER_DEFAULT,
  });

  const reset = useCallback(
    (filters?: FilterListingsDunes, sorting?: SortListingsDunes) => {
      setSorting({
        ...SORT_DEFAULT,
        ...(sorting ?? {}),
      });
      setFilters({
        ...FILTER_DEFAULT,
        ...(tick && { tick }),
        ...(filters ?? {}),
      });
    },
    [tick],
  );

  const setOffset = (offset: number) => {
    setFilters((prev) => ({ ...prev, offset }));
  };

  const setSort = (value: SortListingsDunes | undefined) => {
    // Always reset list when sorting changes
    setOffset(0);
    if (value === undefined) {
      setSorting(SORT_DEFAULT);
    } else {
      setSorting(value);
    }
  };

  const fetch = useCallback(
    async (
      params: SortListingsDunes & FilterListingsDunes,
      cachebreaker: boolean = true,
    ) => {
      if (FEATURE_ACTIVATION_DUNES !== "true") {
        return;
      }

      if (!tick || isLoading) return;

      const {
        offset,
        limit,
        seller,
        sortOrder,
        sortParam,
        status,
        amount,
        txHash,
        txOut,
        priceMin,
        priceMax,
        floorDiffMinPercent,
        floorDiffMaxPercent,
      } = params;

      try {
        // Convert priceMin and priceMax to shibes
        const priceMinInShibes = priceMin
          ? (Number(priceMin) * ONE_DOGE_IN_SHIBES).toString()
          : undefined;
        const priceMaxInShibes = priceMax
          ? (Number(priceMax) * ONE_DOGE_IN_SHIBES).toString()
          : undefined;

        if (offset != 0 && offset > offset - DUNES_LISTINGS_PAGINATION_LIMIT) {
          setIsLoadingMoreData(true);
        } else {
          setIsLoading(true);
        }

        const res = await fetchDunesListings(
          {
            tick: tick?.toLocaleLowerCase(),
            seller,
            offset,
            limit,
            sortOrder,
            sortParam,
            status,
            amount,
            txHash,
            txOut,
            priceMin: priceMinInShibes,
            priceMax: priceMaxInShibes,
            floorDiffMinPercent,
            floorDiffMaxPercent,
          },
          cachebreaker,
        );

        if (offset != 0 && offset > offset - DUNES_LISTINGS_PAGINATION_LIMIT) {
          setDuneListings(
            ensureUniqueTableDataByTwoKeys(res.offers, "txHash", "txOut"),
          );
        } else {
          setDuneListings(res.offers);
        }

        setTotalAmount(res.total);
        setHasMoreData(offset + limit < res.total);
        setLastOffset(offset);
      } catch (e: Error | unknown) {
        handleError(e);
        setDuneListings([]);
        setHasMoreData(false);
        setTotalAmount(0);
        setLastOffset(0);
        // we are throwing again to deal with error in UI further up the dependency chain
        throw new Error("Failed to fetch Dune listings");
      } finally {
        setIsLoading(false);
        setIsLoadingMoreData(false);
      }
    },
    [isLoading, tick],
  );

  const refetch = useCallback(async () => {
    if (tick) {
      fetch({ ...filters, ...sorting }, false);
    }
  }, [filters, sorting]);

  // DON'T use fetch as dependency, it will cause to call fetch 2 times
  useEffect(() => {
    if (tick) {
      fetch({ ...filters, ...sorting }, false);
    }
  }, [tick, filters, sorting]);

  const { hasActiveFilters, hasActiveSorting, hasActiveSearch } =
    useMemo(() => {
      const { tick, amount, seller, ...otherFilters } = filters;

      return {
        hasActiveFilters: !Object.entries(otherFilters).every(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ([key, value]) => FILTER_DEFAULT[key] === value,
        ),
        hasActiveSorting: !Object.entries(sorting).every(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ([key, value]) => SORT_DEFAULT[key] === value,
        ),
        hasActiveSearch: [seller, amount].some((value) => !!value),
      };
    }, [filters, sorting]);

  return {
    refetch,
    data: duneListings,
    isLoading,
    isLoadingMoreData,
    hasMoreData,
    setFilters,
    setSort,
    filters,
    sort: sorting,
    setOffset,
    offset: lastOffset,
    total: totalAmount,
    reset,
    hasActiveFilters,
    hasActiveSorting,
    hasActiveSearch,
  };
};
