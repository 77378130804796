import { useCallback, useEffect, useState } from "react";

import { marketplaceApiV2 } from "@/lib/fetch";
import {
  ActivityCollection,
  ActivityFilters,
  ActivitySort,
  ActivitySortParam,
  ActivityType,
  CollectionActivitiesRequestParams,
  FetchCollectionActivitiesReturn,
  SortOrder,
} from "@/types";
import { ensureUniqueTableData, handleError } from "@/utility";

export const FILTER_DEFAULT = {
  offset: 0,
  limit: 20,
  type: ActivityType.Sale,
};

export const SORT_DEFAULT = {
  sortParam: ActivitySortParam.Recent,
  sortOrder: SortOrder.Descending,
};

const fetchCollectionActivities = async (
  params: CollectionActivitiesRequestParams,
) => {
  const {
    collectionSymbol,
    offset,
    limit,
    type,
    sortOrder,
    sortParam,
    history,
    address,
  } = params;
  const { data } = await marketplaceApiV2(false).get(
    "/offer/doginals/activity",
    {
      params: {
        collectionSymbol,
        offset,
        limit,
        action: type,
        sortOrder,
        sortParam,
        history,
        address,
      },
    },
  );

  if (!data?.activityList || !data.activityList.length) {
    return { activitylist: [], total: 0 };
  }

  return { activitylist: data.activityList, total: data.total };
};

const useFetchCollectionActivities = (
  collectionSymbol?: string,
): FetchCollectionActivitiesReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [collectibleActivities, setCollectibleActivities] = useState<
    ActivityCollection[]
  >([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [lastOffset, setLastOffset] = useState(0);
  const [sorting, setSorting] = useState<ActivitySort | undefined>(
    SORT_DEFAULT,
  );

  const [filters, setFilters] = useState<ActivityFilters>({
    ...FILTER_DEFAULT,
  });
  const reset = useCallback((forCurrencySymbol: string | undefined) => {
    setSorting(SORT_DEFAULT);
    forCurrencySymbol &&
      setFilters({
        ...FILTER_DEFAULT,
        collectionSymbol: forCurrencySymbol,
      });
  }, []);

  const setSort = (value: ActivitySort | undefined) => {
    // Always reset list when sorting changes
    setOffset(0);
    if (value === undefined) {
      setSorting(SORT_DEFAULT);
    } else {
      setSorting(value);
    }
  };

  const setOffset = (offset: number) => {
    setFilters((prev) => ({ ...prev, offset }));
  };

  const fetch = useCallback(
    async ({
      offset,
      limit = 20,
      type,
      sortOrder,
      sortParam,
      history,
      address,
    }: CollectionActivitiesRequestParams) => {
      if (!collectionSymbol) return;

      try {
        // get last offset value and compare with new offset value
        if (offset > lastOffset) {
          setIsLoadingMoreData(true);
        } else {
          setIsLoading(true);
        }

        const res = await fetchCollectionActivities({
          collectionSymbol,
          offset,
          limit,
          type,
          sortOrder,
          sortParam,
          history,
          address,
        });

        if (offset > lastOffset) {
          setCollectibleActivities(
            ensureUniqueTableData(res.activitylist, "inscriptionId"),
          );
        } else {
          setCollectibleActivities(res.activitylist);
        }
        setTotalAmount(res.total);
        setHasMoreData(offset + limit < res.total);
        setLastOffset(offset);
      } catch (e: Error | unknown) {
        handleError(e);
        setCollectibleActivities([]);
        setHasMoreData(false);
        setTotalAmount(0);
        setLastOffset(0);
        // we are throwing again to deal with error in UI further up the dependency chain
        throw new Error("Failed to fetch Collection activity");
      } finally {
        setIsLoading(false);
        setIsLoadingMoreData(false);
      }
    },
    [collectionSymbol, lastOffset],
  );

  // DON'T use fetch as dependency, it will cause to call fetch 2 times
  useEffect(() => {
    if (collectionSymbol) {
      fetch({ ...filters, ...sorting });
    }
  }, [collectionSymbol, filters, sorting]);

  return {
    data: collectibleActivities,
    isLoading,
    hasMoreData,
    total: totalAmount,
    isLoadingMoreData,
    setFilters,
    setSort,
    offset: lastOffset,
    setOffset,
    sort: sorting as ActivitySort,
    filters,
    reset,
  };
};

export { useFetchCollectionActivities, fetchCollectionActivities };
