import { isNumber } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  Appear,
  Button,
  ButtonBar,
  ListingCardDRC20,
  Spinner,
  TableContentListingsDRC20,
  TableListings,
} from "@/components";
import { useCurrency, useDogePrice, useTxWallet } from "@/contextHooks";
import {
  useCurrentAccount,
  useFetchDune,
  useFetchDuneListings,
  useInfiniteScroll,
} from "@/hooks";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { cn } from "@/lib/utils";
import { ModalBuyDune, ModalTokenFilter, ModalTokenSearch } from "@/modals";
import { FilterBetween, SortListingsDunes, TokenListingProps } from "@/types";
import {
  calculateFloorDifference,
  formatRange,
  getFormattedValue,
  isValidDogecoinAddress,
  isValidInscriptionId,
  isValidRange,
} from "@/utility";

import { DisplayType, ModalType } from "../types";
import { DUNES_LISTINGS_PAGINATION_LIMIT, PAGINATION_LIMIT } from "@/constants";
import { ModalDunesSort } from "@/modals/modalDunesSort/modalDunesSort";

interface TabTokenListingsProps {
  tick: string | undefined;
}

export const TabTokenListings: React.FC<TabTokenListingsProps> = ({ tick }) => {
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();

  const { buyItem, login } = useTxWallet();

  const { address: currentAccountAddress } = useCurrentAccount();

  /**
   * Dune Hooks
   */

  const { tokenData } = useFetchDune(tick);
  const {
    data,
    isLoading,
    hasMoreData,
    isLoadingMoreData,
    setFilters,
    setSort,
    sort,
    filters,
    reset,
    offset,
    setOffset,
    hasActiveFilters,
    hasActiveSorting,
    hasActiveSearch,
  } = useFetchDuneListings(tick);
  const floorPrice = useMemo(() => tokenData?.floorPrice || 0, [tokenData]);

  /**
   * Local State
   */

  const [search, setSearch] = useState("");
  const [modalType, setModalType] = useState<ModalType | undefined>();
  const [display, setDisplay] = useState<DisplayType>(DisplayType.Grid);
  const [selectedListings, setSelectedListings] = useState<string[]>([]);

  /**
   * Dune Listings
   */

  const filterPrice = useMemo(() => {
    const { priceMin, priceMax } = filters;
    return {
      min: isNumber(priceMin) ? priceMin : undefined,
      max: isNumber(priceMax) ? priceMax : undefined,
    };
  }, [filters]);

  const filterFloorDifference = useMemo(() => {
    const { floorDiffMinPercent, floorDiffMaxPercent } = filters;
    return {
      min: isNumber(floorDiffMinPercent) ? floorDiffMinPercent : undefined,
      max: isNumber(floorDiffMaxPercent) ? floorDiffMaxPercent : undefined,
    };
  }, [filters]);

  const updateListings = useCallback((offerId: string) => {
    setSelectedListings((prev) => {
      const isSelected = prev.includes(offerId);
      return isSelected
        ? prev.filter((selected) => selected !== offerId)
        : [...prev, offerId];
    });
  }, []);

  const removeFromListings = useCallback((offerId: string) => {
    setSelectedListings((prev) => {
      // Only remove the offerId if it exists in the previous state
      return prev.includes(offerId)
        ? prev.filter((selected) => selected !== offerId)
        : prev;
    });
  }, []);

  const onSelectListing = useCallback(
    async (offerId: string) => {
      const isLoggedIn = await login();
      if (isLoggedIn) {
        updateListings(offerId);
      }
    },
    [updateListings, login],
  );

  const onBuyListing = useCallback(
    async (offerId: string) => {
      const isLoggedIn = await login();
      if (isLoggedIn) {
        updateListings(offerId);
        setModalType(ModalType.Buy);
      }
    },
    [login, updateListings],
  );

  const onBuyListings = useCallback(async () => {
    const isLoggedIn = await login();
    if (isLoggedIn) {
      setModalType(ModalType.Buy);
    }
  }, [login]);

  const onResetSelectedListings = useCallback(() => {
    setSelectedListings([]);
  }, []);

  /**
   * Button Bar: Modals
   */

  const onOpenModalFilter = useCallback(() => {
    setModalType(ModalType.Filter);
  }, []);

  const onOpenModalSort = useCallback(() => {
    setModalType(ModalType.Sort);
  }, []);

  const onOpenModalSearch = useCallback(() => {
    setModalType(ModalType.Search);
  }, []);

  /**
   * Button Bar: Others
   */

  const onToggleDisplay = useCallback(() => {
    setDisplay((prev) =>
      prev === DisplayType.Grid ? DisplayType.List : DisplayType.Grid,
    );
  }, []);

  const onRefresh = useCallback(
    async (resetListing: boolean = false) => {
      if (!tick) return;
      if (resetListing) onResetSelectedListings();
      reset({ tick, offset: 0, limit: DUNES_LISTINGS_PAGINATION_LIMIT });
      setSearch("");
    },
    [onResetSelectedListings, reset, tick],
  );

  const onCloseModal = useCallback(() => {
    setModalType(undefined);
  }, []);

  const onCloseBuyModal = useCallback(() => {
    setModalType(undefined);
    setSelectedListings([]);
    setTimeout(() => onRefresh(), 500);
  }, [onRefresh]);

  /**
   * Apply Filters/Sort/Search
   */

  const onApplyFilter = useCallback(
    ({
      price,
      floorDifference,
    }: {
      price: FilterBetween;
      floorDifference: FilterBetween;
    }) => {
      const updatedPriceRange = isValidRange(price.min, price.max)
        ? formatRange(price.min, price.max)
        : { min: undefined, max: undefined };

      const updatedFloorDifferenceRange = isValidRange(
        floorDifference.min,
        floorDifference.max,
      )
        ? formatRange(floorDifference.min, floorDifference.max)
        : { min: undefined, max: undefined };

      setFilters((prev: any) => ({
        ...prev,
        priceMin: updatedPriceRange.min,
        priceMax: updatedPriceRange.max,
        floorDiffMinPercent: updatedFloorDifferenceRange.min,
        floorDiffMaxPercent: updatedFloorDifferenceRange.max,
      }));

      onCloseModal();
    },
    [onCloseModal, setFilters],
  );

  const onApplySort = useCallback(
    (sort: SortListingsDunes | undefined) => {
      setSort(sort);
      onCloseModal();
    },
    [onCloseModal, setSort],
  );

  const onApplySearch = useCallback(
    (search: string) => {
      search = search.trim();
      const isValidSeller = isValidDogecoinAddress(search);
      const isInscriptionId = isValidInscriptionId(search);
      if (isValidSeller) {
        setFilters((prev: any) => ({ ...prev, seller: search }));
      } else if (isInscriptionId) {
        setFilters((prev: any) => ({ ...prev, inscriptionId: search }));
      } else if (Number(search) > 0) {
        setFilters((prev: any) => ({ ...prev, amount: search }));
      } else {
        setFilters((prev: any) => ({
          ...prev,
          seller: undefined,
          inscriptionId: undefined,
          amount: undefined,
        }));
      }
      setSearch(search);
      onCloseModal();
    },
    [onCloseModal, setFilters],
  );

  /**
   * Data Transformations
   */

  // Formatted table data
  const dataListings = useMemo<TokenListingProps[]>(() => {
    if (!data) return [];

    return data.map((listing: any) => {
      const {
        tick,
        amount,
        status,
        address,
        offerId,
        unitPrice,
        createdAt,
        price: totalPrice,
        inscriptionNumber,
      } = listing;

      const userIsSeller = address === currentAccountAddress;

      const isLoading = buyItem.isLoading;
      const isSelected = selectedListings.includes(offerId);
      const floorDifferencePercentage = calculateFloorDifference(
        unitPrice,
        floorPrice,
      );

      return {
        tick,
        amount,
        offerId,
        inscriptionNumber,

        unitPrice,
        totalPrice,
        floorDifferencePercentage,

        status,
        address,
        createdAt,

        isLoading,
        isSelected,
        onBuy: () => onBuyListing(offerId),
        onAction: () => onSelectListing(offerId),

        currency,
        currentDogePrice: dogePrice,

        userIsSeller,
      };
    });
  }, [
    currency,
    dogePrice,
    floorPrice,
    data,
    selectedListings,
    onBuyListing,
    onSelectListing,
    currentAccountAddress,
    buyItem.isLoading,
  ]);

  // Formatted card data
  const dataListingCards = useMemo(
    () =>
      dataListings.map((listing) => {
        const {
          onBuy,
          onAction,

          tick,
          status,
          amount,
          offerId,
          currency,
          unitPrice,
          totalPrice,
          isSelected,
          currentDogePrice,
          floorDifferencePercentage,

          address: seller,
        } = listing;

        const value = formatNumber({
          value: amount,
          type: NumberFormatType.Large_Number,
        });

        const { value: totalPriceForCurrency } = getFormattedValue(
          totalPrice,
          currentDogePrice,
          currency,
          true,
        );

        const { value: unitPriceForCurrency } = getFormattedValue(
          unitPrice,
          currentDogePrice,
          currency,
          true,
        );

        return {
          tick,
          value,
          status,
          key: offerId,
          selected: isSelected,
          floorDifferencePercentage,
          displayPendingState: false,
          unitPrice: unitPriceForCurrency,
          totalPrice: totalPriceForCurrency,
          className: cn("w-full", !isSelected && "bg-background-primary/50"),
          seller,

          onBuy: () => onBuy(),
          onSelect: () => onAction(),
        };
      }),
    [dataListings],
  );

  const selectedOffers = useMemo(() => {
    return dataListings.filter((listing) =>
      selectedListings.includes(listing.offerId),
    );
  }, [dataListings, selectedListings]);

  const buttonBarConfig = useMemo(() => {
    return [
      {
        icon: display === DisplayType.Grid ? "reorder" : "apps",
        onClick: onToggleDisplay,
        isActive: display !== DisplayType.Grid,
      },
      {
        icon: "filter_list",
        onClick: onOpenModalFilter,
        isActive: hasActiveFilters,
      },
      {
        icon: "swap_vert",
        onClick: onOpenModalSort,
        isActive: hasActiveSorting,
      },
      { icon: "search", onClick: onOpenModalSearch, isActive: hasActiveSearch },
      { icon: "refresh", onClick: onRefresh },
    ];
  }, [
    display,
    onOpenModalFilter,
    onOpenModalSearch,
    onOpenModalSort,
    onRefresh,
    onToggleDisplay,
    hasActiveFilters,
    hasActiveSorting,
    hasActiveSearch,
  ]);

  /**
   * Effects
   */

  // Refresh on tick change
  useEffect(() => {
    if (!tick) return;
    onRefresh();
  }, [tick]);

  /**
   * Infinite Scroll
   */

  const bottomTableRef = useInfiniteScroll({
    isLoadingData: isLoading,
    hasMoreData: hasMoreData,
    isLoadingMoreData: isLoadingMoreData,
    setOffset: setOffset,
    offset: offset,
    paginationLimit: DUNES_LISTINGS_PAGINATION_LIMIT,
  });

  return (
    <>
      <div className="flex flex-1 flex-col">
        {/** Actions */}
        <div className="w-full px-4 md:px-0">
          <ButtonBar buttons={buttonBarConfig} />
        </div>

        {/** Listings */}
        {isLoading ? (
          <div className="flex min-h-64 flex-1 flex-col items-center justify-center">
            <Spinner />
          </div>
        ) : display === "grid" ? (
          <div
            className={cn(
              "grid grid-cols-2 gap-3 p-4",
              "sm:grid-cols-3 md:mt-8 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7",
            )}
          >
            {dataListingCards.map((listing) => (
              <ListingCardDRC20
                {...listing}
                key={listing.key}
                className={cn(
                  "w-full bg-background-primary 2xl:bg-background-secondary",
                  listing.selected && "bg-background-tertiary",
                )}
              />
            ))}
            <div ref={bottomTableRef} />
          </div>
        ) : (
          <div className="mt-4 md:mt-8">
            <TableListings
              data={dataListings}
              columns={TableContentListingsDRC20}
              loading={isLoading}
            />
            <div ref={bottomTableRef} />
          </div>
        )}
      </div>

      {/** Buttons */}
      <Appear
        isVisible={selectedListings.length > 0 && modalType !== ModalType.Buy}
        from="bottom"
      >
        <div className="flex w-full flex-1 flex-row items-center justify-center space-x-2">
          <div className="absolute -bottom-6 left-0 right-0 -z-10 h-20 rounded-lg bg-gradient-to-t from-background-primary/80 to-transparent transition-all duration-200" />
          <Button
            size="large"
            shape="circle"
            variant="inverse"
            onClick={onBuyListings}
            className="w-1/2 max-w-xs border-none drop-shadow-xl disabled:cursor-not-allowed disabled:text-text-disabled disabled:opacity-100 disabled:drop-shadow-none"
          >
            {`Buy ${selectedListings.length} ${selectedListings.length > 1 ? "Listings" : "Listing"}`}
          </Button>
          <Button
            size="large"
            shape="circle"
            variant="default"
            className="h-12 w-12 border-text-highlight bg-text-highlight"
            onClick={onResetSelectedListings}
          >
            <span className="material-symbols-rounded text-lg">close</span>
          </Button>
        </div>
      </Appear>

      {/** Modals */}
      <ModalTokenFilter
        title="Filter"
        price={filterPrice}
        onClose={onCloseModal}
        onApply={onApplyFilter}
        floorDifference={filterFloorDifference}
        isVisible={modalType === ModalType.Filter}
      />

      <ModalTokenSearch
        title="Search"
        search={search}
        onClose={onCloseModal}
        onApply={onApplySearch}
        isVisible={modalType === ModalType.Search}
      />

      <ModalDunesSort
        title="Sort"
        sort={sort}
        onApply={onApplySort}
        onClose={onCloseModal}
        isVisible={modalType === ModalType.Sort}
      />

      <ModalBuyDune
        onClose={onCloseBuyModal}
        offers={selectedOffers}
        removeFromListings={removeFromListings}
        refetchListings={onRefresh}
        isVisible={modalType === ModalType.Buy}
      />
    </>
  );
};
