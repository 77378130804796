import { ImageWithFallback } from "../imageWithFallback";

interface CollectibleCardProps {
  image: string;
  itemName: string;
  collectionName: string;
  valueUSD: string;
  valueDOGE: string;
  onRemove: () => void;
}

export const CollectibleCard: React.FC<CollectibleCardProps> = ({
  image,
  itemName,
  collectionName,
  valueUSD,
  valueDOGE,
  onRemove,
}: CollectibleCardProps) => {
  return (
    <div className="relative flex flex-row overflow-visible rounded-lg bg-background-secondary">
      <ImageWithFallback
        image={image}
        className="h-24 w-24 rounded-none rounded-bl-lg rounded-tl-lg"
      />
      <div className="flex flex-1 flex-col justify-center space-y-1 py-3 pl-4">
        <span className="max-w-32 truncate text-md font-semibold text-text-primary">
          {itemName}
        </span>
        <span className="max-w-32 truncate text-xs font-semibold text-text-tertiary">
          {collectionName}
        </span>
      </div>
      <div className="flex flex-col items-end justify-center space-y-1 py-3 pr-4">
        <span
          className="text-md font-semibold text-text-primary"
          dangerouslySetInnerHTML={{ __html: valueUSD }}
        />
        <span
          className="text-xs font-semibold text-text-tertiary"
          dangerouslySetInnerHTML={{ __html: valueDOGE }}
        />
      </div>
      <button
        onClick={onRemove}
        className="absolute -right-1 -top-1 flex h-6 w-6 items-center justify-center rounded-full border-0.5 border-background-primary bg-background-secondary"
      >
        <span className="material-symbols-rounded text-md text-text-primary">
          close
        </span>
      </button>
    </div>
  );
};
