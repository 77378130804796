import { useCallback, useEffect, useState } from "react";

import { ONE_DOGE_IN_SHIBES } from "@/constants";
import { useDogePrice } from "@/contextHooks";
import { usePendingDrc20BalanceOfAddress } from "@/hooks";
import { marketplaceApiV2, walletApi } from "@/lib/fetch";
import {
  CollectionData,
  Currency,
  Drc20OverviewDetail,
  Drc20OverviewResponseData,
  PendingBalances,
  RequestParams,
} from "@/types";
import { Drc20TickData } from "@/types/drc20List";
import { getValueForCurrency, handleError } from "@/utility";

type PendingBalanceResponse = {
  address: string;
  balances: PendingBalances;
};

const fetchDrc20s = async (
  address: string,
  params?: RequestParams,
): Promise<{ drc20: Drc20OverviewResponseData[] }> => {
  const response = await marketplaceApiV2(true, { ...params, address }).get(
    `/drc20/balance`,
  );
  return { drc20: response.data.balances };
};

// @todo: move this to a call to the ord once implemented
const fetchPendingDrc20Balance = async (
  address: string,
  params?: RequestParams,
): Promise<PendingBalanceResponse> => {
  const response = await walletApi(true, params).get(
    `/pending-balance/${address}`,
  );
  return response?.data;
};

export const fetchDrc20Ticks = async (
  ticks: string[],
): Promise<Drc20TickData[]> => {
  if (ticks.length === 0) {
    return [];
  }

  const response = await marketplaceApiV2(false).get<Drc20TickData[]>(
    `/drc20s/data?ticks=${ticks.join(",")}`,
  );
  return response?.data;
};

const calculateMarketCap = (data: Drc20TickData | CollectionData): number => {
  let currentSupply: number;
  if ("supply" in data) {
    // CollectionData
    currentSupply = data.supply;
  } else {
    // Drc20TickData
    currentSupply = parseFloat(data.currentSupply);
  }
  const marketCap = currentSupply * data.floorPrice;
  return marketCap;
};

// hook to fetch any Drc20 tokens for an address and each token's details
const useFetchDrc20s = (
  address?: string,
): {
  getDrc20s: () => Promise<void>;
  drc20s: Drc20OverviewDetail[];
  loading: boolean;
  pendingBalances: PendingBalanceResponse | null;
} => {
  const { dogePrice } = useDogePrice();
  const { pendingBalances, isLoading: isLoadingPendingBalances } =
    usePendingDrc20BalanceOfAddress(address);
  const [loading, setLoading] = useState(false);
  const [drc20s, setDrc20s] = useState<Drc20OverviewDetail[]>([]);

  const getDrc20s = useCallback(async () => {
    if (!address) {
      setDrc20s([]);
      return;
    }

    if (isLoadingPendingBalances || !dogePrice) {
      return;
    }

    setLoading(true);

    let result = [] as Drc20OverviewDetail[];

    try {
      // if necessary, we could fetch additonal data about drc20 token in parallel like in useFetchDrc20.ts
      const data = await fetchDrc20s(address, { show_utxos: false });
      let drc20s = data.drc20;

      drc20s = Object.keys(pendingBalances?.balances || {}).reduce(
        (acc, tick) => {
          const existingDrc20Index = acc.findIndex(
            (drc20) => drc20.tick === tick,
          );
          const available = Number(acc[existingDrc20Index]?.available) || 0;
          const pendingBalance =
            Number(pendingBalances?.balances[tick].balance) || 0;
          if (existingDrc20Index !== -1) {
            acc[existingDrc20Index] = {
              ...acc[existingDrc20Index],
              available:
                available > 0
                  ? String(available + pendingBalance)
                  : String(available),
            };
          } else {
            acc.push({
              tick,
              available: pendingBalance > 0 ? String(pendingBalance) : "0",
              transferable: "0",
              utxos: null,
            });
          }
          return acc;
        },
        [...drc20s],
      );

      const ticks = drc20s.map((drc20) => drc20.tick);
      const ticksDetails = await fetchDrc20Ticks(ticks);

      const fullOverviewPromises = drc20s.map(async (drc20) => {
        const { tick, available, transferable } = drc20;
        const pending = Number(pendingBalances?.balances[tick]?.balance) || 0;
        let amount = Number(available) + Number(transferable);
        amount = amount < 0 ? 0 : amount;

        try {
          let floorPrice = 0;
          let changePercent = 0;
          const currentTickData = ticksDetails.find(
            (tickData) => tickData.tick == tick,
          );
          if (currentTickData) {
            floorPrice = currentTickData.floorPrice;
            changePercent = currentTickData.twentyFourHourChangePercent;
          }

          const priceData = {
            floorPrice: {
              doge: getValueForCurrency(
                currentTickData?.floorPrice ?? 0,
                Currency.DOGE,
                dogePrice,
              ),
              usd: getValueForCurrency(
                currentTickData?.floorPrice ?? 0,
                Currency.USD,
                dogePrice,
              ),
            },
            twentyFourHourChangePercent:
              currentTickData?.twentyFourHourChangePercent,
            weeklyChangePercent: currentTickData?.weeklyChangePercent,
            volume: {
              doge: getValueForCurrency(
                currentTickData?.volume ?? 0,
                Currency.DOGE,
                dogePrice,
              ),
              usd: getValueForCurrency(
                currentTickData?.volume ?? 0,
                Currency.USD,
                dogePrice,
              ),
            },
          };

          const floorPriceDoge = floorPrice / ONE_DOGE_IN_SHIBES;
          const floorPriceUsd = floorPriceDoge * dogePrice;

          return {
            tick,
            amount,
            amountPending: pending,
            valueDollar: floorPriceUsd * amount,
            valueDoge: floorPriceDoge * amount,
            changeDollar: floorPriceUsd * amount * (changePercent / 100),
            changeDoge: floorPriceDoge * amount * (changePercent / 100),
            priceData,
          };
        } catch (e: Error | unknown) {
          handleError(e);
          return null;
        }
      });

      const fullOverview = (await Promise.all(fullOverviewPromises)).filter(
        Boolean,
      );

      result = fullOverview as Drc20OverviewDetail[];
    } catch (e: Error | unknown) {
      handleError(e);
    } finally {
      setDrc20s(result);
      setLoading(false);
    }
  }, [address, dogePrice, isLoadingPendingBalances, pendingBalances?.balances]);

  useEffect(() => {
    getDrc20s();
  }, [getDrc20s]);

  return { getDrc20s, drc20s, loading, pendingBalances };
};

export type {
  Drc20OverviewDetail,
  RequestParams,
  Drc20TickData,
  PendingBalanceResponse,
};
export {
  useFetchDrc20s,
  fetchDrc20s,
  calculateMarketCap,
  fetchPendingDrc20Balance,
};
