import { marketplaceApiV2 } from "@/lib/fetch";
import {
  DoginalCollectionResponse,
  FilterListingsCollection,
  SortListingsCollection,
} from "@/types";

export const fetchDoginalListings = async (
  fetchParams: SortListingsCollection & FilterListingsCollection,
  cachebreaker: boolean = false,
): Promise<DoginalCollectionResponse> => {
  const {
    collectionSymbol,
    seller,
    offset,
    limit,
    sortOrder,
    sortParam,
    listed,
    inscriptionId,
    priceMin,
    priceMax,
    // floorDiffMinPercent,
    // floorDiffMaxPercent,
    metadata,
    name,
  } = fetchParams;

  const { data } = await marketplaceApiV2(
    cachebreaker,
  ).get<DoginalCollectionResponse>("/doginals/collection", {
    params: {
      collectionSymbol,
      owner: seller,
      offset,
      limit,
      sortOrder,
      sortParam,
      inscriptionId,
      priceMin,
      priceMax,
      metadataValue: metadata?.map((m) => m.metadataValue),
      metadataKey: metadata?.map((m) => m.metadataKey),
      listed,
      name,
      // floorDiffMinPercent,
      // floorDiffMaxPercent,
    },
  });

  return data;
};
