import { useRef, useEffect } from "react";
import _ from "lodash";

export const useDebounce = (callback: (x?: any) => void, delay: number) => {
  const debouncedCallback = useRef(_.debounce(callback, delay)).current;

  useEffect(() => {
    return () => {
      debouncedCallback.cancel();
    };
  }, [debouncedCallback]);

  return debouncedCallback;
};
