import { useCallback, useEffect, useState } from "react";

import { FEATURE_ACTIVATION_DUNES, ONE_DOGE_IN_SHIBES } from "@/constants";
import { useDogePrice } from "@/contextHooks";
import { sdoggsApiV2 } from "@/lib/fetch";
import {
  CollectionData,
  Currency,
  DunesOverviewDetail,
  DunesOverviewResponseData,
  PendingBalances,
  RequestParams,
} from "@/types";
import { DunesTickData } from "@/types/dunesList";
import { getValueForCurrency, handleError } from "@/utility";

type PendingBalanceResponseDunes = {
  address: string;
  balances: PendingBalances;
};

const fetchDunes = async (
  address: string,
  params?: RequestParams,
): Promise<{ dunes: DunesOverviewResponseData[] }> => {
  if (FEATURE_ACTIVATION_DUNES !== "true") {
    return { dunes: [] };
  }
  const response = await sdoggsApiV2(true, { ...params, address }).get(
    `/dunes/balance`,
  );

  return { dunes: response.data.balances.dunes }; // .dunes
};

export const fetchDunesTicks = async (
  ticks: string[],
): Promise<DunesTickData[]> => {
  if (FEATURE_ACTIVATION_DUNES !== "true") {
    return [];
  }

  if (ticks.length === 0) {
    return [];
  }

  const response = await sdoggsApiV2(false).get<DunesTickData[]>(
    `/dunes/data?ticks=${ticks.join(",")}`,
  );
  return response?.data;
};

const calculateMarketCapDunes = (
  data: DunesTickData | CollectionData,
): number => {
  let currentSupply: number;
  if ("supply" in data) {
    // CollectionData
    currentSupply = data.supply;
  } else {
    // DunesTickData
    currentSupply = parseFloat(data.currentSupply);
  }
  const marketCap = currentSupply * data.floorPrice;
  return marketCap;
};

// hook to fetch any Dune tokens for an address and each token's details
const useFetchDunes = (
  address?: string,
): {
  getDunes: () => Promise<void>;
  dunes: DunesOverviewDetail[];
  loading: boolean;
} => {
  const { dogePrice } = useDogePrice();
  const [loading, setLoading] = useState(false);
  const [dunes, setDunes] = useState<DunesOverviewDetail[]>([]);

  const getDunes = useCallback(async () => {
    if (!address || FEATURE_ACTIVATION_DUNES !== "true" || !dogePrice) {
      setDunes([]);
      return;
    }

    setLoading(true);

    try {
      // if necessary, we could fetch additonal data about dune token in parallel like in useFetchDrc20.ts
      const data = await fetchDunes(address, { show_utxos: false });
      const dunes: DunesOverviewResponseData[] = data.dunes;

      const ticks = dunes.map((dune: DunesOverviewResponseData) => dune.dune);
      const ticksDetails = await fetchDunesTicks(ticks);

      const fullOverviewPromises = dunes.map(
        async (dune: DunesOverviewResponseData) => {
          const tick = dune.dune;
          const amount = dune.total_balance < 0 ? 0 : dune.total_balance;

          try {
            let floorPrice = 0;
            let changePercent = 0;
            const currentTickData = ticksDetails.find(
              (tickData) => tickData.tick == tick,
            );
            if (currentTickData) {
              floorPrice = currentTickData.floorPrice;
              changePercent = currentTickData.twentyFourHourChangePercent;
            }

            const priceData = {
              floorPrice: {
                doge: getValueForCurrency(
                  currentTickData?.floorPrice ?? 0,
                  Currency.DOGE,
                  dogePrice,
                ),
                usd: getValueForCurrency(
                  currentTickData?.floorPrice ?? 0,
                  Currency.USD,
                  dogePrice,
                ),
              },
              twentyFourHourChangePercent:
                currentTickData?.twentyFourHourChangePercent,
              weeklyChangePercent: currentTickData?.weeklyChangePercent,
              volume: {
                doge: getValueForCurrency(
                  currentTickData?.volume ?? 0,
                  Currency.DOGE,
                  dogePrice,
                ),
                usd: getValueForCurrency(
                  currentTickData?.volume ?? 0,
                  Currency.USD,
                  dogePrice,
                ),
              },
            };

            const floorPriceDoge = floorPrice / ONE_DOGE_IN_SHIBES;
            const floorPriceUsd = floorPriceDoge * dogePrice;

            return {
              tick,
              amount,
              valueDollar: floorPriceUsd * amount,
              valueDoge: floorPriceDoge * amount,
              changeDollar: floorPriceUsd * amount * (changePercent / 100),
              changeDoge: floorPriceDoge * amount * (changePercent / 100),
              priceData,
            };
          } catch (e: Error | unknown) {
            handleError(e);
            return null;
          }
        },
      );

      const fullOverview = (await Promise.all(fullOverviewPromises)).filter(
        Boolean,
      );

      setDunes(fullOverview as DunesOverviewDetail[]);
    } catch (e: Error | unknown) {
      handleError(e);
    } finally {
      setLoading(false);
    }
  }, [address, dogePrice]);

  useEffect(() => {
    getDunes();
  }, [getDunes]);

  return { getDunes, dunes, loading };
};

export type {
  DunesOverviewDetail,
  RequestParams,
  DunesTickData,
  PendingBalanceResponseDunes,
};
export { useFetchDunes, fetchDunes, calculateMarketCapDunes };
