import { cn } from "@/lib/utils";
import { getColorForChange } from "@/utility";

import { AnimatedClickable } from "../animatedClickable";
import { ImageWithFallback } from "../imageWithFallback";
import { useNavigate } from "react-router-dom";
import React, { useCallback } from "react";
import { PendingIndicator, Skeleton } from "@/components";

interface BalanceCardCollectibleProps {
  title: string;
  symbol?: string;
  subtitle?: string;
  value: string;
  change: string;
  imageURI: string;
  amount: number;
  totalSupply?: number;

  numPendingSending: number;
  numPendingReceiving: number;

  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export const BalanceCardCollectible: React.FC<BalanceCardCollectibleProps> = ({
  className,
  style,
  onClick,
  symbol,
  title,
  subtitle,
  value,
  change,
  imageURI,
  amount,
  totalSupply,
  numPendingSending,
  numPendingReceiving,
}) => {
  const navigate = useNavigate();

  const colorPercentage = getColorForChange(change);

  // Click on image should link to collection overview
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation(); // Prevent the event from bubbling up to the parent
      navigate(`/collectible/${symbol}`);
    },
    [navigate, symbol],
  );

  return (
    <AnimatedClickable
      onClick={onClick}
      className={cn(
        "flex flex-row items-center justify-between overflow-clip rounded-lg border-0.5 border-border-secondary/50 bg-background-secondary hover:bg-background-tertiary/50",
        className,
      )}
      style={style}
      whileHover={{ scale: 1.01 }}
    >
      <div className="flex flex-1 flex-row justify-between">
        <div className="flex flex-1 flex-row space-x-4">
          <AnimatedClickable
            onClick={handleClick}
            className={cn("flex flex-row overflow-hidden", className)}
            style={style}
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.99 }}
          >
            <ImageWithFallback
              image={imageURI}
              className="h-20 w-20 rounded-none"
            />
          </AnimatedClickable>
          <div className="flex max-w-36 flex-col justify-center space-y-0">
            <span className="truncate text-md font-bold uppercase text-text-primary">
              {title}
            </span>
            <span className="truncate text-sm font-semibold text-text-tertiary">
              {subtitle}
            </span>

            <div className="flex items-center space-x-2">
              <span className="text-xs text-text-tertiary">{`${amount} item${amount > 1 ? "s" : ""}`}</span>

              {numPendingSending > 0 && (
                <PendingIndicator pendingAmount={numPendingSending * -1} />
              )}
              {numPendingReceiving > 0 && (
                <PendingIndicator pendingAmount={numPendingReceiving} />
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col items-end justify-center space-y-0 pr-4 text-text-primary">
          <span
            className={"text-md font-medium text-text-primary"}
            dangerouslySetInnerHTML={{
              __html: value,
            }}
          />

          {change != "" && change != "0" && (
            <span
              className={cn("text-sm", colorPercentage)}
              dangerouslySetInnerHTML={{
                __html: `${change}%`,
              }}
            />
          )}
        </div>
      </div>
    </AnimatedClickable>
  );
};

export const BalanceCardCollectibleSkeleton = () => {
  return (
    <Skeleton className="flex items-center justify-between rounded-lg bg-background-tertiary/50 pr-4">
      <div className="flex w-1/3 flex-row items-center space-x-3">
        <Skeleton className="h-20 w-20 bg-background-tertiary/70" />
        <div className="flex flex-col space-y-0.5">
          <Skeleton className="h-6 w-20 bg-background-tertiary/70" />
          <Skeleton className="h-3 w-10 bg-background-tertiary/70" />
        </div>
      </div>
      <div className="flex w-2/3 flex-row items-center justify-end space-x-2">
        <Skeleton className="h-6 w-20 bg-background-tertiary/30 font-medium uppercase" />
      </div>
    </Skeleton>
  );
};

export const BalanceCardCollectibleLoading = () => {
  return Array(3)
    .fill("")
    .map((_, index) => <BalanceCardCollectibleSkeleton key={index} />);
};
