import Sentry from "@/main";

/**
 * Handle errors and log them to Sentry
 * @param e Error or unknown
 * @returns Error message
 */

export const handleError = (e: Error | unknown) => {
  let message: string;

  if (typeof e === "object" && e !== null && "response" in e) {
    // Handle custom error object with response
    const errorObj = e as {
      response?: { data?: { message?: string; error?: string } };
    };

    message =
      errorObj.response?.data?.error ||
      errorObj.response?.data?.message ||
      "An unknown error occurred";

    // Log error to Sentry
    Sentry.captureException(new Error(message));
  } else if (e instanceof Error) {
    // Log error to Sentry
    Sentry.captureException(e);

    // Handle Error instance
    message = e.message;
  } else {
    // Fallback for other cases
    message = "An unknown error occurred";
    Sentry.captureException(new Error(message));
  }

  console.error("Error: ", message);

  return message;
};
