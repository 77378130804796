import { useCallback, useEffect, useState } from "react";

import { ONE_DOGE_IN_SHIBES } from "@/constants";
import { fetchDoginalListings } from "@/context/helpers/fetchDoginalListings.ts";
import {
  DoginalListing,
  FilterListingsCollection,
  SortListingsCollection,
  SortOrder,
  SortParamListingsCollection,
} from "@/types";
import { FetchDoginalListingsReturn } from "@/types/listings.ts";
import { ensureUniqueTableData, handleError } from "@/utility";

export const FILTER_DEFAULT = {
  offset: 0,
  limit: 20,
  priceMin: "0",
  priceMax: undefined,
  metadata: [],
  listed: true,
};

export const SORT_DEFAULT = {
  sortOrder: SortOrder.Ascending,
  sortParam: SortParamListingsCollection.Price,
};

/* fetches doginals of a certain collection AND address */

export const useFetchDoginalsOfCollection = (
  collectionSymbol?: string,
  address?: string,
): FetchDoginalListingsReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [doginals, setDoginals] = useState<DoginalListing[]>([]);
  const [floorPrice, setFloorPrice] = useState<null | number>(null);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [lastOffset, setLastOffset] = useState(0);
  const [sorting, setSorting] = useState<SortListingsCollection>(SORT_DEFAULT);
  const [filters, setFilters] = useState<FilterListingsCollection>({
    ...FILTER_DEFAULT,
  });

  const reset = useCallback(
    (filters?: FilterListingsCollection, sorting?: SortListingsCollection) => {
      setSorting({
        ...SORT_DEFAULT,
        ...(sorting ?? {}),
      });
      setFilters({
        ...FILTER_DEFAULT,
        ...(collectionSymbol && { collectionSymbol }),
        seller: address,
        ...(filters ?? {}),
      });
    },
    [address, collectionSymbol],
  );

  const setOffset = (offset: number) => {
    setFilters((prev) => ({ ...prev, offset }));
  };

  const setSort = (value: SortListingsCollection | undefined) => {
    // Always reset list when sorting changes
    setOffset(0);
    if (value === undefined) {
      setSorting(SORT_DEFAULT);
    } else {
      setSorting(value);
    }
  };

  const fetch = useCallback(
    async (
      params: SortListingsCollection & FilterListingsCollection,
      cachebreaker: boolean = false,
    ) => {
      if (!collectionSymbol || isLoading) return;

      const {
        offset,
        limit,
        seller,
        sortOrder,
        sortParam,
        listed,
        inscriptionId,
        priceMin,
        priceMax,
        metadata,
      } = params;

      try {
        const priceMinInShibes = priceMin
          ? (Number(priceMin) * ONE_DOGE_IN_SHIBES).toString()
          : undefined;
        const priceMaxInShibes = priceMax
          ? (Number(priceMax) * ONE_DOGE_IN_SHIBES).toString()
          : undefined;

        // get last offset value and compare with new offset value
        // Determine whether to set isLoading or isLoadingMoreData
        if (offset > lastOffset) {
          setIsLoadingMoreData(true);
        } else {
          setIsLoading(true);
        }

        const res = await fetchDoginalListings(
          {
            collectionSymbol: collectionSymbol.toLocaleLowerCase(),
            seller: seller ?? address ?? undefined,
            offset,
            limit,
            sortOrder,
            sortParam,
            listed,
            inscriptionId,
            priceMin: priceMinInShibes,
            priceMax: priceMaxInShibes,
            metadata,
          },
          cachebreaker,
        );

        // Show only listed doginals first
        res.doginals = res.doginals.sort((a, b) => {
          if (
            a.doginalOffer.status === "listed" &&
            b.doginalOffer.status === "listed"
          ) {
            return 0;
          }
          if (
            a.doginalOffer.status === "listed" &&
            b.doginalOffer.status !== "listed"
          ) {
            return -1;
          } else {
            return 1;
          }
        });
        if (offset > lastOffset) {
          setDoginals(ensureUniqueTableData(res.doginals, "inscriptionId"));
        } else {
          setDoginals(res.doginals);
        }
        setTotalAmount(res.total);
        setFloorPrice(res.floorPrice);
        setHasMoreData(offset + limit < res.total);
        setLastOffset(offset);
      } catch (e: Error | unknown) {
        handleError(e);
        setDoginals([]);
        setHasMoreData(false);
        setFloorPrice(0);
        setTotalAmount(0);
        setLastOffset(0);
        setIsLoading(false);
        setIsLoadingMoreData(false);
        // we are throwing again to deal with error in UI further up the dependency chain
        throw new Error("Failed to fetch Doginal listings");
      } finally {
        setIsLoading(false);
        setIsLoadingMoreData(false);
      }
    },
    [address, collectionSymbol, isLoading, lastOffset],
  );

  const refetch = useCallback(async () => {
    if (collectionSymbol) {
      // @todo: check if we can remove cachebreaker
      // Need to check runJob to find pending balances, too.
      fetch({ ...filters, ...sorting }, true);
    }
  }, [filters, sorting]);

  // DON'T use fetch as dependency, it will cause to call fetch 2 times
  useEffect(() => {
    if (collectionSymbol) {
      fetch({ ...filters, ...sorting, seller: address }, true);
    }
  }, [collectionSymbol, filters, sorting, address]);

  return {
    refetch,
    data: doginals,
    isLoading,
    hasMoreData,
    total: totalAmount,
    isLoadingMoreData,
    setFilters,
    setSort,
    offset: lastOffset,
    setOffset,
    sort: sorting,
    filters,
    reset,
    reset_for_symbol: () => {},
    floorPrice,
  };
};
