import { motion } from "framer-motion";
import React from "react";

import { useOperatingSystem } from "@/hooks";

import { Modal, ModalProps, cardTransition, cardVariants } from "../base";

export const ModalInstall: React.FC<ModalProps> = ({ ...props }) => {
  const { isAndroid, isIOS } = useOperatingSystem();
  const isDesktop = !isAndroid && !isIOS;
  return (
    <Modal
      opacity={50}
      backdropBlur={false}
      classNameContent="justify-center p-4"
      {...props}
    >
      <motion.div
        key="modal-card"
        initial="closed"
        animate="open"
        exit="closed"
        variants={cardVariants}
        transition={cardTransition}
        className="relative flex aspect-3/4 flex-col overflow-clip rounded-2xl bg-background-secondary text-text-primary"
      >
        <div className="flex flex-1 flex-col items-center justify-center space-y-2 p-4 text-center">
          {/** Header */}

          <div className="flex flex-col items-center space-y-8">
            <span className="relative flex h-16 w-16">
              <span className="relative inline-flex h-full w-full items-center justify-center rounded-full bg-background-tertiary">
                <span className="material-symbols-rounded text-4xl text-text-primary">
                  add_to_home_screen
                </span>
              </span>
            </span>

            <h1 className="text-2xl font-bold">
              {isDesktop ? "Open On Smartphone" : "Add To Home Screen"}
            </h1>
          </div>

          {/** Content  */}
          <div className="space-y-8 text-md text-text-secondary">
            {isDesktop ? (
              <div className="flex flex-col items-center space-y-8">
                <p className="">
                  Hey there, desktop surfer! Grab your smartphone and open this
                  app to explore the world of Doginals and DRC-20.
                </p>
              </div>
            ) : (
              <p>Explore the world of Doginals and DRC-20.</p>
            )}
            {isAndroid && (
              <p>
                Using Chrome? Tap the three-dot menu, choose Add to Home Screen,
                and access your Dogecoin world anytime, anywhere!
              </p>
            )}
            {isIOS && (
              <p>
                Safari enthusiasts, click the Share icon and choose Add to Home
                Screen.
              </p>
            )}
          </div>
        </div>
      </motion.div>
    </Modal>
  );
};
