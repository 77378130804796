import { wonkyOrdApi } from "@/lib/fetch";
import { RequestParams } from "@/hooks";

export const fetchDogeBalance = async (
  address?: string,
  params?: RequestParams,
) => {
  if (!address)
    return {
      utxos: [],
      total_utxos: 0,
      total_shibes: 0,
      dogeBalanceInUsd: 0,
    };
  const endpoint = `/utxos/balance/${address}?show_all=true`;
  // @todo: evaluate, why we cannot call without cachebreaker here...
  // The problem is, that after sending, listing etc. the balance is not updated in the wallet otherwise and
  // other following listings etc. are not working correctly
  const response = await wonkyOrdApi(true).get(endpoint);
  return response?.data;
};
