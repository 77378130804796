import React, { useMemo } from "react";

import { ListItem, Spinner } from "@/components";
import { useFetchDune } from "@/hooks";
import { shortenAddress } from "@/lib/address";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { WONKY_ORD_URL } from "@/constants";

interface TabTokenDetailsProps {
  tick: string | undefined;
}

export const TabTokenDetails: React.FC<TabTokenDetailsProps> = ({ tick }) => {
  const { tokenData, loading } = useFetchDune(tick);

  const dataDetail = useMemo(() => {
    if (!tokenData) return [];

    console.log("### tokenData", tokenData);

    const {
      // inscription,
      createdAt,
      //added,
      //limitPerMint,
      // mints,
      //minted,
      //mintingTransactions,
      //deployer,
      //deploymentShibescription,
      currentSupply,
      maxSupply,
      holders,
      rank,
    } = tokenData;

    return [
      // {
      //   label: "Inscription ID",
      //   value: shortenAddress(inscription),
      //   onClick: () =>
      //     window.open(
      //       `${WONKY_ORD_URL}shibescription/${inscription}`,
      //       "_blank",
      //     ),
      // },
      // { label: "Deployed By", value: shortenAddress(deployer) },
      { label: "Deployed Time", value: createdAt },
      {
        label: "Supply",
        value: maxSupply
          ? formatNumber({
              value: Number(maxSupply),
              type: NumberFormatType.Large_Number,
            })
          : 0,
      },
      { label: "Minted", value: currentSupply }, // minted },
      // limitPerMint && { label: "Limit Per Mint", value: limitPerMint },
      {
        label: "Owners",
        value: holders
          ? formatNumber({
              value: holders,
              type: NumberFormatType.Large_Number,
            })
          : 0,
      },
      { label: "Deploy Number", value: rank ?? "N/A" },
      // {
      //   label: "Mint Transactions",
      //   value: mints
      //     ? formatNumber({
      //         value: mints,
      //         type: NumberFormatType.Large_Number,
      //       })
      //     : 0,
      // },
    ];
  }, [tokenData]);

  if (loading) {
    return (
      <div className="flex h-96 flex-1 flex-col items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="mx-auto flex max-w-2xl flex-1 flex-col p-4">
      <div className="flex flex-1 flex-col space-y-4">
        <div className="text-center text-md font-bold text-text-primary">{`${tick?.toUpperCase()} Details`}</div>
        <div className="flex flex-col rounded-lg bg-background-primary/50 p-2 2xl:bg-background-secondary">
          {dataDetail.map(({ label, value }: any) => (
            <ListItem key={label} label={label} detail={value} />
          ))}
        </div>
      </div>
    </div>
  );
};
