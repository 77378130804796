import * as bitcoin from "bitcoinjs-lib";
import * as dogecore from "bitcore-lib-doge";
import ECPairFactory from "ecpair";
import * as ecc from "tiny-secp256k1";

import {
  DUST_AMOUNT_AND_MIN_PRICE,
  LOW_NETWORK_FEE_RATE,
  ONE_DOGE_IN_SHIBES,
  SERVICE_FEE_ADDRESS,
  SERVICE_FEE_SATS,
} from "@/constants";
import { broadcasterApi, wonkyOrdApi } from "@/lib/fetch.ts";
import { wait } from "@/lib/time.ts";
import { GetTx, Utxo, WalletForTx } from "@/types/transaction";
import { dogecoinNetwork, handleError } from "@/utility";
import { TxWallet } from "@/context/wallet/types.ts";
import { DunesUtxo } from "@/types";
import { Edict, constructScript, parseDuneId } from "../helpers/sendDune";

const { Opcode, PrivateKey, Script, Transaction } = dogecore;
const { Hash, Signature } = dogecore.crypto;

const getTx = async (txHash: string): Promise<GetTx> => {
  const { data } = await broadcasterApi().get<GetTx>(`/tx?hash=${txHash}`);
  return data;
};

const waitUntilTxInMempool = async (txHash: string) => {
  let txFound = false;
  while (!txFound) {
    try {
      const tx = await getTx(txHash);

      if (tx.status === "1") {
        txFound = true;
      }
    } catch (e: Error | unknown) {
      handleError(e);
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
};

// wait for tx to "get trough" (meaning to have at least one confirmation)
const waitForTxConfirmed = async (
  txHash: string,
  confirmationsToWait: number = 6,
): Promise<boolean> => {
  let shouldRetry = true;
  let attempts = 0;
  const maxAttempts = 20;

  while (shouldRetry && attempts < maxAttempts) {
    try {
      const endpoint = `/tx/${txHash}?json=true`;
      const { data } = await wonkyOrdApi(true).get(endpoint);

      if (
        (data.confirmations && data.confirmations >= confirmationsToWait) ||
        (confirmationsToWait === 0 && data.confirmations === null)
      ) {
        console.log(
          "waitForTxConfirmed - waited for confirmations",
          confirmationsToWait,
        );
        console.log("waitForTxConfirmed - data", data);
        shouldRetry = false;
        return true;
      } else {
        attempts += 1;
        if (attempts < maxAttempts) {
          await wait(10000);
        }
      }
    } catch (error: Error | unknown) {
      // We do not throw an error here, because this is just checking the blockchain state
      attempts += 1;
      if (attempts < maxAttempts) {
        await wait(10000);
      }
    }
  }

  // If the loop exits and max attempts reached, return false
  return false;
};

// TODO: cleanup or rename broadcast
type SendTxResponse = {
  result: string;
  message: string;
  status: string;
};
const sendTx = async (tx: any): Promise<SendTxResponse> => {
  try {
    const res = await broadcasterApi().post<SendTxResponse>("/tx/broadcast", {
      rawTx: typeof tx === "string" ? tx : tx.toString(),
    });
    if (res?.data?.message !== "OK") {
      throw new Error("Error broadcasting tx");
    }
    return res.data;
  } catch (e: Error | unknown) {
    handleError(e);
    throw new Error("Error broadcasting tx");
  }
};

type SendTxsResponse = {
  result: string[];
  message: string;
  status: string;
};

const sendTxs = async (
  txs: any[],
  params?: BroadcastAllAdditionalParams,
): Promise<SendTxsResponse> => {
  try {
    const txHexToSend: string[] = [];
    for (const tx of txs) {
      txHexToSend.push(typeof tx === "string" ? tx : tx.toString("hex"));
    }

    const res = await broadcasterApi().post<SendTxsResponse>(
      "/tx/broadcast/all",
      {
        rawTxs: txHexToSend,
        params,
      },
    );

    if (res?.data?.message !== "OK") {
      throw new Error("Error broadcasting txs");
    }

    return res.data;
  } catch (e: Error | unknown) {
    const message = handleError(e);
    throw new Error(message);
  }
};

export type BroadcastAllAdditionalParams = {
  address?: string;
  receiverAddresses?: string[];
};

const broadcastAll = async (
  txs: any,
  params?: BroadcastAllAdditionalParams,
): Promise<string[] | null> => {
  try {
    const { result } = await sendTxs(txs, params);
    return result;
  } catch (e: Error | unknown) {
    const message = handleError(e);
    console.error("broadcastAll - error", message);
    return null;
  }
};

const broadcast = async (
  tx: any,
  isSendingDoge: boolean = true,
): Promise<string | null> => {
  try {
    const { result: txHash } = await sendTx(tx);
    await waitUntilTxInMempool(txHash);
    await waitForTxConfirmed(txHash, 0);

    // @todo: implement if needed
    if (!isSendingDoge) {
      console.warn("Not implemented yet");
      // // used to get all other utxos
      // waitForTxConfirmed(txResponse,   10)
      //   .finally(async () => {
      //    // implement here
      //   });
    }

    return txHash;
  } catch (e: Error | unknown) {
    handleError(e);
    return null;
  }
};

const fundWallet = (
  wallet: TxWallet | WalletForTx,
  tx: any,
  networkFeeRate: number | undefined,
  isSendDoge: boolean,
): { tx: any } => {
  Transaction.FEE_PER_KB = networkFeeRate
    ? networkFeeRate * 1000
    : ONE_DOGE_IN_SHIBES;

  if (!isSendDoge) {
    tx.change(wallet.address);
    delete tx._fee;
  }

  // Larger utxos first
  const utxos = wallet.utxos.sort(
    (a: Utxo, b: Utxo) => b.satoshis - a.satoshis,
  );

  console.log("fundWallet - utxos used for tx", utxos);

  for (const [index, utxo] of utxos.entries()) {
    if (
      index > 0 &&
      tx.inputs.length &&
      tx.outputs.length &&
      tx.inputAmount >= tx.outputAmount + tx.getFee() + 1
    ) {
      break;
    }

    delete tx._fee;
    // Add the utxo to the transaction as input
    tx.from(utxo);
    // if there is a change send it back to the signer's wallet.address
    tx.change(wallet.address);
    tx.sign(new PrivateKey(wallet.privKey));
  }

  if (tx.inputAmount < tx.outputAmount + tx.getFee()) {
    throw new Error(
      `not enough funds: fee has to be ${
        (tx.outputAmount + tx.getFee()) / 1e8
      } but wallet only has ${tx.inputAmount / 1e8}`,
    );
  }
  return {
    tx,
  };
};

const bufferToChunk = (b: any, type?: any) => {
  b = Buffer.from(b, type);
  return {
    buf: b.length ? b : undefined,
    len: b.length,
    opcodenum: b.length <= 75 ? b.length : b.length <= 255 ? 76 : 77,
  };
};

const numberToChunk = (n: any) => {
  return {
    buf:
      n <= 16
        ? undefined
        : n < 128
          ? Buffer.from([n])
          : Buffer.from([n % 256, n / 256]),
    len: n <= 16 ? 0 : n < 128 ? 1 : 2,
    opcodenum: n == 0 ? 0 : n <= 16 ? 80 + n : n < 128 ? 1 : 2,
  };
};

const opcodeToChunk = (op: any) => {
  return { opcodenum: op };
};

// This function updates the wallet utxos with a transaction that is locally generated
// caution, completely copied from doginals, except for minor adjustments to our FE arch
// inscribes and creates DRC20 transfer transactions
/**
 *
 * @param fullWallet
 * @param contentType
 * @param data
 * @param feePerVByte
 * @param withTransferInscription
 * @param estimateFeesOnly - don't update the wallet utxos if set to true.
 * @param receiverAddress
 */
const createDrc20Txs = (
  fullWallet: TxWallet,
  contentType: string,
  data: any,
  feePerVByte: number,
  withTransferInscription: boolean = true,
  estimateFeesOnly: boolean = false,
  receiverAddress?: string,
) => {
  const MAX_CHUNK_LEN = 240;
  const MAX_PAYLOAD_LEN = 1500;
  const txs = [];
  const wallet = fullWallet;

  const privateKey = new PrivateKey(wallet.privKey);
  const publicKey = privateKey.toPublicKey();

  const parts = [];
  while (data.length) {
    const part = data.slice(0, Math.min(MAX_CHUNK_LEN, data.length));
    data = data.slice(part.length);
    parts.push(part);
  }

  const inscription = new Script();
  inscription.chunks.push(bufferToChunk("ord"));
  inscription.chunks.push(numberToChunk(parts.length));
  inscription.chunks.push(bufferToChunk(contentType));
  parts.forEach((part, n) => {
    inscription.chunks.push(numberToChunk(parts.length - n - 1));
    inscription.chunks.push(bufferToChunk(part));
  });

  let p2shInput;
  let lastLock;
  let lastPartial;

  while (inscription.chunks.length) {
    const partial = new Script();

    if (txs.length == 0) {
      partial.chunks.push(inscription.chunks.shift());
    }

    while (
      partial.toBuffer().length <= MAX_PAYLOAD_LEN &&
      inscription.chunks.length
    ) {
      partial.chunks.push(inscription.chunks.shift());
      partial.chunks.push(inscription.chunks.shift());
    }

    if (partial.toBuffer().length > MAX_PAYLOAD_LEN) {
      inscription.chunks.unshift(partial.chunks.pop());
      inscription.chunks.unshift(partial.chunks.pop());
    }

    const lock = new Script();
    lock.chunks.push(bufferToChunk(publicKey.toBuffer()));
    lock.chunks.push(opcodeToChunk(Opcode.OP_CHECKSIGVERIFY));
    partial.chunks.forEach(() => {
      lock.chunks.push(opcodeToChunk(Opcode.OP_DROP));
    });
    lock.chunks.push(opcodeToChunk(Opcode.OP_TRUE));

    const lockhash = Hash.ripemd160(Hash.sha256(lock.toBuffer()));

    const p2sh = new Script();
    p2sh.chunks.push(opcodeToChunk(Opcode.OP_HASH160));
    p2sh.chunks.push(bufferToChunk(lockhash));
    p2sh.chunks.push(opcodeToChunk(Opcode.OP_EQUAL));

    const p2shOutput = new Transaction.Output({
      script: p2sh,
      satoshis: 100000,
    });

    const tx = new Transaction();
    if (p2shInput) tx.addInput(p2shInput);
    tx.addOutput(p2shOutput);
    fundWallet(wallet, tx, feePerVByte, false);

    if (p2shInput) {
      const signature = Transaction.sighash.sign(
        tx,
        privateKey,
        Signature.SIGHASH_ALL,
        0,
        lastLock,
      );
      const txsignature = Buffer.concat([
        signature.toBuffer(),
        Buffer.from([Signature.SIGHASH_ALL]),
      ]);

      const unlock = new Script();
      unlock.chunks = unlock.chunks.concat(lastPartial.chunks);
      unlock.chunks.push(bufferToChunk(txsignature));
      unlock.chunks.push(bufferToChunk(lastLock.toBuffer()));
      tx.inputs[0].setScript(unlock);
    }

    // This updates the utxos of the wallet and should be used only, if needed
    if (!estimateFeesOnly) {
      wallet.updateUtxos({ tx });
    }

    txs.push(tx);

    p2shInput = new Transaction.Input({
      prevTxId: tx.hash,
      outputIndex: 0,
      output: tx.outputs[0],
      script: "",
    });

    p2shInput.clearSignatures = () => {};
    p2shInput.getSignatures = () => [];
    lastLock = lock;
    lastPartial = partial;
  }

  const tx = new Transaction();
  tx.addInput(p2shInput);
  tx.to(wallet.address, 100000);
  // Service Fee, but only if > DUST_AMOUNT_AND_MIN_PRICE
  if (SERVICE_FEE_SATS > DUST_AMOUNT_AND_MIN_PRICE) {
    tx.to(SERVICE_FEE_ADDRESS, SERVICE_FEE_SATS);
  }

  fundWallet(wallet, tx, feePerVByte, false);

  const signature = Transaction.sighash.sign(
    tx,
    privateKey,
    Signature.SIGHASH_ALL,
    0,
    lastLock,
  );
  const txsignature = Buffer.concat([
    signature.toBuffer(),
    Buffer.from([Signature.SIGHASH_ALL]),
  ]);

  const unlock = new Script();
  unlock.chunks = unlock.chunks.concat(lastPartial.chunks);
  unlock.chunks.push(bufferToChunk(txsignature));
  unlock.chunks.push(bufferToChunk(lastLock.toBuffer()));
  tx.inputs[0].setScript(unlock);

  // This updates the utxos of the wallet and should be used only, if needed
  if (!estimateFeesOnly) {
    wallet.updateUtxos({ tx });
  }

  txs.push(tx);

  // if it is listing, we don't want the send tx
  if (!withTransferInscription || !receiverAddress) return txs;

  // create send tx
  const sendTx = new Transaction();
  const output = tx.outputs[0];
  const utxo = {
    txid: tx.hash,
    vout: 0,
    script: output.script.toHex(),
    satoshis: output.satoshis,
  };
  sendTx.from(utxo);
  sendTx.to(receiverAddress, 100000);
  fundWallet(wallet, sendTx, feePerVByte, false);

  txs.push(sendTx);
  return txs;
};

// SDOGGS enhancements start
// inscribes and creates Dune transfer transactions
/**
 *
 * @param fullWallet
 * @param feePerVByte
 * @param isListing
 * @param estimateFeesOnly - don't update the wallet utxos if set to true.
 * @param receiverAddress
 */
const createDuneTxs = async (
  fullWallet: TxWallet,
  feePerVByte: number,
  isListing: boolean = true,
  duneUtxos: DunesUtxo[],
  estimateFeesOnly: boolean = false,
  amount: number,
  duneId: string,
  receiverAddress?: string,
) => {
  const txs = [];
  const wallet = fullWallet;

  const privateKey = new PrivateKey(wallet.privKey);
  const publicKey = privateKey.toPublicKey();

  let utxosUsedForTxs: DunesUtxo[] = [
    duneUtxos.find((duneUtxo) => duneUtxo.dunes.balance == amount) ?? null,
  ].filter(Boolean) as DunesUtxo[];

  let isExact = true;

  if (utxosUsedForTxs.length <= 0) {
    const sortedDuneUtxos = duneUtxos.sort(
      (a, b) => b.dunes.balance - a.dunes.balance,
    );

    const findCombination = (
      currentIndex: number,
      currentSum: number,
      selectedUtxos: DunesUtxo[],
    ): { utxos: DunesUtxo[]; isExact: boolean } => {
      if (currentSum >= amount) {
        // Check if we exactly meet the amount
        return { utxos: selectedUtxos, isExact: currentSum === amount };
      }
      if (currentIndex >= sortedDuneUtxos.length) {
        // No more UTXOs to check
        return { utxos: selectedUtxos, isExact: false };
      }

      // Try including the current UTXO
      const withCurrent = findCombination(
        currentIndex + 1,
        currentSum + sortedDuneUtxos[currentIndex].dunes.balance,
        [...selectedUtxos, sortedDuneUtxos[currentIndex]],
      );
      if (withCurrent.utxos) {
        return withCurrent;
      }

      // Try excluding the current UTXO
      return findCombination(currentIndex + 1, currentSum, selectedUtxos);
    };

    // Find exact or the smallest amount greater than or equal to the desired amount
    const combination = findCombination(0, 0, []);
    utxosUsedForTxs = combination.utxos;
    isExact = combination.isExact;
  }

  let finalUtxo: Utxo;

  const parsedUtxos: Utxo[] = utxosUsedForTxs.map((utxo) => {
    return {
      txid: utxo.txid,
      vout: utxo.vout,
      script: utxo.script,
      satoshis: utxo.satoshis,
    };
  });

  if (isExact && parsedUtxos.length == 1) {
    finalUtxo = parsedUtxos[0];
    if (isListing) {
      return finalUtxo as any;
    }
  } else if (isExact && parsedUtxos.length > 1) {
    // Build transaction
    const tx = new Transaction();
    parsedUtxos.map((utxo) => {
      tx.from(utxo as Utxo);
    });
    tx.to(wallet.address);
    // Fund & sign
    const { tx: fundedTx } = fundWallet(
      wallet,
      tx,
      LOW_NETWORK_FEE_RATE,
      false,
    );
    if (!estimateFeesOnly) {
      wallet.updateUtxos({ tx: fundedTx });
    }

    finalUtxo = {
      txid: fundedTx.hash,
      vout: 0,
      script: fundedTx.outputs[0].script.toHex(),
      satoshis: 100_000,
    };

    txs.push(fundedTx);
  } else {
    // Build transaction
    const tx = new Transaction();
    parsedUtxos.map((utxo) => {
      tx.from(utxo as Utxo);
    });
    // Define default output where the sender receives unallocated dunes
    const DEFAULT_OUTPUT: any = 1;
    // Define output offset for receivers of dunes
    const OFFSET = 2;
    try {
      // parse given id string to dune id
      const parsedDuneId = parseDuneId(duneId);
      /**
       * we have an index-offset of 2
       * - the first output (index 0) is the protocol message
       * - the second output (index 1) is where we put the dunes which are on input utxos which shouldn't be transfered
       * */
      const edicts = [];
      edicts.push(new Edict(parsedDuneId, amount, OFFSET));

      // Create payload and parse it into an OP_RETURN script with protocol message
      const script = constructScript(DEFAULT_OUTPUT, null, edicts);

      // Add output with OP_RETURN Dune assignment script
      tx.addOutput(
        new dogecore.Transaction.Output({ script: script, satoshis: 0 }),
      );
    } catch (error) {
      console.error("Error fetching or parsing data:", error);
      throw error;
    }

    // add one output to the sender for the dunes that are not transferred
    tx.to(wallet.address, 100_000);

    // add one output to the sender for the dunes that will be transferred
    tx.to(wallet.address, 100_000);

    // Fund & sign
    const { tx: fundedTx } = fundWallet(
      wallet,
      tx,
      LOW_NETWORK_FEE_RATE,
      false,
    );
    if (!estimateFeesOnly) {
      wallet.updateUtxos({ tx: fundedTx });
    }

    finalUtxo = {
      txid: fundedTx.hash,
      vout: 2,
      script: fundedTx.outputs[2].script.toHex(),
      satoshis: 100_000,
    };

    txs.push(fundedTx);
  }

  // if it is listing, we don't want the send tx
  if (isListing || !receiverAddress) return txs;

  // create send tx
  const sendTx = new Transaction();
  sendTx.from(finalUtxo);
  sendTx.to(receiverAddress, 100000);
  fundWallet(wallet, sendTx, feePerVByte, false);

  txs.push(sendTx);
  return txs;
};
// SDOGGS enhancements end

const getTotalTransactionFeeInSats = (
  tx: any,
  networkFeeInSats: number,
): number => {
  console.log("getTotalTransactionFeeInSats - tx", tx);
  return tx.vsize * networkFeeInSats;
};

type ToSignInput = {
  index: number;
  publicKey: string; // Public key in hexadecimal format
  sighashTypes?: number[];
};

type SignPsdtOptions = {
  autoFinalized?: boolean;
  isHex?: boolean;
  isSeller?: boolean;
};

const signPsbt = (
  psbtHex: string,
  wallet: WalletForTx | TxWallet,
  options?: SignPsdtOptions,
) => {
  const psbtNetwork = dogecoinNetwork;
  const psbt = bitcoin.Psbt.fromHex(psbtHex);

  const toSignInputs: ToSignInput[] = [];
  psbt.data.inputs.forEach((v, index) => {
    let script: any = null;
    // let value = 0;

    if (v.witnessUtxo) {
      script = v.witnessUtxo.script;
      // value = v.witnessUtxo.value;
    } else if (v.nonWitnessUtxo) {
      const tx = bitcoin.Transaction.fromBuffer(v.nonWitnessUtxo);
      const output = tx.outs[psbt.txInputs[index].index];
      script = output.script;
      // value = output.value;
    }
    const isSigned = v.finalScriptSig || v.finalScriptWitness;
    if (script && !isSigned) {
      const address = bitcoin.address.fromOutputScript(script, psbtNetwork);
      if (wallet.address === address) {
        toSignInputs.push({
          index,
          publicKey: wallet.pubKey,
          sighashTypes: v.sighashType ? [v.sighashType] : undefined,
        });
      }
    }
  });

  // create a privateKey as buffer
  const ECPair = ECPairFactory(ecc);
  const decodedPrivateKey = ECPair.fromWIF(wallet.privKey, psbtNetwork);
  const privateKeyBuffer = decodedPrivateKey.privateKey;
  if (!privateKeyBuffer) {
    throw new Error("Invalid private key");
  }

  // create a signer object
  const signer = {
    publicKey: ECPair.fromPrivateKey(privateKeyBuffer).publicKey,
    sign: (hash: Buffer) => {
      const signature = ECPair.fromPrivateKey(privateKeyBuffer).sign(hash);
      return signature;
    },
  };

  if (!options?.isSeller) {
    // sign inputs
    toSignInputs.forEach((input) => {
      psbt.signInput(input.index, signer, input.sighashTypes);
    });
  } else {
    psbt.signInput(2, signer, [
      bitcoin.Transaction.SIGHASH_SINGLE |
        bitcoin.Transaction.SIGHASH_ANYONECANPAY,
    ]);
  }

  // finalize tx
  if (options?.autoFinalized) {
    toSignInputs.forEach((v) => {
      psbt.finalizeInput(v.index);
    });
  }
  if (options?.isHex) {
    return psbt.toHex();
  }
  return psbt;
};

export {
  bufferToChunk,
  numberToChunk,
  opcodeToChunk,
  broadcast,
  broadcastAll,
  fundWallet,
  createDrc20Txs,
  createDuneTxs,
  getTotalTransactionFeeInSats,
  signPsbt,
};
