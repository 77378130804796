import React from "react";

import { AnimatedClickable } from "@/components";

export interface BalanceProps {
  value: string;
  icon?: string;
  onClick: () => void;
}

export const Balance: React.FC<BalanceProps> = ({ value, icon, onClick }) => {
  return (
    <AnimatedClickable
      className="flex h-8 w-16 flex-shrink-0 select-none flex-row items-center justify-center space-x-1 rounded-md text-xs font-semibold text-text-primary"
      onClick={onClick}
    >
      {icon && <span>{icon}</span>}
      <span
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    </AnimatedClickable>
  );
};
