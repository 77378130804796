import { useEffect, useMemo, useState } from "react";

import { useOperatingSystem } from "./useOperatingSystem";

const PWA_LOCAL_STORAGE_KEY = "pwa";

export const useShowInstallMessage = () => {
  const { isDesktop, isMobile } = useOperatingSystem();
  const [showInstallMessage, setShowInstallMessage] = useState(false);

  const isInStandaloneMode = useMemo(
    () =>
      window.matchMedia("(display-mode: standalone)").matches ||
      ("standalone" in window.navigator && window.navigator.standalone),
    [],
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get(PWA_LOCAL_STORAGE_KEY) === "1") {
      localStorage.setItem(PWA_LOCAL_STORAGE_KEY, "1");
    }
  }, []);

  useEffect(() => {
    if (isDesktop) return;

    const isInStandaloneOnIos = isMobile && isInStandaloneMode;

    if (!isInStandaloneOnIos && !localStorage.getItem(PWA_LOCAL_STORAGE_KEY)) {
      setShowInstallMessage(true);
    }
  }, [isMobile, isDesktop, isInStandaloneMode]);

  return showInstallMessage;
};
