import React from "react";

import { AnimatedClickable, Button } from "@/components";
import { ONE_DOGE_IN_SHIBES } from "@/constants";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { cn } from "@/lib/utils";
import { Currency } from "@/types";

import { AnimatedContent } from "../../base";

type NetworkFeeCardProps = {
  handleToggle: () => void;
  isToggled: boolean;
  label: string;
  detail: string;
  feeRatePerVb: number;
  totalFee: number;
};

interface FeeViewProps {
  handleContinue: () => void;
  items: NetworkFeeCardProps[];
}

const FeeView: React.FC<FeeViewProps> = ({ handleContinue, items }) => (
  <AnimatedContent id="fee">
    <div className="relative flex flex-1 flex-row  space-x-2">
      {items?.map((item, index) => <NetworkFeeCard key={index} {...item} />)}
    </div>
    <Button size="large" variant="default" onClick={handleContinue}>
      Continue
    </Button>
  </AnimatedContent>
);

const NetworkFeeCard: React.FC<NetworkFeeCardProps> = ({
  handleToggle,
  isToggled,
  label,
  detail,
  feeRatePerVb,
  totalFee,
}) => {
  return (
    <AnimatedClickable
      whileTap={{ scale: 0.95 }}
      onClick={handleToggle}
      className={cn(
        "flex flex-1 cursor-pointer flex-col rounded-md bg-background-secondary p-4 text-text-primary",
        isToggled && "bg-background-tertiary",
      )}
    >
      <div className="flex flex-1 flex-col space-y-1">
        <span className="text-lg font-bold">{label}</span>
        <span className="text-2xs text-text-secondary">{detail}</span>
        <span
          className="text-2xs text-text-secondary"
          dangerouslySetInnerHTML={{
            __html: `${formatNumber({
              value: feeRatePerVb / ONE_DOGE_IN_SHIBES,
              type: NumberFormatType.Price,
            })} ${Currency.DOGE}/vB`,
          }}
        />

        <span className="text-2xs text-text-secondary">
          {`Total: ${Currency.DOGE} ${formatNumber({ value: totalFee / ONE_DOGE_IN_SHIBES, type: NumberFormatType.Price })}`}
        </span>
      </div>
      <div
        className={cn(
          "rounded-full bg-background-tertiary px-1 py-2 text-center text-xs font-medium",
          isToggled && "bg-white text-background-primary",
        )}
      >
        {isToggled ? "Selected" : "Select"}
      </div>
    </AnimatedClickable>
  );
};

export { FeeView };
export type { NetworkFeeCardProps };
