import { debounce } from "lodash";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { PAGINATION_LIMIT } from "@/constants.ts";
import { fetchDoginalTokens } from "@/context/helpers/fetchDoginalTokens.ts";
import { useCurrency, useDogePrice, useRefreshKey } from "@/contextHooks";
import { marketplaceApiV2 } from "@/lib/fetch";
import {
  formatLargeNumber,
  formatNumber,
  NumberFormatType,
} from "@/lib/numbers";
import {
  CollectionActivityData,
  CollectionData,
  CollectionSale,
  CollectionSales,
  Currency,
  Sorting,
  SortOrder,
  TimeFrames,
} from "@/types";
import { FetchActivityData, WatchlistItem } from "@/types/watchlist.ts";
import {
  calculateTokenValueInDoge,
  calculateTokenValueInUSD,
  ensureUniqueTableData,
  handleError,
} from "@/utility";

import {
  DoginalWatchlistContext,
  WatchlistProvider,
} from "@/context/DoginalWatchListContext.ts";

// todo: move to a hook
const fetchSalesData = async (params: FetchActivityData) => {
  try {
    const paramsToSend = {
      offset: 0,
      limit: 20,
      sortOrder: "desc",
      sortParam: "top",
      action: "sale",
      collectionSymbol: params.filter ? params.filter : "",
      currency: Currency.USD,
      ...params,
    };

    const response = await marketplaceApiV2(false).get<CollectionSales>(
      "offer/doginals/activity",
      {
        params: paramsToSend,
      },
    );
    return response?.data?.activityList || [];
  } catch (e: Error | unknown) {
    handleError(e);
    return [];
  }
};

const sortActivityData = <T extends keyof CollectionData>(
  collection: CollectionData[],
  sortParam: T,
) => {
  if (collection.length === 0) {
    return collection;
  }

  if (typeof collection[0][sortParam] !== "number") {
    throw new Error(
      `${sortParam} must be a key of CollectionData with a numeric value`,
    );
  }

  return collection.sort(
    (a, b) =>
      Math.abs((b[sortParam] as number) || 0) -
      Math.abs((a[sortParam] as number) || 0),
  );
};

const sortSalesData = (data: CollectionSale[]) => {
  if (data.length === 0) {
    return data;
  }

  return data.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );
};

const convertPrice = (
  price: string,
  currency: Currency,
  dogePrice: number,
): string => {
  if (!price || !dogePrice || isNaN(dogePrice)) {
    return "";
  }

  const value = parseFloat(price.slice(1));
  if (isNaN(value)) {
    return "";
  }

  if (currency === Currency.DOGE) {
    const dogeValue = value / dogePrice;
    const dogeValueFormatted = formatNumber({
      value: dogeValue,
      type: NumberFormatType.Price,
    });

    return `${Currency.DOGE}${dogeValueFormatted}`;
  } else if (currency === Currency.USD) {
    const usdValue = value * dogePrice;
    const usdValueFormatted = formatNumber({
      value: usdValue,
      type: NumberFormatType.Price,
    });

    return `${Currency.USD}${usdValueFormatted}`;
  }

  return "";
};

/*
 This context contains data about doginal (aka collectible) collections.
 - Difference from CollectiblesDetailsContext: focused on collections' general details, whereas CollectiblesDetailsContext is more about listings and activities inside a collection.
 - Data Used Where?
    - The collectibles table on /trading
    - Collectible top movers section on /trading
    - Collection Details Section at the top on collectible/:collectionSymbol
 The data we fetch on collectible/:collectionSymbol has the exact same structure as table data on /trading. But because the table is pagination sensitive, we store and mutate the data around the table seperately inside "tableData".
 In order to save API calls, when switching between collection pages, we store the data of the collections that are already fetched in "alreadyFetchedCollectionData". Whenever we go to another collection page we first try to load it from there or the tableData, before making a new API call.
*/

type DoginalCollectionListContextType = {
  // table data
  isLoadingTableData: boolean;
  isLoadingMoreTableData: boolean;
  hasMoreTableData: boolean;
  tableData: CollectionData[];
  tableOffset: number;
  debouncedSetTableOffset: (offset: number) => void;
  loadTableData: (params?: {
    history?: TimeFrames;
    sortParam?: Sorting;
    currency?: Currency;
    limit?: number;
    collectionSymbol?: string;
  }) => void;
  getCurrentCollectionData: (symbol: string) => Promise<void>;
  currentCollectionData: CollectionData | undefined;
  isLoadingCurrentCollectionData: boolean;
  // top movers and recent sales
  isMoversLoading: boolean;
  topMovers: CollectionActivityData[];
  topSales: CollectionActivityData[];
  recentSales: CollectionActivityData[];
};

type DoginalCollectionListProviderProps = {
  children: ReactNode;
};

const DoginalCollectionListContext =
  createContext<DoginalCollectionListContextType>({
    // table data
    isLoadingTableData: false,
    isLoadingMoreTableData: false,
    isLoadingCurrentCollectionData: false,
    hasMoreTableData: true,
    tableData: [],
    tableOffset: 0,
    debouncedSetTableOffset: () => {},
    loadTableData: () => {},
    getCurrentCollectionData: async () => undefined,
    currentCollectionData: undefined,

    // top movers and recent sales
    isMoversLoading: false,
    topMovers: [],
    topSales: [],
    recentSales: [],
  });

const mapCollectionToActivityData = (
  data: CollectionData,
  dogePrice: number,
): WatchlistItem => {
  const {
    collectionSymbol: id,
    sales = 0,
    floorPrice = 0,
    imageURI,
    description,
    supply,
  } = data;

  const formattedId = id.toUpperCase();
  const priceInDOGE = calculateTokenValueInDoge(1, floorPrice);
  const priceInUSD = calculateTokenValueInUSD(1, floorPrice, dogePrice);
  const percentage = formatNumber({
    value: 0,
    type: NumberFormatType.Percentage,
  });

  const totalSales = formatNumber({
    value: sales,
    type: NumberFormatType.Large_Number,
  });

  return {
    sales: totalSales,
    id: formattedId,
    title: formattedId,
    description,
    percentage: percentage,
    value: `${Currency.USD}${priceInUSD}`,
    priceInUSD: `${Currency.USD}${priceInUSD}`,
    priceInDOGE: `${Currency.DOGE}${priceInDOGE}`,
    imageURI,
    mcapInUSD:
      supply > 0 && parseFloat(priceInUSD) > 0
        ? `$${formatLargeNumber(parseFloat(priceInUSD) * supply)}`
        : "",
  };
};

const DoginalCollectionListProvider = ({
  children,
}: DoginalCollectionListProviderProps) => {
  const { currency } = useCurrency();
  const { dogePrice, loading: isLoadingDogePrice } = useDogePrice();

  const [loading, setLoading] = useState(false);
  const [fetchedInitially, setFetchedInitially] = useState(false);

  const [collectionTopMovers, setCollectionTopMovers] = useState<
    CollectionActivityData[]
  >([]);
  const [collectionTrending, setCollectionTrending] = useState<
    CollectionActivityData[]
  >([]);
  const [collectionRecentSales, setCollectionRecentSales] = useState<
    CollectionActivityData[]
  >([]);
  const [tableData, setTableData] = useState<CollectionData[]>([]);
  const [alreadyFetchedCollectionData, setAlreadyFetchedCollectionData] =
    useState<CollectionData[]>([]);
  const [currentCollectionData, setCurrentCollectionData] = useState<
    CollectionData | undefined
  >(undefined);
  const [tableOffset, setTableOffset] = useState<number>(0);
  const [hasMoreTableData, setHasMoreTableData] = useState<boolean>(true);
  const [isLoadingCurrentCollectionData, setIsLoadingCurrentCollectionData] =
    useState<boolean>(false);
  const [isLoadingTableData, setIsLoadingTableData] = useState<boolean>(false);
  const [isLoadingMoreTableData, setIsLoadingMoreTableData] =
    useState<boolean>(false);

  const { refreshKey } = useRefreshKey();

  const getCurrentCollectionData = useCallback(
    async (symbol: string) => {
      // check if CollectionData with this symbol exists in the table data
      let collection = tableData.find(
        (collection) => collection.collectionSymbol === symbol,
      );
      // if it does not, look inside alreadyFetchedCollectionData
      if (!collection) {
        collection = alreadyFetchedCollectionData.find(
          (collection) => collection.collectionSymbol === symbol,
        );
      }
      // if it is not found, fetch it and add it to alreadyFetchedCollectionData
      if (!collection) {
        try {
          setIsLoadingCurrentCollectionData(true);
          const res = await fetchDoginalTokens({
            collectionSymbol: symbol,
            currency: currency,
            offset: 0,
            limit: 1,
            history: TimeFrames.ALL,
            cachebreaker: true,
          });

          collection = res?.[0];

          if (collection) {
            setAlreadyFetchedCollectionData([
              ...alreadyFetchedCollectionData,
              collection,
            ]);
          }
        } catch (e: Error | unknown) {
          handleError(e);
        } finally {
          setIsLoadingCurrentCollectionData(false);
        }
      }
      setCurrentCollectionData(collection);
    },
    [alreadyFetchedCollectionData, currency, tableData],
  );

  const loadTableData = useCallback(
    async (params = {}) => {
      if (isLoadingDogePrice) return;

      const { ...fetchParams } = {
        // default params
        history: TimeFrames.ALL,
        // sortParam: Sorting.volume30d,
        sortParam: Sorting.top,
        currency: Currency.USD,
        limit: PAGINATION_LIMIT,
        // passed params
        ...params,
      };

      if (!hasMoreTableData) {
        return;
      }

      try {
        setIsLoadingMoreTableData(true);
        const newData = await fetchDoginalTokens({
          action: "sale",
          offset: tableOffset,
          ...fetchParams,
          dogePrice,
        });

        setHasMoreTableData(newData.length === PAGINATION_LIMIT);
        setTableData(ensureUniqueTableData(newData, "collectionSymbol"));
      } catch (e: Error | unknown) {
        handleError(e);
      } finally {
        setIsLoadingMoreTableData(false);
        setIsLoadingTableData(false);
      }
    },

    [dogePrice, hasMoreTableData, isLoadingDogePrice, tableOffset],
  );

  const debouncedSetTableOffset = debounce((offset: number) => {
    setTableOffset(offset);
  }, 500);

  const processTrendingData = useCallback((data: CollectionData[]) => {
    const trendingData = [...data];

    const sortedCollectionSevenDays = sortActivityData(trendingData, "sales");

    const mappedTrending: CollectionActivityData[] =
      sortedCollectionSevenDays.map((collection) => ({
        id: collection.collectionSymbol,
        title: collection.name,
        description: collection.description,
        image: collection.imageURI,
        valuePrimary: `${formatNumber({
          value: collection.sales,
          type: NumberFormatType.Large_Number,
        })}`,
      }));

    setCollectionTrending(mappedTrending);
  }, []);

  const processTopMoverData = useCallback(
    (data: CollectionData[]) => {
      const activityData = [...data];
      const sortedCollectionsThirtyDays = sortActivityData(
        activityData,
        "sales",
      );

      const mappedTopMovers: CollectionActivityData[] =
        sortedCollectionsThirtyDays.map((collection) => {
          const {
            name,
            symbol,
            imageURI,
            description,
            floorPrice = 0,
            change24h,
            change7d,
            change30d,
          } = collection;

          const priceDOGE = calculateTokenValueInDoge(1, floorPrice);
          const priceUSD = calculateTokenValueInUSD(1, floorPrice, dogePrice);
          const price = currency === Currency.DOGE ? priceDOGE : priceUSD;

          const allChanges = [change24h, change7d, change30d];
          const changesDays = ["24h", "7D", "30D"];
          const recentValidChangeIndex =
            allChanges.findIndex((v) => (v ? v > 0 : false)) ?? 0;
          const recentValidChange = allChanges[recentValidChangeIndex] ?? 0;
          const recentValidChangeDay =
            changesDays[recentValidChangeIndex] ?? "30D";

          return {
            id: symbol,
            title: name,
            description,
            image: imageURI,
            valuePrimary: `${currency}${price}`,
            valueSecondary: formatNumber({
              value: recentValidChange,
              type: NumberFormatType.Percentage,
            }),
            valueSecondarySuffix: recentValidChangeDay,
          };
        });

      setCollectionTopMovers(mappedTopMovers);
    },
    [currency, dogePrice],
  );

  const processRecentSalesData = useCallback(
    (data: CollectionSale[]) => {
      const salesData = [...data];
      const sortedData = sortSalesData(salesData);

      const mappedRecentSales: CollectionActivityData[] = sortedData.map(
        (collection) => {
          const {
            collectionSymbol,
            price,
            inscriptionId,
            doginalName,
            imageURI,
            description,
          } = collection;
          const priceDOGE = calculateTokenValueInDoge(1, price);
          const priceUSD = calculateTokenValueInUSD(1, price, dogePrice);
          const value = currency === Currency.DOGE ? priceDOGE : priceUSD;

          return {
            id: inscriptionId,
            collectionSymbol,
            title: doginalName,
            description,
            image: imageURI,
            valuePrimary: `${currency}${value}`,
          };
        },
      );

      setCollectionRecentSales(mappedRecentSales);
    },
    [currency, dogePrice],
  );

  const fetchActivity = useCallback(
    async (isInitial: boolean = false, cachebreaker: boolean = false) => {
      try {
        setLoading(true);

        const collectionsSevenDays = isInitial
          ? tableData
          : await fetchDoginalTokens({
              action: "sale",
              offset: 0,
              limit: 20,
              sortOrder: SortOrder.Descending,
              sortParam: Sorting.top,
              currency: currency,
              history: TimeFrames["7d"],
              cachebreaker,
            });
        const collectionsThirtyDays = isInitial
          ? tableData
          : await fetchDoginalTokens({
              action: "sale",
              offset: 0,
              limit: 20,
              sortOrder: SortOrder.Descending,
              sortParam: Sorting.top,
              currency: currency,
              history: TimeFrames["30d"],
              cachebreaker,
            });
        const collectionsRecentSales = await fetchSalesData({
          history: TimeFrames["30d"],
        });

        processTrendingData(collectionsSevenDays);
        processTopMoverData(collectionsThirtyDays);
        processRecentSalesData(collectionsRecentSales);

        setFetchedInitially(true);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [
      tableData,
      currency,
      processTrendingData,
      processTopMoverData,
      processRecentSalesData,
    ],
  );

  const intervalId = useRef<null | NodeJS.Timeout>(null);
  useEffect(() => {
    if (tableData.length === 0) return;

    if (!fetchedInitially) {
      fetchActivity(true);
    }

    if (fetchedInitially) {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }

      intervalId.current = setInterval(fetchActivity, 1800000);

      return () => clearInterval(intervalId.current as NodeJS.Timeout);
    }
  }, [tableData, fetchedInitially, fetchActivity]);

  useEffect(() => {
    setCollectionTopMovers((prev) =>
      prev.map((m) => ({
        ...m,
        valuePrimary: convertPrice(m.valuePrimary, currency, dogePrice),
      })),
    );

    setCollectionRecentSales((prev) =>
      prev.map((m) => ({
        ...m,
        valuePrimary: convertPrice(m.valuePrimary, currency, dogePrice),
      })),
    );
  }, [currency, dogePrice]);

  // fetch data whenever offset changes -> endless scroll
  useEffect(() => {
    const fetchData = async () => {
      await loadTableData({ cachebreaker: false });
    };

    fetchData();
  }, [tableOffset, loadTableData]);

  useEffect(() => {
    if (refreshKey > 0) {
      setTableData([]);
      setTableOffset(0);
      loadTableData({ offset: 0, cachebreaker: false });
      fetchActivity(false, false);
    }
  }, [refreshKey]);

  return (
    <DoginalCollectionListContext.Provider
      value={{
        isLoadingCurrentCollectionData,
        isLoadingTableData,
        isLoadingMoreTableData: !isLoadingTableData && isLoadingMoreTableData,
        hasMoreTableData,
        tableData,
        tableOffset,
        debouncedSetTableOffset,
        loadTableData,
        getCurrentCollectionData,
        currentCollectionData,
        isMoversLoading: loading,
        recentSales: collectionRecentSales,
        topMovers: collectionTopMovers,
        topSales: collectionTrending,
      }}
    >
      <WatchlistProvider
        watchlistName="watchlist-doginals"
        fetchTokens={fetchDoginalTokens}
        mapTokenToItem={mapCollectionToActivityData}
        watchlistKey="collectionSymbol"
        sortParam="sales"
        Context={DoginalWatchlistContext}
      >
        {children}
      </WatchlistProvider>
    </DoginalCollectionListContext.Provider>
  );
};

export { DoginalCollectionListProvider, DoginalCollectionListContext };
