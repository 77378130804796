import { Link } from "react-router-dom";

import { shortenAddress } from "@/lib/address";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { Owner } from "@/types";
import { ColumnDef } from "@tanstack/react-table";

import { HeaderCell, NumberCell, TextCell } from "../components";

export const TableContentOwners: ColumnDef<Owner>[] = [
  {
    accessorKey: "rank",
    header: () => {
      return <HeaderCell title="#" aligned="left" className="w-10 pl-4" />;
    },
    cell: ({ row }) => {
      const rank = row.getValue("rank")?.toString() || "";
      return <TextCell value={rank} className="w-10 pl-4 text-left" />;
    },
  },
  {
    accessorKey: "address",
    header: () => <HeaderCell title="Wallet" aligned="left" className="w-24" />,
    cell: ({ row }) => {
      const value = row.getValue("address") as string;
      return (
        <Link to={`/wallet/${value}`}>
          <TextCell value={shortenAddress(value)} className="w-24 text-left" />
        </Link>
      );
    },
  },
  {
    accessorKey: "amount",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Amount"
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { amount } = row.original;
      const formattedAmount = formatNumber({
        value: amount,
        type: NumberFormatType.Large_Number,
      });
      return <NumberCell value={formattedAmount} className="w-24" />;
    },
  },
  {
    accessorKey: "share",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Share"
          aligned="right"
          column={column}
          withSorting
          className="w-full"
        />
      );
    },
    cell: ({ row }) => {
      const value = row.getValue("share") as string;
      const share = parseFloat(value);
      return (
        <div className="flex flex-col items-end space-y-1">
          <TextCell
            value={formatNumber({
              value: share,
              type: NumberFormatType.Percentage,
            })}
            className="w-24"
          />

          <div className="h-0.5 w-5/6 rounded-full bg-background-tertiary md:h-1.5">
            <div
              className="h-full rounded-full bg-green-600"
              style={{ width: `${share}%` }}
            />
          </div>
        </div>
      );
    },
  },
];
