import {
  WALLET_API,
  WONKY_ORD_API,
  TX_BROADCASTER,
  MARKETPLACE_API_V2,
  D20_API_V2,
  SDOGGS_API_V2,
} from "@/constants";
import axios, { AxiosInstance } from "axios";
import axiosRetry from "axios-retry";

const axiosRetryOptions = {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
};

axiosRetry(axios, axiosRetryOptions);

const timeout = 100_000;
const createAxiosInstance = (
  baseURL: string,
  axiosParams: object = {},
  cacheBreaker: boolean = true,
): AxiosInstance => {
  const params = cacheBreaker
    ? { ...axiosParams, t: new Date().getTime() }
    : axiosParams;
  return axios.create({
    baseURL,
    timeout,
    params,
  });
};

const wonkyOrdApi = (
  cacheBreaker: boolean = true,
  additionalParams: object = {},
): AxiosInstance => {
  return createAxiosInstance(
    WONKY_ORD_API,
    additionalParams,
    cacheBreaker || import.meta.env.DEV,
  );
};

const walletApi = (
  cacheBreaker: boolean = true,
  additionalParams: object = {},
): AxiosInstance => {
  return createAxiosInstance(WALLET_API, additionalParams, cacheBreaker);
};

const marketplaceApiV2 = (
  cacheBreaker: boolean = true,
  additionalParams: object = {},
): AxiosInstance => {
  return createAxiosInstance(
    MARKETPLACE_API_V2,
    additionalParams,
    cacheBreaker,
  );
};

const broadcasterApi = (
  cacheBreaker: boolean = true,
  additionalParams: object = {},
): AxiosInstance => {
  return createAxiosInstance(TX_BROADCASTER, additionalParams, cacheBreaker);
};

const d20ApiV2 = (
  cacheBreaker: boolean = true,
  additionalParams: object = {},
): AxiosInstance => {
  return createAxiosInstance(D20_API_V2, additionalParams, cacheBreaker);
};

const sdoggsApiV2 = (
  cacheBreaker: boolean = true,
  additionalParams: object = {},
): AxiosInstance => {
  return createAxiosInstance(SDOGGS_API_V2, additionalParams, cacheBreaker);
};

export {
  walletApi,
  wonkyOrdApi,
  marketplaceApiV2,
  broadcasterApi,
  d20ApiV2,
  sdoggsApiV2,
};
