import { useCallback, useEffect, useMemo, useState } from "react";

import { ONE_DOGE_IN_SHIBES } from "@/constants.ts";
import { Balance } from "@/context/BalanceProvider.tsx";
import { EMPTY_BALANCE } from "@/context/balance";
import { fetchDogeBalance } from "@/context/helpers/fetchDogeBalance.ts";
import { fromDogeToUSD } from "@/context/helpers/fetchDogePrice.ts";
import { useDogePrice } from "@/contextHooks";
import { NumberFormatType, formatNumber } from "@/lib/numbers.ts";
import { Currency, DogeUtxo } from "@/types";
import { handleError } from "@/utility";

type UseDogeBalanceOfAddressReturn = {
  balanceInUSD: string;
  balanceInDOGE: string;
  isLoading: boolean;
  dogeBalance: Balance;
  safeUtxos: DogeUtxo[];
  getDogeBalanceOfAddress: () => Promise<void>;
};

// this hook differs from BalanceProvider in that it only fetches the balance of a single address
// and contains less data
const useDogeBalanceOfAddress = (
  address?: string,
): UseDogeBalanceOfAddressReturn => {
  const { dogePrice } = useDogePrice();
  const [dogeBalance, setDogeBalance] = useState<Balance>(EMPTY_BALANCE);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [safeUtxos, setSafeUtxos] = useState<DogeUtxo[]>([]);

  const getDogeBalanceOfAddress = useCallback(async () => {
    if (!address) return;

    setIsLoading(true);
    let balance = EMPTY_BALANCE;
    try {
      const dogeBalanceResponse = await fetchDogeBalance(address);
      const { utxos, total_shibes, total_utxos } = dogeBalanceResponse;

      if (utxos.length > 0 && total_shibes > 0) {
        balance = { ...balance, utxos, total_shibes, total_utxos };
      }
    } catch (e: Error | unknown) {
      handleError(e);
    } finally {
      setIsLoading(false);
    }

    const dogeBalanceInUsd = fromDogeToUSD(
      balance.total_shibes / ONE_DOGE_IN_SHIBES,
      dogePrice,
    );

    setDogeBalance({ ...balance, dogeBalanceInUsd });
    setSafeUtxos(balance.utxos);
  }, [address, dogePrice]);

  useEffect(() => {
    getDogeBalanceOfAddress();
  }, [getDogeBalanceOfAddress]);

  const { balanceInUSD, balanceInDOGE } = useMemo(() => {
    const formattedUSD = `${Currency.USD}${formatNumber({ value: dogeBalance.dogeBalanceInUsd, type: NumberFormatType.Price, decimalPlaces: 2 })}`;
    const formattedDOGE = `${Currency.DOGE}${formatNumber({ value: dogeBalance.total_shibes / ONE_DOGE_IN_SHIBES, type: NumberFormatType.Price, decimalPlaces: 2 })}`;

    return {
      balanceInUSD: formattedUSD,
      balanceInDOGE: formattedDOGE,
    };
  }, [dogeBalance.dogeBalanceInUsd, dogeBalance.total_shibes]);

  return {
    balanceInUSD,
    balanceInDOGE,
    dogeBalance,
    isLoading,
    safeUtxos,
    getDogeBalanceOfAddress,
  };
};

export { useDogeBalanceOfAddress };
