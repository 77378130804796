import { useCallback, useMemo } from "react";

import { cn } from "@/lib/utils";
import { getColorForChange, getColorForValue } from "@/utility";

import { AnimatedClickable } from "../animatedClickable";
import { ImageWithFallback } from "../imageWithFallback";
import { BalanceRowDuneProps } from "./type";
import { useNavigate } from "react-router-dom";
import { formatLargeNumber } from "@/lib/numbers";
import { ChangeIndicator } from "@/routes/Trading/components/changeIndicator";

export const BalanceRowDune: React.FC<BalanceRowDuneProps> = ({
  imageURI,
  name,
  tick,
  amount,
  value,
  valueChangeAbsolute,
  price,
  priceChange24H,
  priceChange7D,
  volume7D,
  onClick,
  colorForBalanceChange,
}) => {
  const navigate = useNavigate();

  // Click on image should link to drc20 overview
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation(); // Prevent the event from bubbling up to the parent
      navigate(`/dune/${tick.toLowerCase()}`);
    },
    [navigate, tick],
  );

  const { colorPriceChange24H, colorPriceChange7D, colorPrice, colorVolume7D } =
    useMemo(() => {
      return {
        colorPriceChange24H: getColorForChange(priceChange24H),
        colorPriceChange7D: getColorForChange(priceChange7D),
        colorPrice: getColorForValue(price),
        colorVolume7D: getColorForValue(volume7D),
      };
    }, [price, priceChange24H, priceChange7D, volume7D]);

  const formattedAmount = useMemo(() => {
    return `${formatLargeNumber(amount)}`;
  }, [amount]);

  return (
    <AnimatedClickable
      onClick={onClick}
      whileHover={{ scale: 1.0 }}
      className="flex flex-row space-x-2 rounded-lg bg-background-tertiary/50 p-4 hover:bg-background-tertiary"
    >
      <div className="flex w-1/3 flex-row items-center space-x-3">
        <AnimatedClickable
          onClick={handleClick}
          className="flex flex-row overflow-hidden"
          whileHover={{ scale: 1.1 }}
        >
          <ImageWithFallback image={imageURI} className="h-11 w-11" />
        </AnimatedClickable>

        <div className="flex flex-col">
          <span className="text-md font-bold uppercase text-text-primary">
            {name}
          </span>

          <div className="flex flex-row space-x-2">
            <span className="py-0.5 text-sm text-text-tertiary">
              {formattedAmount}
            </span>
          </div>
        </div>
      </div>
      <div className="flex w-2/3 flex-row space-x-2">
        <div className="flex flex-1 flex-col justify-center">
          <span
            className={cn(
              "text-right text-sm font-medium uppercase text-text-primary",
              colorPrice,
            )}
            dangerouslySetInnerHTML={{ __html: price ?? "-" }}
          />
        </div>

        <div className="flex flex-1 flex-col justify-center">
          <ChangeIndicator
            showEmptyIndicator
            change={priceChange24H}
            className="justify-end text-right text-sm font-medium uppercase"
          />
        </div>

        <div className="flex flex-1 flex-col justify-center">
          <ChangeIndicator
            showEmptyIndicator
            change={priceChange7D}
            className="justify-end text-right text-sm font-medium uppercase"
          />
        </div>

        <div className="flex flex-1 flex-col justify-center">
          <span
            className={cn(
              "text-right text-sm font-medium uppercase text-text-primary",
              colorVolume7D,
            )}
            dangerouslySetInnerHTML={{ __html: volume7D ?? "-" }}
          />
        </div>

        <div className="flex flex-1 flex-col items-end justify-center">
          <span
            className="text-lg font-bold text-text-primary"
            dangerouslySetInnerHTML={{ __html: value }}
          />
          {valueChangeAbsolute !== "0" && (
            <ChangeIndicator
              change={valueChangeAbsolute}
              className="text-right text-sm font-medium uppercase"
            />
          )}
        </div>
      </div>
    </AnimatedClickable>
  );
};
