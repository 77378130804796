import "./App.css";

import { useMemo } from "react";

import { Toaster } from "@/components";
import { DoginalCollectionListProvider } from "@/context/DoginalCollectionListProvider.tsx";
import {
  useLockOrientation,
  useOperatingSystem,
  useShowInstallMessage,
} from "@/hooks";
import { ModalInstall, ModalOrientation } from "@/modals";
import { useOrientation } from "@uidotdev/usehooks";

import { AppRouter } from "./AppRouter";
import {
  BalanceProvider,
  CollectiblePendingItemsProvider,
  CollectiblesDetailsProvider,
  ContentProvider,
  CurrencyProvider,
  DogePriceProvider,
  Drc20TokenListProvider,
  DunesTokenListProvider,
  LoginPromptProvider,
  RefreshKeyProvider,
  TxWalletProvider,
  UIProvider,
  WalletManagementProvider,
} from "./context";
import { SecurityPreferenceProvider } from "./context/SecurityPreferenceProvider";
import { useNotifications } from "./hooks";
import { TooltipProvider } from "@/components/ui/tooltip";

function App() {
  useLockOrientation();
  // useNotifications();
  const { isIOS, isAndroid } = useOperatingSystem();
  const { type: orientation } = useOrientation();
  const showInstallMessage = useShowInstallMessage();
  const showModalOrientation = useMemo(
    () =>
      (isIOS || isAndroid) &&
      (orientation === "landscape" || orientation === "landscape-primary"),
    [isIOS, isAndroid, orientation],
  );

  return (
    <>
      <div className="App bg-background-primary">
        <UIProvider>
          <TooltipProvider>
            <WalletManagementProvider>
              <SecurityPreferenceProvider>
                <ContentProvider>
                  <CurrencyProvider>
                    <LoginPromptProvider>
                      <DogePriceProvider>
                        <BalanceProvider>
                          <TxWalletProvider>
                            <RefreshKeyProvider>
                              <Drc20TokenListProvider>
                                <DunesTokenListProvider>
                                  <DoginalCollectionListProvider>
                                    <CollectiblesDetailsProvider>
                                      <CollectiblePendingItemsProvider>
                                        <AppRouter />
                                      </CollectiblePendingItemsProvider>
                                    </CollectiblesDetailsProvider>
                                  </DoginalCollectionListProvider>
                                </DunesTokenListProvider>
                              </Drc20TokenListProvider>
                            </RefreshKeyProvider>
                          </TxWalletProvider>
                        </BalanceProvider>
                      </DogePriceProvider>
                    </LoginPromptProvider>
                  </CurrencyProvider>
                </ContentProvider>
              </SecurityPreferenceProvider>
            </WalletManagementProvider>
          </TooltipProvider>
        </UIProvider>
      </div>
      <Toaster />
      <ModalInstall isVisible={showInstallMessage && !showModalOrientation} />
      <ModalOrientation isVisible={showModalOrientation} />
    </>
  );
}

export default App;
