import React, { useMemo } from "react";

import { cn } from "@/lib/utils";
import { getColorForChange, getIconForTick } from "@/utility";

import { AnimatedClickable } from "../animatedClickable";
import { ImageWithFallback } from "../imageWithFallback";
import {
  ChangeIndicator,
  getIndicatorValue,
} from "@/routes/Trading/components/changeIndicator";

interface ActivityCardDuneProps {
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  title: string;
  valuePrimary: string;
  valueSecondary: string;
  valueSecondarySuffix?: string;
  tick: string;
  includeIndicatorSymbol?: boolean;
}

export const ActivityCardDune: React.FC<ActivityCardDuneProps> = ({
  tick,
  title,
  valuePrimary,
  valueSecondary,
  valueSecondarySuffix,
  className,
  style,
  onClick,
  includeIndicatorSymbol = false,
}) => {
  const image = useMemo(() => getIconForTick(tick), [tick]);
  return (
    <AnimatedClickable
      onClick={onClick}
      className={cn(
        "w-46 flex flex-shrink-0 cursor-pointer flex-col space-y-4 truncate rounded-lg border-0.5 border-border-primary bg-background-secondary p-3 lg:w-80",
        className,
      )}
      style={style}
      whileHover={{ scale: 1.02 }}
    >
      <div className="space-between flex flex-row items-center space-x-2">
        <ImageWithFallback
          image={image}
          className="h-6 w-6 md:h-8 md:w-8 lg:h-10 lg:w-10"
        />
        <div className="flex flex-1 flex-col justify-end space-y-1">
          <span
            className="text-right text-sm font-semibold text-text-primary md:text-md lg:text-lg"
            dangerouslySetInnerHTML={{ __html: valuePrimary }}
          />

          <div className="flex items-center justify-end space-x-2">
            <ChangeIndicator
              includeArrow={includeIndicatorSymbol}
              change={valueSecondary}
              className="text-xs font-semibold md:text-sm lg:text-md"
            />
            {valueSecondarySuffix && (
              <span className="!ml-1 rounded-full bg-background-tertiary/50 px-2 py-0.5 text-2xs font-bold text-text-tertiary md:!ml-2 md:text-xs">
                {valueSecondarySuffix}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="mt-4 flex flex-row items-center space-x-2">
        <span className="text-sm font-bold text-text-primary">{title}</span>
      </div>
    </AnimatedClickable>
  );
};
