import { useCallback, useState } from "react";

import {
  buildListDuneTxs,
  createDuneOffer,
  getDuneSellerPsdtHex,
} from "@/context/wallet/helpers/listItems.ts";
import { signPsbt } from "@/context/wallet/lib/transaction.ts";
import {
  TxWallet,
  TxWithFeeEstimation,
  WalletContextHandleReturn,
} from "@/context/wallet/types.ts";
import { DunesUtxo, Utxo } from "@/types";
import { handleError } from "@/utility";

export type UseListDuneParams = {
  txWallet: TxWallet | undefined;
  address: string | undefined;
};

export type ListDuneParams = {
  priceInShibs: number;
  duneUtxos: DunesUtxo[];
  tick: string;
  amt: number;
  feePerVByte: number;
};

export interface ListDuneReturn extends WalletContextHandleReturn {
  execute: (listDuneParams: ListDuneParams) => Promise<void>;
  txHashes: string[];
}

export const useListDune = (
  useListParams: UseListDuneParams,
): ListDuneReturn => {
  const [isLoading, setIsListingDune] = useState<boolean>(false);
  const [isError, setIsErrorDuneList] = useState<boolean>(false);
  const [isSuccess, setIsSuccessDuneList] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [txHashes, setTxHashes] = useState<any[]>([]);

  const { txWallet, address } = useListParams;

  const buildListDunesTx = useCallback(
    async (
      buildListDuneParams: ListDuneParams,
    ): Promise<TxWithFeeEstimation[] | undefined> => {
      const estimateFeesOnly = false;
      const buildListDuneTxsParams = {
        txWallet,
        address,
        estimateFeesOnly,
        ...buildListDuneParams,
      };

      return buildListDuneTxs(buildListDuneTxsParams);
    },
    [address, txWallet],
  );

  const listDune = useCallback(
    async (listDuneParams: ListDuneParams): Promise<void> => {
      setIsListingDune(true);
      setIsErrorDuneList(false);
      setIsSuccessDuneList(false);

      try {
        const { priceInShibs } = listDuneParams;

        if (!txWallet) {
          throw new Error("No wallet found");
        }

        const txs = await buildListDunesTx(listDuneParams);
        if (!txs) {
          throw new Error("No transactions found");
        }

        // Broadcast all to the backend in one go
        const preparedTxHexs: string[] = [];
        const preparedTxHashes: string[] = [];
        let vout = 0;
        const tx = txs[0].tx;

        if (tx && typeof tx === "object" && "vout" in tx && "txid" in tx) {
          const utxo = tx as Utxo; // Safe to cast after runtime check
          vout = utxo.vout;
          preparedTxHashes.push(utxo.txid);
        } else {
          for (const [index, { tx }] of txs.entries()) {
            if (!tx.hash) {
              throw new Error(
                `Failed to prepare txs for createDuneOffer at index ${index}`,
              );
            }
            if (index == 0 && tx?.outputs[0]?.satoshis == 0) {
              vout = 2;
            }
            preparedTxHashes.push(tx.hash);
            preparedTxHexs.push(tx.toString("hex"));
          }
        }

        setTxHashes(preparedTxHashes);
        // broadcast all txs and get the psdt
        const psdtHex = await getDuneSellerPsdtHex({
          sellerAddress: txWallet.address,
          inscriptionPrice: priceInShibs,
          transactionHexs: preparedTxHexs,
          txHashes: preparedTxHashes,
          transactionOutput: vout,
          discount: false,
          type: "dunes",
        });

        // Sign psdt
        const signedPsdtHex = signPsbt(psdtHex, txWallet, {
          isHex: true,
          autoFinalized: true,
        }) as string;

        // Create offer
        const response = await createDuneOffer(
          signedPsdtHex,
          listDuneParams.tick,
          listDuneParams.amt,
        );
        console.log("listDune - Offer created", { response });

        setIsSuccessDuneList(true);
      } catch (e: Error | unknown) {
        setIsErrorDuneList(true);
        const message = handleError(e);
        setError(message);
      } finally {
        setIsListingDune(false);
      }
    },
    [txWallet, buildListDunesTx],
  );

  const reset = useCallback(() => {
    setIsErrorDuneList(false);
    setIsSuccessDuneList(false);
    setError(null);
    setTxHashes([]);
  }, []);

  return {
    isLoading,
    isError,
    isSuccess,
    execute: listDune,
    txHashes,
    reset,
    error,
  };
};
