import { Skeleton } from "@/components";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ChangeIndicator } from "../Trading/components/changeIndicator";

interface BalanceListItemProps {
  label: string;
  icon: string;
  showLabelDetail?: boolean;
  value: string;
  labelDetail: string;
  valueDetail?: string;
  isLoading?: boolean;
  colorForValueDetail?: string;
  handleOpenInfo?: (title: string, description: string) => void;
}

export const BalanceListItem: React.FC<BalanceListItemProps> = ({
  label,
  showLabelDetail = true,
  icon,
  value,
  labelDetail,
  valueDetail,
  isLoading,
  colorForValueDetail,
  handleOpenInfo,
}) => {
  return (
    <div className="flex flex-1 flex-col space-y-2 rounded-md bg-background-tertiary/50 p-4">
      <span className="flex flex-1 items-center gap-1 text-xs font-medium text-text-tertiary">
        <span className="material-symbols-rounded text-sm">{icon}</span>
        {label}
        <Tooltip>
          <TooltipTrigger onClick={() => handleOpenInfo?.(label, labelDetail)}>
            <span className="material-symbols-rounded text-sm">info</span>
          </TooltipTrigger>
          <TooltipContent>Click to see info</TooltipContent>
        </Tooltip>
      </span>
      {isLoading ? (
        <Skeleton className="h-8 w-10 bg-background-tertiary/70" />
      ) : (
        <div className="flex flex-row flex-wrap items-end space-x-1">
          <span
            className="text-3xl font-medium text-text-primary"
            dangerouslySetInnerHTML={{
              __html: value,
            }}
          />
          {valueDetail && (
            <ChangeIndicator
              change={valueDetail}
              className="text-sm font-medium"
            />
          )}
        </div>
      )}
    </div>
  );
};
