import React, { useMemo } from "react";

import { TableContentOwners, TableOwners } from "@/components";
import { Drc20Holder, useFetchTopHolders } from "@/hooks";

interface TabTokenOwnersProps {
  tick: string | undefined;
}

export const TabTokenOwners: React.FC<TabTokenOwnersProps> = ({ tick }) => {
  const { topHolders, isTopHoldersLoading } = useFetchTopHolders(tick);

  const dataTokenHolders = useMemo(() => {
    return topHolders.map((holder: Drc20Holder) => {
      return {
        rank: holder.rank,
        address: holder.address,
        amount: holder.value,
        share: holder.percentage,
      };
    });
  }, [topHolders]);

  return (
    <div className="mx-auto flex max-w-2xl flex-1 flex-col p-4">
      <div className="flex flex-1 flex-col space-y-4">
        <div className="text-center text-md font-bold text-text-primary">{`Top 100 ${tick?.toUpperCase()} Owners`}</div>
        <div className="flex flex-col rounded-lg bg-background-primary/50 p-2 md:p-4 2xl:bg-background-secondary">
          <TableOwners
            data={dataTokenHolders}
            columns={TableContentOwners}
            loading={isTopHoldersLoading}
          />
        </div>
      </div>
    </div>
  );
};
