import * as bip32Lib from "bip32";
import * as bip39 from "bip39";
import * as bitcoin from "bitcoinjs-lib";
import Mnemonic from "bitcore-mnemonic";
import encryptor from "browser-passworder";
import ECPairFactory from "ecpair";
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import * as ecc from "tiny-secp256k1";

import { APP_VERSION } from "@/constants";
import { useLocalStorage } from "@/hooks";
import { dogecoinNetwork } from "@/utility/networks";

const ECPair = ECPairFactory(ecc);
const { BIP32Factory } = bip32Lib;
const bip32 = BIP32Factory(ecc);

type ProviderProps = {
  children: ReactNode;
};

type Account = {
  index: number;
  name: string;
  address: string;
  publicKey: string;
  encryptedPrivKey: string;
  isHidden?: boolean;
};

type WalletData = {
  encryptedMnemonic?: string;
  hasBackedUpSeed?: boolean;
  accounts?: Account[];
  activeAccountIndex?: number;
};

type CoreWallet = {
  walletData: WalletData;
  getMnemonic: (pw: string) => Promise<string>;
  getAccountPrivateKey: (address: string, pw: string) => Promise<string>;
  signTransaction: (address: string, pw: string, tx: any) => Promise<void>;
};

type WalletManagementContextProps = {
  wallet?: CoreWallet;
  updateWallet: (value: WalletData) => void;
  pw?: string;
  updatePW: (pw?: string) => void;
  createWallet: (
    pw: string,
    mmemonicToImport?: string,
    derivationPath?: string,
  ) => Promise<string>;
  deleteWallet: () => void;
  importWallet: (
    pw: string,
    mmemonicToImport: string,
    derivationPath?: string,
  ) => Promise<void>;
  addAccount: () => Promise<void>;
  changeAccountName: (address: string, newName: string) => Promise<void>;
  toggleAccountVisibility: (address: string, isHidden: boolean) => void;
  changePassword: (oldPw: string, newPw: string) => Promise<void>;
  changeActiveAccountIndex: (index: number) => void;
  showMnemonicPhrase: () => Promise<string>;
  showAccountPrivateKey: (address: string, pw: string) => Promise<string>;
  signTx: (pw: string, tx: any) => Promise<void>;
};

export const WalletManagementContext = createContext<
  WalletManagementContextProps | undefined
>(undefined);

class Wallet {
  walletData: WalletData;

  constructor(wallet: WalletData) {
    this.walletData = wallet;
  }

  getMnemonic = async (pw: string) => {
    return this.#privateGetMnemonic(pw);
  };

  getAccountPrivateKey = async (address: string, pw: string) => {
    return this.#privateGetAccountPrivateKey(address, pw);
  };
  // TODO: find a way to make this accessible and return the tx to be broadcasted
  signTransaction = async (address: string, pw: string, tx: any) => {
    const privKey = this.getAccountPrivateKey(address, pw);
    tx.sign(privKey);
  };

  #privateGetMnemonic = async (pw: string) => {
    const encryptedMnemonic = this.walletData.encryptedMnemonic;
    if (!encryptedMnemonic) {
      throw new Error("No mnemonic found");
    }
    return await encryptor.decrypt(pw, encryptedMnemonic);
  };

  #privateGetAccountPrivateKey = async (address: string, pw: string) => {
    const account = this.walletData.accounts?.find(
      (account) => account.address === address,
    );
    if (!account || !account.encryptedPrivKey) {
      throw new Error("Account not found");
    }

    return await encryptor.decrypt(pw, account.encryptedPrivKey);
  };
}

/* CAUTION: only access sensible data through a Wallet Class instantiation */

export const WalletManagementProvider = ({ children }: ProviderProps) => {
  const [pw, setPW] = useState<string | undefined>(undefined);
  const [wallet, setWallet] = useState<Wallet | undefined>(undefined);
  const { value, updateValue } = useLocalStorage<undefined | WalletData>(
    "user-wallet",
    undefined,
  );

  const createWallet = useCallback(
    async (pw: string, mmemonicToImport?: string, derivationPath?: string) => {
      const path = derivationPath ?? "m/44'/3'/0'/0/0";
      const mnemonic = mmemonicToImport
        ? new Mnemonic(mmemonicToImport).toString()
        : bip39.generateMnemonic();
      const encryptedMnemonic = await encryptor.encrypt(pw, mnemonic);
      const seed = bip39.mnemonicToSeedSync(mnemonic);
      const rootNode = bip32.fromSeed(seed, dogecoinNetwork);

      // Derive an address from the HD wallet - last 0 stands for index of account
      const addressNode = rootNode.derivePath(path);
      const publicKey = addressNode.publicKey;
      const dogecoinAddress = bitcoin.payments.p2pkh({
        pubkey: publicKey,
        network: dogecoinNetwork,
      });

      if (
        !addressNode.privateKey ||
        !addressNode.publicKey ||
        !dogecoinAddress.address
      ) {
        throw new Error("Could not derive path from mnemonic");
      }

      const account = {
        address: dogecoinAddress.address,
        privKey: ECPair.fromPrivateKey(addressNode.privateKey, {
          network: dogecoinNetwork,
        }).toWIF(),
      };

      const encryptedPrivKeyAccount0 = await encryptor.encrypt(
        pw,
        account.privKey,
      );
      // set password in context
      setPW(pw);
      // write encrpyted wallet data into local storage
      updateValue({
        encryptedMnemonic,
        hasBackedUpSeed: false,
        accounts: [
          {
            index: 0,
            name: "Account 1",
            address: account.address,
            publicKey: publicKey.toString("hex"),
            encryptedPrivKey: encryptedPrivKeyAccount0,
            isHidden: false,
          },
        ],
        activeAccountIndex: 0,
      });
      return mnemonic;
    },
    [updateValue],
  );

  // this is for reset - whereas logout just deletes the pw form context
  const deleteWallet = useCallback(() => {
    const DELETE_KEYS = [
      `dogemp:version-${APP_VERSION}:user-wallet`,
      `dogemp:version-${APP_VERSION}:pendingInscriptions`,
      `dogemp:version-${APP_VERSION}:user-security-preference`,
      `hasCompletedWalletSetup`,
    ];
    DELETE_KEYS.forEach((key) => localStorage.removeItem(key));
    window.location.reload();
  }, []);

  const importWallet = useCallback(
    async (pw: string, mmemonicToImport: string, derivationPath?: string) => {
      const mnemonic = await createWallet(pw, mmemonicToImport, derivationPath);
      return mnemonic;
    },
    [createWallet],
  );

  const generateAccount = useCallback(async () => {
    if (!pw) {
      throw new Error("No password found");
    }
    const mnemonic = await wallet?.getMnemonic(pw);
    if (!mnemonic) {
      throw new Error("No mnemonic found");
    }

    const seed = bip39.mnemonicToSeedSync(mnemonic);
    const rootNode = bip32.fromSeed(seed, dogecoinNetwork);
    const accountsIndex = value?.accounts?.length || 0;

    // TODO: make derivation path handling dynamic
    const addressNode = rootNode.derivePath(`m/44'/3'/0'/0/${accountsIndex}`);
    const publicKey = addressNode.publicKey;
    const dogecoinAddress = bitcoin.payments.p2pkh({
      pubkey: publicKey,
      network: dogecoinNetwork,
    });

    if (
      !addressNode.privateKey ||
      !addressNode.publicKey ||
      !dogecoinAddress.address
    ) {
      throw new Error("Could not derive path from mnemonic");
    }
    const account = {
      address: dogecoinAddress.address,
      privKey: ECPair.fromPrivateKey(addressNode.privateKey, {
        network: dogecoinNetwork,
      }).toWIF(),
    };

    const encryptedPrivKeyAccount = await encryptor.encrypt(
      pw,
      account.privKey,
    );

    const newAccount: Account = {
      index: accountsIndex,
      name: `Account ${accountsIndex + 1}`,
      address: account.address,
      encryptedPrivKey: encryptedPrivKeyAccount,
      publicKey: publicKey.toString("hex"),
      isHidden: false,
    };
    const oldAccounts = value?.accounts || [];

    updateValue({
      encryptedMnemonic:
        value?.encryptedMnemonic ?? wallet?.walletData.encryptedMnemonic,
      hasBackedUpSeed: value?.hasBackedUpSeed,
      accounts: [...oldAccounts, newAccount],
      activeAccountIndex: accountsIndex,
    });
  }, [
    pw,
    updateValue,
    value?.accounts,
    value?.encryptedMnemonic,
    value?.hasBackedUpSeed,
    wallet,
  ]);

  const makeAccountVisible = useCallback(
    (address: string) => {
      const accounts = value?.accounts || [];
      const account = accounts.find((acc) => acc.address === address);
      if (!account) {
        throw new Error("No account with this address found");
      }

      const updatedAccounts = accounts.map((acc) => {
        if (acc.address === address) {
          return {
            ...acc,
            isHidden: false,
          };
        }
        return acc;
      });

      updateValue({
        ...value,
        accounts: updatedAccounts,
      });
    },
    [updateValue, value],
  );

  const addAccount = useCallback(async () => {
    const accounts = value?.accounts || [];
    const hasHiddenAccounts = accounts.some((acc) => acc.isHidden);

    if (hasHiddenAccounts) {
      const firstAddableAcount = accounts
        ?.filter((acc) => acc.isHidden)
        .sort((a, b) => a.index - b.index)[0];
      if (!firstAddableAcount) {
        throw new Error("No addable account found");
      }

      makeAccountVisible(firstAddableAcount?.address);
    } else {
      await generateAccount();
    }
  }, [generateAccount, makeAccountVisible, value?.accounts]);

  const changePassword = useCallback(
    async (oldPw: string, newPw: string) => {
      if (!pw) throw new Error("No password found.");

      // decrypt mnemonic with old password
      const mnemonic = await wallet?.getMnemonic(oldPw);
      if (!mnemonic)
        throw new Error("No mnemonic found. Password is incorrect.");

      // encrypt mnemonic with new password
      const updatedEncryptedMnemonic = await encryptor.encrypt(newPw, mnemonic);
      if (!updatedEncryptedMnemonic)
        throw new Error("Could not encrypt mnemonic with new password.");

      // decrypt all accounts' private keys & encrypt each with new key
      const accountsLength = value?.accounts?.length || 0;
      const accounts = value?.accounts;
      const updatedAccounts: Account[] = [];
      if (accounts && accountsLength) {
        for (let i = 0; i < accountsLength; i++) {
          const account = accounts[i];

          const privKey = await wallet?.getAccountPrivateKey(
            account.address,
            oldPw,
          );
          const newPrivKey = await encryptor.encrypt(newPw, privKey);
          updatedAccounts.push({ ...account, encryptedPrivKey: newPrivKey });
        }
      }
      // set new credential in context
      setPW(newPw);

      updateValue({
        encryptedMnemonic: updatedEncryptedMnemonic,
        hasBackedUpSeed: value?.hasBackedUpSeed ?? false,
        accounts: updatedAccounts,
        activeAccountIndex: value?.activeAccountIndex ?? 0,
      });
    },
    [
      pw,
      updateValue,
      value?.accounts,
      value?.activeAccountIndex,
      value?.hasBackedUpSeed,
      wallet,
    ],
  );

  const changeActiveAccountIndex = useCallback(
    async (index: number) => {
      const selectedAccount = value?.accounts?.find(
        (acc) => acc.index === index,
      );
      if (!selectedAccount) {
        throw new Error(`No account with index ${index} found`);
      }
      if (selectedAccount.isHidden) {
        throw new Error("Selected account is hidden");
      }

      updateValue({ ...value, activeAccountIndex: index });
    },
    [updateValue, value],
  );

  const changeAccountName = useCallback(
    async (address: string, newName: string) => {
      const accounts = value?.accounts || [];
      const selectedAccount = accounts?.find((x) => x.address === address);
      if (!selectedAccount) {
        return;
      }

      // make sure name is unique
      const isNameAlreadyUsed = accounts.some(
        (acc) => acc.name.toLowerCase() === newName.toLowerCase(),
      );
      if (isNameAlreadyUsed) {
        throw new Error("Name already used");
      }

      // update account name
      const updatedAccounts = accounts.map((acc) => {
        if (acc.address === address) {
          return {
            ...acc,
            name: newName,
          };
        }
        return acc;
      });

      updateValue({
        ...value,
        accounts: updatedAccounts,
      });
    },
    [updateValue, value],
  );

  const toggleAccountVisibility = useCallback(
    async (address: string, isHidden: boolean) => {
      const accounts = value?.accounts || [];
      const selectedAccount = accounts?.find((x) => x.address === address);
      if (!selectedAccount) {
        return;
      }

      // update isHidden flag
      const updatedAccounts = accounts.map((acc) => {
        if (acc.address === address) {
          return {
            ...acc,
            isHidden: isHidden ? true : false,
          };
        }
        return acc;
      });

      updateValue({
        ...value,
        accounts: updatedAccounts,
      });
    },
    [updateValue, value],
  );

  // ONLY use this backup UI
  const showMnemonicPhrase = useCallback(async () => {
    if (!pw) {
      throw new Error("No password found");
    }
    const mnemonic = await wallet?.getMnemonic(pw);
    if (!mnemonic) {
      throw new Error("No mnemonic found");
    }
    return mnemonic;
  }, [pw, wallet]);

  // ONLY use this backup UI
  const showAccountPrivateKey = useCallback(
    // we need to pass pw it here, because it is used in loginPrompt - so at a time when there is no pw in WalletManagementContext
    async (address: string, pw: string) => {
      const privKey = await wallet?.getAccountPrivateKey(address, pw);
      if (!privKey) {
        throw new Error("No private key found");
      }
      return privKey;
    },
    [wallet],
  );

  const signTx = useCallback(
    async (pw: string, tx: any) => {
      const accounts = wallet?.walletData.accounts;
      const activeAccountIndex = wallet?.walletData.activeAccountIndex;
      if (!accounts || activeAccountIndex === undefined) {
        throw new Error("No accounts found");
      }
      const account = accounts[activeAccountIndex];
      const privKey = await wallet?.getAccountPrivateKey(account.address, pw);
      if (!privKey) {
        throw new Error("No private key found");
      }

      tx.sign(privKey);
    },
    [wallet],
  );

  useEffect(() => {
    if (!value) {
      setWallet(undefined);
    } else {
      const walletInstance = new Wallet(value);
      setWallet(walletInstance);
    }
  }, [value]);

  return (
    <WalletManagementContext.Provider
      value={{
        wallet,
        updateWallet: updateValue,
        pw,
        updatePW: (newPw) => setPW(newPw),
        createWallet,
        deleteWallet,
        importWallet,
        addAccount,
        changeAccountName,
        toggleAccountVisibility,
        changePassword,
        changeActiveAccountIndex,
        showMnemonicPhrase,
        showAccountPrivateKey,
        signTx,
      }}
    >
      {children}
    </WalletManagementContext.Provider>
  );
};
