import { useState } from "react";

import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { cn } from "@/lib/utils";
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  EmptyState,
  LoadingState,
  MoveUpButton,
  TextCell,
} from "../components";
import { useEndlessScroll } from "../hooks";

const DEFAULT_PAGINATION = { pageIndex: 0, pageSize: 80000 };

// This function is used to get the sticky column class name
function getStickyColumnClassName(index: number): string | undefined {
  const stickyClasses: Record<number, string> = {
    // change this when rank column is reactivated (lookup git history)
    0: "sticky left-0 z-10",
  };

  return stickyClasses[index];
}

interface TableExploreProps<TData, TValue> {
  data: TData[];
  columns: ColumnDef<TData, TValue>[];

  loading?: boolean;
  loadingMore?: boolean;

  defaultSortId?: string;
  className?: string;
}

export const TableExplore = <TData, TValue>({
  data,
  columns,
  loading = false,
  loadingMore = false,
  defaultSortId = "",
  className,
}: TableExploreProps<TData, TValue>) => {
  const { showButton, targetRowRef } = useEndlessScroll(data);
  const [sorting, setSorting] = useState<SortingState>([
    { id: defaultSortId, desc: true },
  ]);

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: { sorting, pagination: DEFAULT_PAGINATION },
  });

  if (loading || data.length === 0) {
    return (
      <div className="flex w-full flex-col space-y-8 overflow-visible pl-4 pt-4">
        <div className="flex flex-row space-x-8 overflow-visible">
          <Skeleton className="h-4 w-12 min-w-12 rounded-lg" />
          <Skeleton className="flex h-4 min-w-44 flex-1 flex-shrink-0 rounded-lg" />
          {Array.from({ length: 6 }).map((_, index) => (
            <Skeleton
              key={index}
              className="flex h-4 min-w-24 flex-1 flex-shrink-0 rounded-lg"
            />
          ))}
        </div>

        <div className="flex flex-col space-y-4 overflow-x-visible">
          {Array.from({ length: 20 }).map((_, index) => (
            <div key={index} className="flex flex-row items-center space-x-8">
              <Skeleton className="h-8 w-12 min-w-12 rounded-lg" />

              <div className="flex min-w-44 flex-1 flex-row items-center space-x-4">
                <Skeleton className="h-12 w-12 rounded-full" />
                <div className="flex flex-1 flex-col space-y-2">
                  <Skeleton className="h-4 w-full rounded-md" />
                  <Skeleton className="h-4 w-full rounded-md" />
                </div>
              </div>

              {Array.from({ length: 6 }).map((_, index) => (
                <Skeleton
                  key={index}
                  className="h-8 min-w-24 flex-1 flex-shrink-0 rounded-lg"
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <Table
        className={cn("bg-background-primary text-text-tertiary", className)}
      >
        <TableHeader id="table-header">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                const stickyColumnStyle = getStickyColumnClassName(index);
                return (
                  <TableHead
                    key={header.id}
                    className={cn(stickyColumnStyle, "bg-background-primary")}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody className="text-text-primary">
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row, index) => {
              const rowRank = index + 1;
              const { id, getVisibleCells } = row;
              const rowRef = index === 50 ? targetRowRef : null; // Used to define from which row on the MoveUpButton should appear
              return (
                <TableRow key={id} ref={rowRef} className="group">
                  {getVisibleCells().map((cell, index) => {
                    const { id, column, getContext } = cell;
                    const stickyColumnStyle = getStickyColumnClassName(index);
                    return (
                      <TableCell
                        key={id}
                        className={cn(
                          stickyColumnStyle,
                          "bg-background-primary group-hover:bg-background-tertiary",
                          index === 0 && "pl-4",
                        )}
                      >
                        {cell.column.id == "rank" ? (
                          <TextCell
                            value={`${rowRank}`}
                            className="w-10 pl-4 text-left"
                          />
                        ) : (
                          flexRender(column.columnDef.cell, getContext())
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          ) : (
            <EmptyState colSpan={columns.length} />
          )}
        </TableBody>
      </Table>
      <LoadingState isVisible={loadingMore} />
      <MoveUpButton isVisible={showButton} />
    </>
  );
};
