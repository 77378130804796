import { calculateMarketCap } from "@/hooks";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { AllDunesData, Currency } from "@/types";
import { formatChangeValue, getFormattedValue } from "@/utility";

import { TokenDetailProps } from "./types";

export const getFormattedTokenDetails = (
  tokenData: AllDunesData,
  dogePrice: number,
  currency: Currency,
): TokenDetailProps[] => {
  const {
    sales,
    sales7d,
    volume,
    volume30d,
    volume7d,
    holders,
    floorPrice,
    minted,
    maxSupply,
    weeklyChangePercent,
    monthlyChangePercent,
    twentyFourHourChangePercent,
  } = tokenData;

  const marketCap = calculateMarketCap(tokenData);

  const formattedChange24h = formatChangeValue(twentyFourHourChangePercent);
  const formattedChange7d = formatChangeValue(weeklyChangePercent);
  const formattedChange30d = formatChangeValue(monthlyChangePercent);

  return [
    {
      label: "Price",
      value: getFormattedValue(floorPrice, dogePrice, currency, true).value,
    },
    {
      label: "24h",
      value: formattedChange24h.value,
      color: formattedChange24h.color,
    },
    {
      label: "7d",
      value: formattedChange7d.value,
      color: formattedChange7d.color,
    },
    {
      label: "30d",
      value: formattedChange30d.value,
      color: formattedChange30d.color,
    },
    {
      label: "Vol (7d)",
      value: getFormattedValue(volume7d, dogePrice, currency, false).value,
    },
    {
      label: "Vol (30d)",
      value: getFormattedValue(volume30d, dogePrice, currency, false).value,
    },
    {
      label: "Vol (All)",
      value: getFormattedValue(volume, dogePrice, currency, false).value,
    },
    {
      label: "Sales (7d)",
      value: formatNumber({
        value: sales7d,
        type: NumberFormatType.Large_Number,
      }),
    },
    {
      label: "Sales (All)",
      value: formatNumber({
        value: sales,
        type: NumberFormatType.Large_Number,
      }),
    },
    {
      label: "Owners",
      value: formatNumber({
        value: holders,
        type: NumberFormatType.Large_Number,
      }),
    },
    {
      label: "Market Cap",
      value: getFormattedValue(marketCap, dogePrice, currency, false).value,
    },
    {
      label: "Total Supply",
      value: formatNumber({
        value: Number(maxSupply),
        type: NumberFormatType.Large_Number,
      }),
    },
    {
      label: "Minted",
      value: formatNumber({
        value: (minted / Number(maxSupply)) * 100,
        type: NumberFormatType.Percentage,
      }),
    },
  ];
};
