import React, { ReactNode, useCallback, useMemo, useState } from "react";

import { Content, Tab } from "@/types";
import { useLocalStorage } from "@/hooks";
import { FEATURE_ACTIVATION_DUNES } from "@/constants";

const CONTENT_OPTIONS =
  FEATURE_ACTIVATION_DUNES === "true"
    ? [
        { icon: "image", value: Content.DRC20 },
        { icon: "toll", value: Content.COLLECTIBLES },
        { icon: "radio_button_checked", value: Content.DUNES },
      ]
    : [
        { icon: "image", value: Content.DRC20 },
        { icon: "toll", value: Content.COLLECTIBLES },
      ];

type ProviderProps = {
  children: ReactNode;
};

interface ContentContextProps {
  content: Content;
  icon: string;
  setContent: (content: Content) => void;
  changeContent: () => void;

  tabExplore: Tab;
  setTabExplore: (tab: Tab) => void;
}

export const ContentContext = React.createContext<ContentContextProps>({
  content: Content.COLLECTIBLES,
  icon: "image",
  setContent: () => {},
  changeContent: () => {},
  tabExplore: Tab.Top,
  setTabExplore: () => {},
});

export const ContentProvider = ({ children }: ProviderProps) => {
  const [tabExplore, setTabExplore] = useState<Tab>(Tab.Top);
  const { value: content, updateValue: setContent } = useLocalStorage<Content>(
    "content-type",
    Content.DRC20,
  );

  const icon = useMemo(() => {
    const selectedOption = CONTENT_OPTIONS.find(
      (option) => option.value === content,
    );
    return selectedOption ? selectedOption.icon : "image";
  }, [content]);

  const changeContent = useCallback(() => {
    const currentIndex = CONTENT_OPTIONS.findIndex((c) => c.value === content);
    const nextIndex = (currentIndex + 1) % CONTENT_OPTIONS.length;
    setContent(CONTENT_OPTIONS[nextIndex].value);
  }, [content]);

  return (
    <ContentContext.Provider
      value={{
        content,
        icon,
        setContent,
        changeContent,
        tabExplore,
        setTabExplore,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};
