import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
import { FIREBASE_CONFIG } from "@/constants";

const app = initializeApp(FIREBASE_CONFIG);

const messaging = getMessaging(app);
const analytics = getAnalytics(app);

export { messaging, analytics };
